import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, Modal, FormFeedback, Badge, UncontrolledDropdown, UncontrolledTooltip, InputGroup } from "reactstrap";
import { Form as Form1 } from 'reactstrap';

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, getProjectDetails, recheckSingleURL, deleteSingleURL, addNewUrls, recheckProjectRequest, recheckMultipleURLs, getProjectStatusHistory, editProjectDetails, getResultsHistory } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import PropTypes from 'prop-types';
import StatusChart from "../projects/statusChart";

import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactApexChart from "react-apexcharts";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import paginationFactory from "react-bootstrap-table2-paginator";

function StatusReport(props) {

    const { id } = useParams();
    // console.log(id, 'id');

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const [logDataArr, setLogDataArr] = useState([]);

    const [allProjects, setAllProjects] = useState([]);
    const [statusHistoryResults, setStatusHistoryResults] = useState([]);
    const [oldestURLHistory, setOldestURLHistory] = useState([]);
    const [latestURLHistory, setLatestURLHistory] = useState([]);
    const [timePeriod, setTimePeriod] = useState(["2024-03-05", "2024-03-07"]);
    const [projectId, setProjectId] = useState();

    useEffect(() => {
        getAllProjects()
    }, [])

    const getAllProjects = () => {
        getAllProjectsByUser().then((project) => {
            console.log(project?.data, "project_dets");
            setAllProjects(project?.data?.projects)
            getHistoryLogResults(project?.data?.projects[0].id)
            setProjectId(project?.data?.projects[0].id)
        }).catch((error) => {
            console.log({ error });
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleTimePeriod = (val) => {
        console.log(val, 'val');
        getDataUsingTimePeriod(val)
    }

    const handleSelectProject = (val) => {
        console.log(val, 'val');
        if (val) {
            getHistoryLogResults(val);
            setProjectId(val);
        }
    }

    const getDataUsingTimePeriod = (time_period) => {
        let groupedData = {};
        console.log(time_period, 'time_period');
        let oldDate = new Date(time_period[0]);
        let newDate = new Date();
        if (time_period.length > 1) {
            newDate = time_period[1];
        }

        statusHistoryResults.forEach(item => {
            let key = item.checkedresult.toString();
            if (!groupedData[key]) {
                groupedData[key] = [];
            }
            groupedData[key].push(item);
        });

        console.log(groupedData, 'groupedData');

        let newArrays = [];
        let count = 0;

        for (let key in groupedData) {
            let group = groupedData[key];
            // let latestRecord = group.reduce((acc, curr) => formatDate(new Date(curr.checked_date)) == formatDate(newDate) ? curr : acc);
            let latestRecord = group.reduce((acc, curr) => {
                const accDate = new Date(acc.checked_date);
                const currDate = new Date(curr.checked_date);
                if (formatDate(currDate) <= formatDate(newDate) && formatDate(currDate) > formatDate(accDate)) {
                    return curr;
                } else {
                    return acc;
                }
            });
            // let oldestRecord = group.reduce((acc, curr) => {
            //     const accDate = new Date(acc.checked_date);
            //     const currDate = new Date(curr.checked_date);
            //     if (formatDate(currDate) == formatDate(oldDate)) {
            //         return curr;
            //     } else if (!acc && formatDate(currDate) > formatDate(oldDate)) {
            //         return curr;
            //     } else {
            //         return acc;
            //     }
            // });
            let oldestRecord = group.find(record => formatDate(new Date(record.checked_date)) == formatDate(oldDate));
            if (!oldestRecord) {
                const nextDateRecord = group.find(record => formatDate(new Date(record.checked_date)) > formatDate(oldDate));
                oldestRecord = nextDateRecord || null;
            }
            // let oldestRecord = group.reduce((acc, curr) => formatDate(new Date(curr.checked_date)) == formatDate(oldDate) ? curr : acc);
            // let oldestRecord = group.reduce((acc, curr) => formatDate(new Date(curr.checked_date)) >= formatDate(oldDate) && formatDate(new Date(curr.checked_date)) < formatDate(new Date(acc.checked_date)) ? curr : acc);
            console.log(oldestRecord, 'oldestRecord');
            console.log(latestRecord, 'latestRecord');
            let main_status;
            if (latestRecord.status == oldestRecord.status) {
                main_status = latestRecord.status
            } else if (latestRecord.status == 'Indexed' && oldestRecord.status == 'Not Indexed') {
                main_status = 're_indexed'
            } else if (oldestRecord.status == 'Indexed' && latestRecord.status == 'Not Indexed') {
                main_status = 'de_indexed'
            }
            count = count++
            let newArrayItem = {
                id: count,
                url: group[0].url,
                latest_checked_date: latestRecord.checked_date,
                latest_status: latestRecord.status,
                oldest_checked_date: oldestRecord.checked_date,
                oldest_status: oldestRecord.status,
                checkedresult: group[0].checkedresult,
                main_status: main_status,
                time_period: formatDate(oldestRecord.checked_date) + " to " + formatDate(latestRecord.checked_date)
            };
            newArrays.push(newArrayItem);
        }

        console.log(newArrays, 'newArrays');
        setLogDataArr(newArrays)

    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const getHistoryLogResults = (proj_id) => {
        getResultsHistory(proj_id).then((project) => {
            setStatusHistoryResults(project?.data.result_history)
            const oldestData = {};
            const latestData = {};
            project?.data.result_history.forEach(item => {
                const resultId = item.checkedresult;
                const checkedDate = item.checked_date;

                if (!(resultId in oldestData) || checkedDate < oldestData[resultId]['checked_date']) {
                    oldestData[resultId] = item;
                }

                if (!(resultId in latestData) || checkedDate > latestData[resultId]['checked_date']) {
                    latestData[resultId] = item;
                }
            });
            const oldestDataList = Object.values(oldestData);
            const latestDataList = Object.values(latestData);

            setTimePeriod([new Date(oldestDataList[0].checked_date), new Date(latestDataList[0].checked_date)])

            setOldestURLHistory(oldestDataList)
            setLatestURLHistory(latestDataList)

            getStatusReportHistory(oldestDataList, latestDataList);
            setIsLoading(false);
        });
    }

    const getStatusReportHistory = (old_records, new_records) => {

        let newArrays = [];
        let count = 0;
        for (let i = 0; i < old_records.length; i++) {
            for (let j = 0; j < new_records.length; j++) {
                if (old_records[i].checkedresult == new_records[j].checkedresult) {
                    let main_status;

                    if (old_records[i].status == new_records[j].status) {
                        main_status = new_records[j].status
                    } else if (new_records[j].status == 'Indexed' && old_records[i].status == 'Not Indexed') {
                        main_status = 're_indexed'
                    } else if (old_records[i].status == 'Indexed' && new_records[j].status == 'Not Indexed') {
                        main_status = 'de_indexed'
                    }
                    count = count++
                    let newArrayItem = {
                        id: count,
                        url: old_records[i].url,
                        latest_checked_date: new_records[j].checked_date,
                        latest_status: new_records[j].status,
                        oldest_checked_date: old_records[i].checked_date,
                        oldest_status: old_records[i].status,
                        checkedresult: old_records[i].checkedresult,
                        main_status: main_status,
                        time_period: formatDate(old_records[i].checked_date) + " to " + formatDate(new_records[j].checked_date)
                    };
                    newArrays.push(newArrayItem);
                }
            }
        }

        console.log(newArrays, 'newArrays');
        setLogDataArr(newArrays)

    }

    const headerStyle = { textAlign: 'center' };

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.main_status == 'Indexed') {
            style.backgroundColor = '#eff2f7';
        } else if (row.main_status == 're_indexed') {
            style.backgroundColor = '#d6f3e9';
        } else if (row.main_status == 'de_indexed') {
            style.backgroundColor = '#fdcdcd';
        } else {
            style.backgroundColor = '#fcf0db';
        }

        return style;
    };

    const columns = [
        // {
        //     dataField: 'id',
        //     text: '#',
        //     sort: true
        // },
        {
            dataField: 'url',
            text: 'URL',
            sort: true,
            // filter: textFilter()
        }, {
            dataField: 'time_period',
            text: 'Time Period',
            sort: true
        }, {
            dataField: 'main_status',
            text: 'Status',
            sort: true,
            headerStyle,
            style: {
                textAlign: "center",
            },
            formatter: (cell, row) => (
                (cell === 're_indexed' ? <><Badge className="badge-soft-danger">{row.oldest_status}</Badge><i className="bx bx-sitemap"></i><Badge className="bg-success">{row.latest_status}</Badge></> :
                    cell === 'de_indexed' ? <><Badge className="badge-soft-success">{row.oldest_status}</Badge><i className="bx bx-sitemap"></i><Badge className="bg-danger">{row.latest_status}</Badge></> :
                        cell === 'Indexed' ? <Badge className="badge-soft-primary" style={{ backgroundColor: "#556ee6", important: true }}>{row.latest_status}</Badge> : <Badge className="bg-warning">{row.latest_status}</Badge>)

            )
        }];

    return (
        <div className="page-content">
            {
                isLoading ? (
                    <Loading />
                ) : null
            }

            <Container fluid>
                {/* Render Breadcrumbs */}
                {/* <Breadcrumbs title="Reports" breadcrumbItem="Project Status" /> */}
                <Row>
                    <Col lg="12" className="mb-2">
                        <Row>
                            <Col lg="4">
                                <div className="d-flex">
                                    <div className="flex-grow-1 align-self-center">
                                        <h5 className="mb-1">Project Status Report</h5>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" className="align-self-center">
                            </Col>

                            <Col lg="2" className="d-none d-lg-block">
                                <Button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/de-indexed-report')}>De-indexed Report</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xl={12}>

                        <Row className="mb-2">
                            <Col lg={4}>
                                <FormGroup row>
                                    <label
                                        htmlFor="select_project"
                                        className="col-md-2 col-form-label"
                                    >
                                        Project:
                                    </label>
                                    <div className="col-md-10">
                                        <select className="form-control" name="select_project" id="select_project"
                                            onChange={(e) => handleSelectProject(e.target.value)}
                                            value={projectId}>
                                            {/* <option value="">Select Project</option> */}
                                            {(allProjects.map((project) => (
                                                <option value={project?.id}>{project?.project_name}</option>
                                            )))}

                                        </select>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup row>
                                    <label
                                        htmlFor="time_period"
                                        className="col-md-3 col-form-label"
                                    >
                                        Time Period:
                                    </label>
                                    <div className="col-md-8">
                                        <InputGroup>
                                            <Flatpickr
                                                onChange={(e) => handleTimePeriod(e)}
                                                value={timePeriod}
                                                className="form-control d-block"
                                                placeholder="dd M,yyyy"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "Y-m-d",
                                                    minDate: new Date(oldestURLHistory[0]?.checked_date),
                                                    maxDate: new Date(latestURLHistory[0]?.checked_date)
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>


                        <Card>
                            <CardBody>
                                {/* <CardTitle className="h4">Hoverable </CardTitle> */}
                                <div>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={logDataArr}
                                        columns={columns}
                                        rowStyle={rowStyle}
                                        filter={filterFactory()}
                                        pagination={paginationFactory()}
                                    />
                                </div>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
StatusReport.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default StatusReport;
