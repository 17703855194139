import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { getBacklinkProjectDetails } from "services/main.service";
import { Link, useParams } from "react-router-dom";
import Loading from "components/Common/Loading";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { JobWidgetCharts } from "pages/DashboardJob/JobCharts";
import ReactApexChart from "react-apexcharts";
import DomainProjectDetails from "checkerAppPages/domainMonitor/domainProjectDetails";

const BacklinkProjectDetails = () => {

    //meta title
    document.title = "Backlink Project Details | Indexed Checker App";

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [backlinkProjectDetails, setBacklinkProjectDetails] = useState();
    const [indexProjectDetails, setIndexProjectDetails] = useState();
    const [urlList, setURLList] = useState([]);
    const [backlinkCheckedUrls, setBacklinkCheckedUrls] = useState([]);
    const [indexCheckedUrls, setIndexCheckedUrls] = useState([]);

    useEffect(() => {
        getBacklinkDetails()
    }, [])

    const getBacklinkDetails = () => {
        getBacklinkProjectDetails(id).then((project) => {
            console.log(project?.data, "project_dets");
            setBacklinkProjectDetails(project?.data?.project_dets)
            setIndexProjectDetails(project?.data?.index_dets)
            setBacklinkCheckedUrls(project?.data?.backlink_results)
            setIndexCheckedUrls(project?.data?.index_result)
            const merged_results = project?.data?.backlink_results.map(item1 => {
                const item2 = project?.data?.index_result.find(item2 => item2.url == item1.source_url);
                return { ...item1, ...item2 }
            })
            const modifiedData = merged_results.map(item => {
                item.index_status = item.status;
                delete item.status;

                item.do_follow = item.do_follow ? 'True' : 'False';
                item.no_follow = item.no_follow ? 'True' : 'False';
                item.sponsored = item.sponsored ? 'True' : 'False';
                item.ugc = item.ugc ? 'True' : 'False';
                item.text = item.text ? 'True' : 'False';
                item.image = item.image ? 'True' : 'False';
                item.link_homepage_only = item.link_homepage_only ? 'True' : 'False';
                item.is_indexable = item.is_indexable ? 'True' : 'False';

                return item;
            });
            console.log(modifiedData, 'modifiedData');

            setURLList(modifiedData);
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [column_values, setColumnValues] = useState([
        {
            value: 'target_url',
            name: 'Target url',
            status: true
        },
        {
            value: 'source_url',
            name: 'Source url',
            status: true
        },
        {
            value: 'backlink_status',
            name: 'Backlink Status',
            status: true
        },
        {
            value: 'status',
            name: 'Index Status',
            status: true
        },
        {
            value: 'anchors',
            name: 'Anchors',
            status: true
        },
        {
            value: 'external_link_count',
            name: 'External links count',
            status: true
        },
        {
            value: 'internal_link_count',
            name: 'Internal links count',
            status: true
        },
        {
            value: 'dofollow',
            name: 'DoFollow',
            status: true
        },
        {
            value: 'nofollow',
            name: 'NoFollow',
            status: true
        },
        {
            value: 'sponsored',
            name: 'Sponsored',
            status: true
        },
        {
            value: 'ugc',
            name: 'UGC',
            status: true
        },
        {
            value: 'text',
            name: 'Text',
            status: true
        },
        {
            value: 'image',
            name: 'Image',
            status: true
        },
        {
            value: 'link_homepage_only',
            name: 'Link To Homepage Only',
            status: true
        },
        {
            value: 'found_url',
            name: 'Found URL',
            status: true
        },
        {
            value: 'is_indexable',
            name: 'Is Indexable',
            status: true
        },
        {
            value: 'error_type',
            name: 'Error Type',
            status: true
        },
    ])

    const initialColumns = [
        {
            Header: 'Target url',
            accessor: 'target_url',
        },
        {
            Header: 'Source url',
            accessor: 'source_url'
        },
        {
            Header: 'Backlink Status',
            accessor: 'backlink_status'
        },
        {
            Header: 'Index Status',
            accessor: 'index_status'
        },
        {
            Header: 'Anchors',
            accessor: 'anchors'
        },
        {
            Header: 'External links count',
            accessor: 'external_link_count'
        },
        {
            Header: 'Internal links count',
            accessor: 'internal_link_count'
        },
        {
            Header: 'DoFollow',
            accessor: 'do_follow'
        },
        {
            Header: 'NoFollow',
            accessor: 'no_follow'
        },
        {
            Header: 'Sponsored',
            accessor: 'sponsored'
        },
        {
            Header: 'UGC',
            accessor: 'ugc'
        },
        {
            Header: 'Text',
            accessor: 'text'
        },
        {
            Header: 'Image',
            accessor: 'image'
        },
        {
            Header: 'Link To Homepage Only',
            accessor: 'link_homepage_only'
        },
        {
            Header: 'Found URL',
            accessor: 'found_url'
        },
        {
            Header: 'Is Indexable',
            accessor: 'is_indexable'
        },
        {
            Header: 'Error Type',
            accessor: 'error_type'
        },
    ];

    const [togColSourceUrl, setTogColSourceUrl] = useState(false);
    const [customchkInfo, setcustomchkInfo] = useState(true);

    const columns1 = [
        {
            dataField: 'target_url',
            text: 'Target url',
        },
        {
            dataField: 'source_url',
            text: 'Source url',
        },
        {
            dataField: 'backlink_status',
            text: 'Backlink Status',
        },
        {
            dataField: 'status',
            text: 'Index Status',
        },
        {
            dataField: 'anchors',
            text: 'Anchors',
        },
        {
            dataField: 'external_link_count',
            text: 'External links count',
        },
        {
            dataField: 'internal_link_count',
            text: 'Internal links count',
        },
        {
            dataField: 'dofollow',
            text: 'DoFollow',
        },
        {
            dataField: 'nofollow',
            text: 'NoFollow',
        },
        {
            dataField: 'sponsored',
            text: 'Sponsored',
        },
        {
            dataField: 'ugc',
            text: 'UGC',
        },
        {
            dataField: 'text',
            text: 'Text',
        },
        {
            dataField: 'image',
            text: 'Image',
        },
        {
            dataField: 'link_homepage_only',
            text: 'Link To Homepage Only',
        },
        {
            dataField: 'found_url',
            text: 'Found URL',
        },
        {
            dataField: 'is_indexable',
            text: 'Is Indexable',
        },
        {
            dataField: 'error_type',
            text: 'Error Type'
        },];

    const [columns, setColumns] = useState(initialColumns);

    const manageColumns = (e) => {
        console.log(initialColumns, 'initialColumns');
        const columnObject = initialColumns.find((column, index) => {
            if (column.accessor === e.target.value) {
                return true;
            }
            return false;
        });
        const columnIndex = initialColumns.indexOf(columnObject);
        console.log(columnIndex, 'columnIndex');
        if (e.target.checked) {
            const updatedColumns = [...columns.slice(0, columnIndex), columnObject, ...columns.slice(columnIndex)];
            console.log(updatedColumns, 'updatedColumns1');

            // Update the state with the new array
            setColumns(updatedColumns);
        } else {
            const updatedColumns = columns.filter(column => column.accessor !== e.target.value);
            console.log(updatedColumns, 'updatedColumns2');

            setColumns(updatedColumns);
        }

    };

    // Use useMemo to memoize the columns array
    const memoizedColumns = useMemo(() => columns, [columns]);

    const chartsData = [
        {
            id: 1,
            title: "Job View",
            price: "14,487",
            perstangeValue: "18.89",
            bagdeColor: "success",
            seriesData: [{
                name: "Job View",
                data: [36, 21, 65, 22, 35, 50, 87, 98],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 2,
            title: "New Application",
            price: "7,402",
            perstangeValue: "24.07",
            bagdeColor: "success",
            seriesData: [{
                name: "New Application",
                data: [36, 48, 10, 74, 35, 50, 70, 73],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 3,
            title: "Total Approved",
            price: "12,487",
            perstangeValue: " 8.41",
            bagdeColor: "success",
            seriesData: [{
                name: "Total Approved",
                data: [60, 14, 5, 60, 30, 43, 65, 84],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 4,
            title: "Total Rejected",
            price: "12,487",
            perstangeValue: " 20.63",
            bagdeColor: "danger",
            istrendingArrow: true,
            seriesData: [{
                name: "Total Rejected",
                data: [32, 22, 7, 55, 20, 45, 36, 20],
            }],
            color: '["--bs-danger", "--bs-transparent"]'
        },
    ];

    var options = {
        chart: { sparkline: { enabled: !0 } },
        dataLabels: { enabled: !1 },
        colors: ["#556ee6", "#f1c40f"],
        labels: ["DoFollow", "NoFollow"],
        plotOptions: {
            donut: {
                hollow: { margin: 0, size: "60%" },
                track: { margin: 0 },
                dataLabels: { show: !0 },
            },
        },
    };

    var options_index = {
        chart: { sparkline: { enabled: !0 } },
        dataLabels: { enabled: !1 },
        colors: ["#34c38f", "#f46a6a"],
        labels: ["Indexed", "Not Indexed"],
        plotOptions: {
            donut: {
                hollow: { margin: 0, size: "60%" },
                track: { margin: 0 },
                dataLabels: { show: !0 },
            },
        },
    };

    const handleExportToCsv = () => {
        const fieldsToExport = ['target_url', 'source_url', 'backlink_status', 'index_status', 'anchors', 'external_link_count', 'internal_link_count', 'do_follow', 'no_follow', 'sponsored', 'ugc', 'text', 'image', 'link_homepage_only', 'found_url', 'is_indexable'];
        const fieldName = ['Target URL', 'Source URL', 'Backlink Status', 'Index Status', 'Anchors', 'External Link Count', 'Internal Link Count', 'Do Follow', 'No Follow', 'Sponsored', 'UGC', 'Text', 'Image', 'Link to Homepage Only', 'Found URL', 'Is Indexable'];

        // Function to format a date object to "YYYY-MM-DD" format
        const formatDate = (date) => {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const hours = String(formattedDate.getHours()).padStart(2, '0');
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const headerRow = fieldName.join(',') + '\n';
        console.log(fieldsToExport, 'fieldsToExport');
        console.log(urlList, 'urlList');

        const csvContent = "data:text/csv;charset=utf-8," + fieldName.join(',') + '\n' + // Adding header row
            urlList.map(item => {
                return fieldsToExport.map(field => {
                    // Convert object property to CSV value, handle special cases if needed
                    if (field === 'anchors') {
                        return '"' + (item[field] || '').replace(/"/g, '""') + '"';
                    } else {
                        return '"' + (item[field] || '') + '"';
                    }
                }).join(',');
            }).join('\n');

        // Now you can use the csvContent variable to download or further process your CSV data.


        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", backlinkProjectDetails?.domain + "_export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getChartOptions = index => {
        var options = {
            chart: { sparkline: { enabled: !0 } },
            dataLabels: { enabled: !1 },
            colors: ["#556ee6"],
            plotOptions: {
                radialBar: {
                    hollow: { margin: 0, size: "60%" },
                    track: { margin: 0 },
                    dataLabels: { show: !1 },
                },
            },
        };
        switch (index) {
            case 1:
                options["colors"][0] = "#f1c40f";
                break;
            case 2:
                options["colors"][0] = "#34c38f";
                break;
            case 3:
                options["colors"][0] = "#f46a6a";
                break;
            default:
                break;
        }

        return options;
    };


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3">
                                            <div className="d-flex mb-3">
                                                <div className="flex-grow-1 align-self-center">
                                                    <div>
                                                        <p className="mb-2 text-muted">Backlink Analysis</p>
                                                        <h4 className="mb-1">{backlinkProjectDetails?.domain}</h4>
                                                        {/* <p className="mb-0">Total Scanned Links: {backlinkProjectDetails?.total_scanned_links}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg="9" className="align-self-center">
                                            <div className="text-lg-center mt-4 mt-lg-0">
                                                <Row>
                                                    <Col xs="3">
                                                        <div>
                                                            <p className="text-muted text-truncate mb-2">
                                                                Total Scanned Links
                                                            </p>
                                                            <h3 className="mb-0">{backlinkProjectDetails?.total_scanned_links}</h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="2">
                                                        <div>
                                                            <p className="text-muted text-truncate mb-2">
                                                                Active Links
                                                            </p>
                                                            <h3 className="mb-0">{backlinkProjectDetails?.active_backlinks}</h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="2">
                                                        <div>
                                                            <p className="text-muted text-truncate mb-2">
                                                                Broken Links
                                                            </p>
                                                            <h3 className="mb-0">{backlinkProjectDetails?.broken_links}</h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="2">
                                                        <div>
                                                            <p className="text-muted text-truncate mb-2">
                                                                Crawl Errors
                                                            </p>
                                                            <h3 className="mb-0">{backlinkProjectDetails?.crawl_links}</h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="3">
                                                        <div>
                                                            <p className="text-muted text-truncate mb-2">
                                                                Linked to Homepage
                                                            </p>
                                                            <h3 className="mb-0">{backlinkProjectDetails?.link_to_homepage}</h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Linked to Homepage</p>
                                            <h3 className="mb-1">{backlinkProjectDetails?.link_to_homepage}</h3>
                                        </div>

                                    </div>
                                </CardBody>

                            </Card>
                        </Col> */}
                    </Row>
                    {/* <Row>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Active Links</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.active_backlinks}</h1>
                                        </div>

                                        <div className="flex-shrink-0 text-center">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={getChartOptions(2)}
                                                    series={[(backlinkProjectDetails?.active_backlinks / backlinkProjectDetails?.total_scanned_links) * 100]}
                                                    type="radialBar"
                                                    height={90}
                                                    width={90}
                                                    className="apex-charts"
                                                />
                                            </div>
                                            <p className="fw-medium mb-0" style={{ "color": "#34c38f" }}> {((backlinkProjectDetails?.active_backlinks / backlinkProjectDetails?.total_scanned_links) * 100).toFixed(2)}%</p>

                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Crawl Errors</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.crawl_links}</h1>
                                        </div>

                                        <div className="flex-shrink-0 text-center">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={getChartOptions(1)}
                                                    series={[(backlinkProjectDetails?.crawl_links / backlinkProjectDetails?.total_scanned_links) * 100]}
                                                    type="radialBar"
                                                    height={90}
                                                    width={90}
                                                    className="apex-charts"
                                                />
                                            </div>
                                            <p className="fw-medium mb-0" style={{ "color": "#f1c40f" }}> {((backlinkProjectDetails?.crawl_links / backlinkProjectDetails?.total_scanned_links) * 100).toFixed(2)}%</p>

                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Broken Links</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.broken_links}</h1>
                                        </div>

                                        <div className="flex-shrink-0 text-center">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={getChartOptions(3)}
                                                    series={[(backlinkProjectDetails?.broken_links / backlinkProjectDetails?.total_scanned_links) * 100]}
                                                    type="radialBar"
                                                    height={90}
                                                    width={90}
                                                    className="apex-charts"
                                                />
                                            </div>
                                            <p className="fw-medium mb-0" style={{ "color": "#f46a6a" }}> {((backlinkProjectDetails?.broken_links / backlinkProjectDetails?.total_scanned_links) * 100).toFixed(2)}%</p>

                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">DoFollow/NoFollow</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.do_follow}/{backlinkProjectDetails?.no_follow}</h1>
                                        </div>

                                        <div className="flex-shrink-0">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={options}
                                                    series={[backlinkProjectDetails?.do_follow, backlinkProjectDetails?.no_follow]}
                                                    type="donut"
                                                    height={113}
                                                    width={113}
                                                    className="apex-charts"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">DoFollow/NoFollow</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.do_follow}/{backlinkProjectDetails?.no_follow}</h1>
                                        </div>

                                        <div className="flex-shrink-0">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={options}
                                                    series={[backlinkProjectDetails?.do_follow, backlinkProjectDetails?.no_follow]}
                                                    type="donut"
                                                    height={113}
                                                    width={113}
                                                    className="apex-charts"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Text Links</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.text_links}</h1>
                                        </div>

                                        <div className="flex-shrink-0 text-center">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={getChartOptions(2)}
                                                    series={[(backlinkProjectDetails?.text_links / backlinkProjectDetails?.active_backlinks) * 100]}
                                                    type="radialBar"
                                                    height={90}
                                                    width={90}
                                                    className="apex-charts"
                                                />
                                            </div>
                                            <p className="fw-medium mb-0" style={{ "color": "#34c38f" }}> {((backlinkProjectDetails?.text_links / backlinkProjectDetails?.active_backlinks) * 100).toFixed(2)}%</p>

                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Image Links</p>
                                            <h1 className="mb-0">{backlinkProjectDetails?.image_links}</h1>
                                        </div>

                                        <div className="flex-shrink-0 text-center">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={getChartOptions(1)}
                                                    series={[(backlinkProjectDetails?.image_links / backlinkProjectDetails?.active_backlinks) * 100]}
                                                    type="radialBar"
                                                    height={90}
                                                    width={90}
                                                    className="apex-charts"
                                                />
                                            </div>
                                            <p className="fw-medium mb-0" style={{ "color": "#f1c40f" }}> {((backlinkProjectDetails?.image_links / backlinkProjectDetails?.active_backlinks) * 100).toFixed(2)}%</p>

                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Indexed/Not Indexed</p>
                                            <h1 className="mb-0">{indexProjectDetails?.page_indexed}/{indexProjectDetails?.page_not_indexed}</h1>
                                        </div>

                                        <div className="flex-shrink-0">
                                            <div id="radialchart-1 mb-2">
                                                <ReactApexChart
                                                    options={options_index}
                                                    series={[indexProjectDetails?.page_indexed, indexProjectDetails?.page_not_indexed]}
                                                    type="donut"
                                                    height={113}
                                                    width={113}
                                                    className="apex-charts"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" /> */}
                    <Row>
                        <Col lg="12">
                            <div className="row gy-2 gx-3 align-items-center">
                                {initialColumns.map((column, index) => (
                                    <div className="form-check form-check-primary mb-3 col-sm-auto">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={column.accessor}
                                            value={column.accessor}
                                            onChange={manageColumns}
                                            defaultChecked
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={column.accessor}
                                        >
                                            {column.Header}
                                        </label>
                                    </div>
                                ))}


                                {/* <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="target_url"
                                name="target_url"
                                value="target_url"
                                onChange={manageColumns}
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="target_url"
                            >
                                Target URL
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="source_url"
                                name="source_url"
                                value="source_url"
                                onChange={manageColumns}
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="source_url"
                            >
                                Source URL
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="backlink_status"
                                name="backlink_status"
                                value="backlink_status"
                                onChange={manageColumns}
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="backlink_status"
                            >
                                Backlink Status
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="backlink_status"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="backlink_status"
                            >
                                Backlink Status
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="anchors"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="anchors"
                            >
                                Anchors
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="external_link_count"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="external_link_count"
                            >
                                External Link Count
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="internal_link_count"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="internal_link_count"
                            >
                                Internal Link Count
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="dofollow"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="dofollow"
                            >
                                DoFollow
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="nofollow"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="nofollow"
                            >
                                NoFollow
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="sponsored"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="sponsored"
                            >
                                Sponsored
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="ugc"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="ugc"
                            >
                                UGC
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="text"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="text"
                            >
                                Text
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="image"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="image"
                            >
                                Image
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="link_homepage"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="link_homepage"
                            >
                                Link To Homepage
                            </label>
                        </div>
                        <div className="form-check form-checkbox-outline form-check-primary mb-3 col-sm-auto">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="found_url"
                                onChange={(e) => manageColumns(e)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="found_url"
                            >
                                Found URL
                            </label>
                        </div> */}
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="table-responsive">
                        <BootstrapTable keyField='id' data={urlList} columns={columns} pagination={paginationFactory()} />
                    </div> */}
                    <TableContainer
                        columns={memoizedColumns}
                        data={urlList}
                        isGlobalFilter={true}
                        isDownloadCSV={true}
                        handleExportToCsv={handleExportToCsv}
                        customPageSize={10}
                        className="custom-header-css"
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default BacklinkProjectDetails;
