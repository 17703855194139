import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

const ProjectNo = (cell) => {
    return <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const ProjectName = (cell) => {
    return cell.value ? cell.value : "";
};

const Pages = (cell) => {
    return cell.value ? cell.value : "";
};

const PageIndexed = (cell) => {
    return cell.value ? cell.value : "0";
};

const PageNotIndexed = (cell) => {
    return cell.value ? cell.value : "0";
};

const CreatedDate = (cell) => {
    return cell.value ? new Date(cell.value).toLocaleString() : "-";
};

const LastCheckedDate = (cell) => {
    const formattedDate = new Date(cell.value).toLocaleString();
    return formattedDate !== '1/1/1970' ? formattedDate : '-';
};

const Status = (cell) => {
    switch (cell.value) {
        case "Checked":
            return <Badge className="badge-soft-success">Checked</Badge>
        case "Pending":
            return <Badge className="badge-soft-info">Pending</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
            case "Part Time":
                return <Badge className="badge-soft-danger">Part Time</Badge>
    };
};

const Schedule = (cell) => {
    return cell.value ? cell.value : "";
};

// const Status = (cell) => {
//     switch (cell.value) {
//         case "Active":
//             return <Badge className="bg-success">Active</Badge>
//         case "New":
//             return <Badge className="bg-info">New</Badge>
//         case "Close":
//             return <Badge className="bg-danger">Close</Badge>
//     }
// };


export { ProjectNo, ProjectName, Pages, PageIndexed, PageNotIndexed, CreatedDate, LastCheckedDate, Status, Schedule };