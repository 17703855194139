import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { saveWpConfigProject, getProjectsByUser, buyCreditPackage, requestPayment } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import './CardElementStyles.css';
import Swal from "sweetalert2";

const SuccessPayment = () => {

    //meta title
    document.title = "Indexed Checker App";

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();


    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }
    const [amount, setAmount] = useState(0);
    const [credits, setCredits] = useState(0);

    const handleOpenPaymentModal = (amount, credits) => {
        setmodal_standard(true)
        setCredits(credits)
        setAmount(amount)
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    return (
        <React.Fragment>
            {
                (isLoading ? (
                    <Loading />
                ) : null)
            }
            <div className="page-content">

                <Container fluid>
                    {/* Render Breadcrumbs */}

                    <Row style={{ textAlign: "center", marginTop: "100px" }}>
                        <Card>
                            <CardBody>
                                {/* <CardTitle className="mb-4">Your Payment was Successful!</CardTitle> */}
                                <h1>Your Payment was Successful!</h1>
                                <h3 className="mb-4">Thank your for your payment.</h3>
                                <Button onClick={() => navigate('/projects-list')} style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                    Back to Projects
                                </Button>


                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SuccessPayment;
