import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { saveWpConfigProject, getProjectsByUser, buyCreditPackage, requestPayment, getCreditPackages } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import './CardElementStyles.css';
import Swal from "sweetalert2";
import CardPricing from "pages/Utility/card-pricing";

const CreditPackages = () => {

    //meta title
    document.title = "Credits Packages | Indexed Checker App";

    const [isLoading, setIsLoading] = useState(true);

    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('authUser')));

    const navigate = useNavigate();


    // const [modal_standard, setmodal_standard] = useState(false);
    // function tog_standard() {
    //     setmodal_standard(!modal_standard);
    //     removeBodyCss();
    // }
    // const [amount, setAmount] = useState(0);
    // const [credits, setCredits] = useState(0);

    // const [package_data, setPackageData] = useState();

    // const handleOpenPaymentModal = (package_dets) => {
    //     setmodal_standard(true)
    //     setCredits(package_dets.credits)
    //     setAmount(package_dets.price)
    //     setPackageData(package_dets)
    // }

    // function removeBodyCss() {
    //     document.body.classList.add("no_padding");
    // }

    // const stripe = useStripe();
    // const elements = useElements();
    // const [error, setError] = useState(null);
    // const [loading, setLoading] = useState(true);

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     setIsLoading(true);

    //     if (!stripe || !elements) {
    //         // Stripe.js has not loaded yet. Make sure to disable
    //         // form submission until Stripe.js has loaded.
    //         return;
    //     }

    //     const cardElement = elements.getElement(CardElement);

    //     try {
    //         const { token } = await stripe.createToken(cardElement);
    //         // Pass the token to your backend
    //         console.log(token, 'token');
    //         const p_data = {
    //             token: token.id,
    //             amount: amount * 100,
    //             credits: credits
    //         }
    //         requestPayment(p_data).then((res) => {
    //             console.log(res);
    //             if (res?.data?.response?.status == "succeeded") {
    //                 setmodal_standard(false);
    //                 setIsLoading(false);
    //                 Swal.fire("Added " + credits + " Credits!", "", "success")
    //                 window.location.reload()
    //             }
    //         }).catch((error) => {
    //             console.log({ error });
    //         }).finally(() => {
    //             setIsLoading(false);
    //         });
    //         // handleToken(token);
    //     } catch (error) {
    //         setError(error.message);
    //     }

    //     setLoading(false);
    // };

    const [creditPackages, setCrediPackages] = useState([]);

    useEffect(() => {
        getCreditPackages().then(res => {
            console.log(res, 'res');
            setCrediPackages(res?.data?.packages);
            setIsLoading(false)
        })
    }, [])

    return (
        <React.Fragment>
            {/* {
                (isLoading ? (
                    <Loading />
                ) : null)
            } */}
            <div className="page-content">

                {/* <Modal
                    isOpen={modal_standard}
                    toggle={() => {
                        tog_standard();
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Purchase Package
                        </h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <Card className="plan-box">
                                <CardBody className="p-4 text-center">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <h5>{package_data?.package_name}</h5>
                                            <p className="text-muted">{package_data?.description}</p>
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <h2>
                                            <sup>
                                                <small>$</small>
                                            </sup>{" "}
                                            {package_data?.price}.00
                                        </h2>
                                    </div>
                                    <div className="py-1">
                                        <h5>{package_data?.credits}{" "}Credits</h5>
                                    </div>
                                    <div className="py-1">
                                        <h6>${(package_data?.price / package_data?.credits)}{" "}per check</h6>
                                    </div>
                                </CardBody>
                            </Card>

                            <div>
                                <h6 className="modal-title mt-0 mb-3" id="myModalLabel">
                                    Add Card Details
                                </h6>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                        hidePostalCode: true,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit" disabled={!stripe || loading}
                                className="btn btn-primary "
                            >
                                Purchase
                            </button>
                            {error && <div>{error}</div>}
                        </div>
                    </form>
                </Modal> */}

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="Pricing" /> */}

                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-5">
                                <h4>Choose your Pricing plan</h4>
                                <p className="text-muted">
                                    We offer free and paid premium access plans to support ethical access for all webmasters.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    {
                        isLoading ? (
                            <Row>
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            Loading...
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                {/* {creditPackages.map((pricing, key) => (
                            <CardPricing pricing={pricing} key={"_pricing_" + key} />
                        ))} */}
                                {creditPackages.map((package1, index) => (
                                    <Col xl="3" md="6" key={index}>
                                        <Card className="plan-box">
                                            <CardBody className="p-4 text-center">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5>{package1.package_name}</h5>
                                                        {/* <p className="text-muted">{package1.description}</p> */}
                                                    </div>
                                                </div>
                                                <div className="py-4">
                                                    <h2>
                                                        <sup>
                                                            <small>$</small>
                                                        </sup>{" "}
                                                        {package1.price}.00
                                                    </h2>
                                                </div>
                                                <div className="py-1">
                                                    <h5>{package1.credits}{" "}Credits</h5>
                                                </div>
                                                <div className="py-1">
                                                    <h6>${package1.cost_per_credit}{" "}per check</h6>
                                                </div>
                                                <div className="text-center plan-btn">
                                                    {/* <Button onClick={() => handleOpenPaymentModal(package1)} style={{ "backgroundColor": "#00264c", "color": "#fff" }} className="btn btn-primary btn-md waves-effect waves-light ">
                                                Purchase
                                            </Button> */}
                                                    <form action={`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create-checkout-session/`} method="POST">
                                                        <input type="hidden" name="product_name" value={package1.package_name} />
                                                        <input type="hidden" name="price" value={(package1.price * 100)} />
                                                        <input type="hidden" name="user_token" value={userData?.access} />
                                                        <input type="hidden" name="package_id" value={package1.id} />
                                                        <Button className="btn btn-primary btn-md waves-effect waves-light" type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>Buy Now</Button>
                                                    </form>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}

                            </Row>
                        )}

                </Container>

                {/* <Container fluid>
                    <Breadcrumbs breadcrumbItem="Buy Credits" />

                    <Row>
                        <Card style={{ backgroundColor: "#ebebf4" }}>
                            <CardBody>
                                <Row style={{ textAlign: "center" }}>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Starter</CardTitle>
                                                <h1>$10.00</h1>
                                                <h3>1000 Credits</h3>
                                                <Button onClick={() => handleOpenPaymentModal(10, 1000)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Pro</CardTitle>
                                                <h1>$20.00</h1>
                                                <h3>2500 Credits</h3>
                                                <Button onClick={() => handleOpenPaymentModal(20, 2500)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Elite</CardTitle>
                                                <h1>$30.00</h1>
                                                <h3>5000 Credits</h3>
                                                <Button onClick={() => handleOpenPaymentModal(30, 5000)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Row>
                </Container> */}
            </div>
        </React.Fragment>
    );
};

export default CreditPackages;
