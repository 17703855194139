import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useNavigate} from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { savePrompt, getUserPrompts, saveProjectData, saveProject, updatePrompt, updateProject, genContOnlyPrompt, saveContentOnly, publishOnWordpress } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

const PromptManage = () => {

  //meta title
    document.title = "Create Single Project";

    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [selectedFiles, setselectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [mainPrompt, setMainPrompt] = useState();
    const [keywordsArr, setKeywordsArr] = useState([]);
    let [proPrompt, setProPrompt] = useState("");
    const [finalContent, setFinalContent] = useState("");

    const [promptData, setPromptData] = useState({prompt: "", prompt_name: "", is_public: false});

    const [loadingStatus, setLoadingStatus] = useState(null);
    const [contentData, setContentData] = useState(null);
    const [wpUrl, setWpUrl] = useState(null);
    const [saveStatus, setSaveStatus] = useState({status: 0, project_id: null, prompt_id: null});
    const [userPrompts, setUserPrompts] = useState([]);
    const [wordpress, setWordpress] = useState({user_name: "admin2", password: "6DT@w&6Mx%pNeVr&d@rv@OJk", wp_url: "https://testwp.kongcepts.info/wp", content: "", title: "General"});

    const [projectData, setProjectData] = useState({
        project_name: "",
        prompt: "",
        website: "",
        main_keyword: "",
        second_keyword: "",
        wordpress_email: "",
        org_prompt: "",
        code_model: "gpt-3.5-turbo-0301",
        proj_id: null,
        openaikey: ""
    });

  const startDateChange = date => {
    setstartDate(date);
  };

  const endDateChange = date => {
    setendDate(date);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

    const createPrompt = (e) => {
        let prompt = e?.target?.value ?? "";
        let regExp = /\[@(?<content>[^\[\]]+)\]/g;;
        if (prompt) {
            let checkKeyWords = Array.from(
                prompt.matchAll(regExp)
            ).map(
                ({ groups: { content } }) => content
            );

            let checkKeyWordsMaps = checkKeyWords.map((keyw, ind) => ({index: ind, key: keyw, value: ''}));
            setKeywordsArr(checkKeyWordsMaps);
            setMainPrompt(prompt);
            setProPrompt(prompt);
            setProjectData({...projectData, org_prompt: prompt});
            setPromptData({...promptData, prompt: prompt});
        }
    }

    const changeParams = (e, param) => {
        let vals = e?.target?.value ?? "";
        if (vals !== "") {
            let keywordsArrAlter = keywordsArr.map((key) => {
                if (key.index === param.index) {
                    return {...key, value: vals}
                }
                return key
            });
            setKeywordsArr(keywordsArrAlter);
        }

    }

    useEffect(() => {
        if (keywordsArr && keywordsArr.length > 0) {
            let proPromptData = mainPrompt;
            let mainKeyWords = projectData.main_keyword;
            let secondKeyWords = projectData.second_keyword;
            keywordsArr.forEach((keyData) => {
                if (keyData?.key.toLowerCase().includes('mainkey') || keyData?.key.toLowerCase().includes('mainkeyword')  || keyData?.key.toLowerCase().includes('mainkeywords')) {
                    mainKeyWords = keyData?.value;
                }
                if (keyData?.key.toLowerCase().includes('secondkey') || keyData?.key.toLowerCase().includes('secondkeyword')  || keyData?.key.toLowerCase().includes('secondkeywords')) {
                    secondKeyWords = keyData?.value;
                }
                proPromptData = proPromptData.replace(`[@${keyData.key}]`, keyData.value)
            });
            //proPrompt.replace(`[@${param.key}]`, e.target.value)
            setProPrompt(proPromptData);
            setProjectData({...projectData, main_keyword: mainKeyWords, second_keyword: secondKeyWords});
        }
    }, [keywordsArr, mainPrompt])


    useEffect(() => {
        if (proPrompt) {
            setProjectData({...projectData, prompt: proPrompt});
        }
    }, [proPrompt]);

    const savePromptData = (e) => {
        if (promptData?.prompt !== "" && promptData?.prompt_name !== "") {
            setIsLoading(true);
            savePrompt(promptData).then((response) => {
                if (response?.data) {
                    userPromptsList();
                }
            }).catch((error) => {
                console.log('error', error);
            }).finally(() => {
                setIsLoading(false);
            });
        }
        
    }

    useEffect(() => {
        userPromptsList();
    }, []);

    const userPromptsList = () => {
        getUserPrompts().then((userPrompts) => {
            if (userPrompts?.data?.prompts) {
                setUserPrompts(userPrompts?.data?.prompts);
            }
        }).catch((error) => {
            console.error('Error Occured');
        });
    }


  return (
    <React.Fragment>
      <div className="page-content">
        {
            isLoading ? (
                <Loading/>
            ) : null
        }

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Manage Prompts" breadcrumbItem="Prompt Manager" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Prompt</CardTitle>
                  <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        savePromptData(e);
                    }}
                  >                   
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Prompt Title
                          </Label>
                          <Col lg="10">
                            <Input
                              id="title"
                              name="title"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Enter Title"
                              onChange={(e) => {setPromptData({...promptData, prompt_name: e?.target?.value})}}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Prompt
                          </Label>
                          <Col lg="10">
                            <textarea
                              id="prompt"
                              name="prompt"
                              required
                              type="text"
                              className="form-control"
                              placeholder="Enter Prompt"
                              onChange={createPrompt}
                            ></textarea>
                          </Col>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Prompt Details
                          </Label>
                          <Col lg="8">
                            <div>
                                {
                                    keywordsArr.length > 0 ? (
                                        keywordsArr.map((param, ind) => {
                                            return (
                                                <div key={`param-${ind}`}>
                                                    <label>
                                                        <span>{ind + 1}. {param?.key}</span>
                                                        <input onChange={(e) => {changeParams(e, param)}} type="text" className="form-control"/>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    ) : (<p>No Parameters</p>)
                                }
                            </div>
                            {
                                projectData && (projectData.main_keyword !== "" || projectData.second_keyword !== "") ? (
                                    <>
                                        <p>Main Keywords: <span>{projectData.main_keyword}</span></p>
                                        <p>Second Keywords: <span>{projectData.second_keyword}</span></p>
                                    </>
                                ) : (null)
                            }
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Publicly available
                          </Label>
                          <Col lg="10">
                            <Input
                              id="title"
                              name="title"
                              type="checkbox"
                              className="form-control"
                              placeholder="Enter Title"
                              onChange={(e) => {setPromptData({...promptData, is_public: e?.target?.checked})}}
                            />
                          </Col>
                        </FormGroup>
                        <Row className="justify-content-end mb-4">
                            <Col lg="10">
                            <Button type="submit" color="primary">
                                Create New Prompt
                            </Button>
                            </Col>
                        </Row>                
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <h4>My Prompts</h4>
                        <div className="table-responsive">
                            <Table className="table table-centered table-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">Prompt</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (userPrompts && userPrompts.length > 0 ? (
                                            userPrompts.map((prompt, ind) => {
                                                return (
                                                    <tr key={`user-pr-${ind}`}>
                                                        <td>
                                                            <div>
                                                                <h5 className="text-truncate font-size-14">
                                                                   {prompt?.prompt}
                                                                </h5>
                                                                <p className="text-muted mb-0">{prompt?.prompt_name}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-primary" type="button">
                                                                <span>Edit Prompt</span>
                                                            </button>
                                                            <button className="btn btn-warning" style={{"marginLeft": "10px"}} type="button">
                                                                <span>Delete</span>
                                                            </button>
                                                        </td>
                                                    </tr> 
                                                )
                                            })
                                        ) : null)
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromptManage;
