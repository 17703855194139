import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, Modal, FormFeedback, Badge, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { Form as Form1 } from 'reactstrap';

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, getProjectDetails, recheckSingleURL, deleteSingleURL, addNewUrls, recheckProjectRequest, recheckMultipleURLs, getProjectStatusHistory, editProjectDetails, getResultsHistory } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import PropTypes from 'prop-types';
import StatusChart from "../projects/statusChart";

import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactApexChart from "react-apexcharts";

function ResultsLog(props) {

    const { id } = useParams();
    // console.log(id, 'id');

    const [isLoading, setIsLoading] = useState(true);
    const [projectDetails, setProjectDetails] = useState();
    const [projectHistory, setProjectHistory] = useState();
    const [urlList, setURLList] = useState([]);
    const [toggleStopCheckIndexed, setToggleStopCheckIndexed] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [toggleStatusChangeMail, setToggleStatusChangeMail] = useState(false);
    const [toggleMonitorAll, setToggleMonitorAll] = useState(false);

    const navigate = useNavigate();

    const [status_data, setStatus_data] = useState([]);

    useEffect(() => {
        getProjectAllDetails();
        getProjectAllHistory();
        getResultLogDetails();
    }, [])

    const getResultLogDetails = () => {
        getResultsHistory(id).then((project) => {
            console.log(project?.data, "project_dets_result_history");
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const getProjectAllDetails = () => {
        getProjectDetails(id).then((project) => {
            console.log(project?.data, "project_dets");
            setProjectDetails(project?.data?.project_dets)
            setStatus_data([
                { value: project?.data?.project_dets?.page_indexed, name: "Indexed" },
                { value: project?.data?.project_dets?.page_not_indexed, name: "Not Indexed" },
            ])
            setURLList(project?.data?.results)
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [seriesData, setSeriesData] = useState([]);
    const [xaxisCategories, setxaxisCategories] = useState([]);

    const getProjectAllHistory = () => {
        getProjectStatusHistory(id).then((project) => {
            console.log(project?.data, "getProjectAllHistory");
            setProjectHistory(project?.data?.project_history)
            setSeriesData(
                ['Total Pages', 'Indexed Pages', 'Not Indexed Pages'].map((displayName, index) => ({
                    name: displayName,
                    data: project?.data?.project_history.map(item => {
                        switch (index) {
                            case 0:
                                return item['pages'];
                            case 1:
                                return item['indexed_pages'];
                            case 2:
                                return item['not_indexed_pages'];
                            default:
                                return 0;
                        }
                    })
                }))
            );
            setxaxisCategories(
                project?.data?.project_history.map(item => {
                    const date = new Date(item.created_date);
                    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${date.toLocaleTimeString()}`;
                })
            )
            // setStatus_data([
            //     { value: project?.data?.project_dets?.page_indexed, name: "Indexed" },
            //     { value: project?.data?.project_dets?.page_not_indexed, name: "Not Indexed" },
            // ])
            // setURLList(project?.data?.results)
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [dropdownStates, setDropdownStates] = useState(Array(urlList.length).fill(false));

    const toggleDropdown = (index) => {
        const updatedDropdownStates = [...dropdownStates];
        updatedDropdownStates[index] = !updatedDropdownStates[index];
        setDropdownStates(updatedDropdownStates);
    };

    const recheckURL = (project) => {
        console.log(project, 'project');
        recheckSingleURL(project).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckProject = () => {
        console.log(projectDetails, 'project');
        recheckProjectRequest(projectDetails).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckMultiURLs = (url_arr) => {
        console.log(url_arr, 'url_arr');
        recheckMultipleURLs(url_arr).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckNIURLs = () => {
        console.log(urlList, 'urlList');
        const NIurlList = urlList.filter(url => url.status === "Not Indexed");
        console.log(NIurlList, 'NIurlList');
        recheckMultipleURLs(NIurlList).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const exportToCsv = () => {
        const fieldsToExport = ['url', 'first_checked_date', 'last_checked_date', 'status', 'url_status'];
        const fieldName = ['URL', 'Created Date', 'Last Checked Date', 'Status', 'URL Status'];

        // Function to format a date object to "YYYY-MM-DD" format
        const formatDate = (date) => {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const hours = String(formattedDate.getHours()).padStart(2, '0');
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const headerRow = fieldName.join(',') + '\n';

        const csvContent = "data:text/csv;charset=utf-8," + headerRow +
            urlList.map(row => {
                return fieldsToExport.map(field => {
                    // Check if the field is a date field, and format it accordingly
                    return field.includes('date') ? formatDate(row[field]) : row[field];
                }).join(',');
            }).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", projectDetails.project_name + "_export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const [settingsMenu, setSettingsMenu] = useState(false)

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    const deleteURL = (url) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSingleURL(url.id).then((res) => {
                    console.log(res, "response");

                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    getProjectAllDetails();
                }).catch((error) => {
                    console.log({ error });
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    const [modalAddUrl, setModalAddUrl] = useState(false);
    const [estimatedCounts, setEstimatedCounts] = useState(0);

    const handleOpenNewUrlModal = () => {
        setModalAddUrl(!modalAddUrl);
        setEstimatedCounts(0);
    }
    // const headers = Object.keys(urlList[0]);

    const columns = [
        // {
        //     dataField: 'id',
        //     text: 'Product ID',
        //     sort: true
        // },
        {
            dataField: 'url',
            text: 'URL',
            sort: true,
            // filter: textFilter()
            formatter: (cell, row) => {
                return (
                    <>
                        {/* {(row.status == 'Checked' ? (
                            <Link to={'/project-details/' + row.id}>{cell}</Link>
                        ) : (
                            <p>{cell}</p>
                        ))} */}
                        <Link to={'/url-history/' + row.id}>{cell}</Link>
                    </>
                );
            },
        }, {
            dataField: 'last_checked_date',
            text: 'Last Checked Date',
            sort: true,
            formatter: (cell) => {
                const formattedDate = new Date(cell).toLocaleDateString();
                return formattedDate !== '1/1/1970' ? new Date(cell).toLocaleString() : '-';
            }
        }, {
            dataField: 'prev_status',
            text: 'Previous Status',
            sort: true,
            formatter: (cell) => (
                <span className={`badge rounded-pill badge-soft-secondary font-size-11`}>
                    {cell}
                </span>
            )
        }, {
            dataField: 'status',
            text: 'Current Status',
            sort: true,
            formatter: (cell) => (
                <span className={`badge rounded-pill badge-soft-${cell === "Indexed" ? 'success' : cell === "Not Indexed" ? 'warning' : 'info'} font-size-11`}>
                    {cell}
                </span>
            )
        }];

    const selectRow1 = {
        mode: 'checkbox',
        clickToSelect: false,
        hideSelectColumn: true,
        // bgColor: '#00BFFF',
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row.id, 'rowId');
            console.log(isSelect, 'isSelect');
            console.log(rowIndex, 'rowIndex');
            console.log(e);
        },
    };

    const [selectedRows, setSelectedRows] = useState([])


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelectedRows([...selectedRows, row]);
            } else {
                setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row));
            }
            // console.log(row, 'rowId');
            // console.log(isSelect, 'isSelect');
            // console.log(rowIndex, 'rowIndex');
            // console.log(e);
        },
        hideSelectAll: true,
    };

    const chart_data = {
        series: seriesData,
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: xaxisCategories
            }
        }
    };

    const [modal_standard, setmodal_standard] = useState(false);
    const [projectData, setProjectData] = useState();

    const handleOpenEditModal = () => {
        setmodal_standard(true);
        setToggleStopCheckIndexed(projectDetails?.stop_check_indexed === 'True')
        setToggleSendMails(projectDetails?.send_mails === 'True')
        setToggleStatusChangeMail(projectDetails?.notify_deindex === 'True')
        setToggleMonitorAll(projectDetails?.monitor_url === 'True')
        setProjectData({
            id: projectDetails.id,
            project_name: projectDetails.project_name,
            project_desc: projectDetails.description,
            project_schedule: projectDetails.schedule,
            stop_check_indexed: projectDetails?.stop_check_indexed === 'True',
            send_mails: projectDetails?.send_mails === 'True',
            send_mails_status_change: projectDetails?.notify_deindex === 'True',
            monitor_url: projectDetails?.monitor_url === 'True',
        })
        console.log(projectDetails, 'project');
    }

    const handleProjectUpdate = (e) => {
        console.log(projectData, 'projectData');
        editProjectDetails(projectData).then(r => {
            // tog_standard()
            setmodal_standard(false);
            getProjectAllDetails()
            Swal.fire("Project Details Has Updated!", "", "success")
        }).catch(e => {
            console.log(e.response.data)
        })
    }
    const [newUrls, setNewUrl] = useState()

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const urls = textareaValue.split('\n').map(line => line.trim()).filter(line => line !== '');

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Filter out non-URL values and remove duplicates
        const uniqueUrls = Array.from(new Set(urls.filter(url => urlRegex.test(url))));

        const newUniqueUrls = uniqueUrls.filter(url => !urlList.map(item => item.url).includes(url));

        console.log(newUniqueUrls, 'newUniqueUrls');


        setEstimatedCounts(newUniqueUrls.length);
        setNewUrl(newUniqueUrls.join('\n'));
    };

    const handleNewUrls = () => {
        console.log(newUrls, 'newUrls');
        const urlsData = {
            id: id,
            urls: newUrls,
        }
        console.log(urlsData, "urlsData")
        addNewUrls(urlsData).then(r => {
            // tog_standard()
            setModalAddUrl(false);
            getProjectAllDetails();
            Swal.fire("Added New URLs!", "", "success")
        }).catch(e => {
            console.log(e.response.data)
        })
    }

    return (
        <div className="page-content">
            {
                isLoading ? (
                    <Loading />
                ) : null
            }

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    setmodal_standard(!modal_standard);
                }}
            >
                <Form1
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleProjectUpdate(e);
                    }}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Edit Project Details
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                                Project Name
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="project_name"
                                name="project_name"
                                placeholder="Enter Your Project Name"
                                onChange={(e) => { setProjectData({ ...projectData, project_name: e?.target?.value }) }}
                                value={projectData?.project_name}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                                Description
                            </Label>
                            <textarea
                                id="description"
                                name="description"
                                required
                                type="text"
                                rows={8}
                                className="form-control"
                                placeholder="Enter Description..."
                                onChange={(e) => { setProjectData({ ...projectData, project_desc: e?.target?.value }) }}
                                value={projectData?.project_desc}
                            ></textarea>
                        </div>
                        <Row>
                            <Col lg="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-phoneno-input32">
                                        Recheck Period
                                    </Label>
                                    <select className="form-control" name="schedule" id="schedule"
                                        value={projectData?.schedule}
                                        onChange={(e) => { setProjectData({ ...projectData, project_schedule: e?.target?.value }) }}>
                                        <option value="No">No</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="stop_check_indexed"
                                        >
                                            Stop rechecking urls for pages that are already indexed
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stop_check_indexed"
                                            name="stop_check_indexed"
                                            checked={projectData?.stop_check_indexed}
                                            onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="send_mails"
                                        >
                                            Be notified via email when these pages are checked
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="send_mails"
                                            name="send_mails"
                                            checked={projectData?.send_mails}
                                            onClick={(e) => { setProjectData({ ...projectData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="send_mails_status_change"
                                        >
                                            Be notified via email after change of index status of any url
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="send_mails_status_change"
                                            name="send_mails_status_change"
                                            checked={projectData?.send_mails_status_change}
                                            onClick={(e) => { setProjectData({ ...projectData, send_mails_status_change: !toggleStatusChangeMail }), setToggleStatusChangeMail(!toggleStatusChangeMail) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="monitor_url"
                                        >
                                            Monitor all url of the project
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="monitor_url"
                                            name="monitor_url"
                                            checked={projectData?.monitor_url}
                                            onClick={(e) => { setProjectData({ ...projectData, monitor_url: !toggleMonitorAll }), setToggleMonitorAll(!toggleMonitorAll) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            style={{ "backgroundColor": "#00264c", "color": "#fff" }}
                            className="btn btn-lg btn-primary "
                        >
                            Submit
                        </button>
                    </div>
                </Form1>
            </Modal>

            <Modal
                isOpen={modalAddUrl}
                toggle={() => {
                    handleOpenNewUrlModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Add New URLs
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModalAddUrl(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Form1
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleNewUrls(e);
                    }}>
                    <div className="modal-body">

                        <div className="mb-3">

                            <Label
                                htmlFor="website"
                                className="col-form-label col-lg-6"
                            >
                                Website URLs
                            </Label>
                            <Col lg="12">
                                <textarea
                                    id="urls"
                                    name="urls"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Website URLs here..."
                                    rows={10}
                                    onChange={(e) => {
                                        handleTextareaChange(e)
                                    }}
                                ></textarea>
                                <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                            </Col>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary "
                        >
                            Submit
                        </button>
                    </div>
                </Form1>
            </Modal>

            <Link to={'/project-details/' + id}><i className="bx bx-laptop"></i> Project Details</Link>
            <Container fluid>
                {/* Render Breadcrumbs */}
                {/* <Breadcrumbs title="Projects" breadcrumbItem={projectDetails?.project_name} /> */}

                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col lg="7">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <h5 className="mb-1" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{projectDetails?.project_name}</h5>
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>History Log</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </CardBody>

                        </Card>
                        <Row>
                            <Col>
                                <CardBody>
                                    {/* <div className="flex-shrink-0 mb-2">
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => handleOpenNewUrlModal()}>Add New URLs</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => recheckProject()}>Recheck All</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => recheckNIURLs()} disabled={projectDetails?.page_not_indexed == 0}>Recheck Not Index({projectDetails?.page_not_indexed})</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} disabled={selectedRows.length == 0} onClick={() => recheckMultiURLs(selectedRows)}>Recheck({selectedRows.length})</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => exportToCsv()}>Export to CSV</button>
                                        <button style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => window.location.reload()} className="btn btn-light me-1"><i className="bx bx-slider-alt"></i></button>
                                    </div> */}
                                </CardBody>
                            </Col>
                        </Row>


                        {/* <div>
                            <CSVLink data={urlList} headers={headers}>
                                Download CSV
                            </CSVLink>
                        </div> */}
                        <Card>
                            <CardBody>
                                {/* <CardTitle className="h4">Hoverable </CardTitle> */}

                                <div className="table-responsive">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={urlList}
                                        columns={columns}
                                        // selectRow={selectRow}
                                        filter={filterFactory()}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
ResultsLog.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default ResultsLog;
