import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import { getDeindexedUrls } from "services/main.service";
import { useNavigate } from "react-router-dom";

const DeindexedReport = () => {


    const searchParams = new URLSearchParams(window.location.search)

    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
            <div>
                <p>{`This Expand row is belong to rowKey ${row.project_name}`}</p>
                {row.details.map((url, index) => (
                    <><p>{url.url}</p></>
                ))}
            </div>
        )
    };

    const columns = [{
        dataField: 'project_name',
        text: 'Product Name'
    }, {
        dataField: 'details.length',
        text: 'URL Count'
    }];

    const [results, setResults] = useState([]);
    const [checkedResults, setCheckedResults] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {
        getDetails();
    }, [])

    const getDetails = () => {
        getDeindexedUrls().then((project) => {
            console.log(project?.data, "project_dets");
            setCheckedResults(project?.data.checked_results)
            console.log(groupByProjectId(project?.data.checked_results), "pdsadts");
            setResults(groupByProjectId(project?.data.checked_results))
        }).catch((error) => {
            console.log({ error });
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function groupByProjectId(data) {
        const groupedData = {};

        data.forEach(item => {
            const { project_id, project_name, ...details } = item;

            if (!groupedData[project_id]) {
                groupedData[project_id] = {
                    project_id,
                    project_name,
                    details: []
                };
            }

            groupedData[project_id].details.push(details);
        });

        return Object.values(groupedData);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" /> */}
                    <Row>
                        <Col lg="12" className="mb-2">
                            <Row>
                                <Col lg="4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 align-self-center">
                                            <h5 className="mb-1">De-indexed URLs Report</h5>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="6" className="align-self-center">
                                </Col>

                                <Col lg="2" className="d-none d-lg-block">
                                    <Button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/status-report')}>Project Status Report</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            {/* <BootstrapTable
                                keyField='id'
                                data={results}
                                columns={columns}
                                expandRow={expandRow}
                            /> */}
                            <div className="table-responsive">
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th>Project Name</th>
                                            <th>De-indexed URL count</th>
                                            <th style={{ "textAlign": "center" }}></th>
                                        </tr>
                                    </thead>
                                    {(results.length > 0 ?
                                        <tbody>
                                            {results.map((project, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td scope="row">{project.project_name}</td>
                                                        <td>{project.details.length}</td>
                                                        <td style={{ "textAlign": "center" }}>
                                                            <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/report-log/' + project.project_id + '?type=de-indexed')}>View</button>
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        {project.details.map((url, innerIndex) => (
                                                            <td colSpan="2">
                                                                <div>
                                                                    <p>{`URL: ${url.url}`}</p>
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr> */}
                                                </React.Fragment>
                                            ))}

                                        </tbody> :
                                        <tbody>
                                            <tr>
                                                <td colspan="4" className="mt-2">
                                                    <center>
                                                        <h5>No data found!</h5>
                                                    </center>
                                                </td>
                                            </tr>
                                        </tbody>)}
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DeindexedReport;
