import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createBacklinkProject, getUserPackageDetails } from "services/main.service";
import Loading from "components/Common/Loading";
import { useNavigate } from "react-router-dom";

const CreateBacklinkProject = () => {

    //meta title
    document.title = "Create Backlink Project | Indexed Checker App";

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [toggleBacklinkCheck, setToggleBacklinkCheck] = useState(false);
    const [toggleIndexCheck, setToggleIndexCheck] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [estimatedCounts, setEstimatedCounts] = useState(0);
    const [userPackageDets, setUserPackageDets] = useState();
    const [backlinkData, setBacklinkData] = useState({
        domain: "",
        checking_urls: "",
        backlink_recheck: toggleBacklinkCheck,
        backlink_recheck_time: "Daily",
        index_check: toggleIndexCheck,
        project_schedule: "No",
        send_mails: toggleSendMails,
        // check_dindex: toggleCheckDIndex,
        // stop_check_indexed: toggleStopCheckIndexed,
        // monitor_url: toggleMonitorAll
    });

    useEffect(()=>{
        getUserPackageDetails().then((project) => {
            console.log(project?.data, "dets");
            setUserPackageDets(project?.data.user_package)
        }).catch((error) => {
            console.log({ error });
        })
    }, [])

    const [isValidUrl, setIsValidUrl] = useState(true)

    const handleDomainValidate = (e) => {
        setIsValidUrl(false)
        const inputValue = e.target.value;
        setBacklinkData({ ...backlinkData, domain: inputValue });

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Check if the input value matches the URL regex pattern
        // const isValidUrl = urlRegex.test(inputValue);
        setIsValidUrl(urlRegex.test(inputValue));

        if (isValidUrl) {
            console.log(isValidUrl, 'Valid URL');
            // You can add further actions if the URL is valid
        } else {
            console.log(isValidUrl, 'Invalid URL');
            // You can add further actions if the URL is invalid
        }
    };

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const urls = textareaValue.split('\n').map(line => line.trim()).filter(line => line !== '');

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Filter out non-URL values and remove duplicates
        const uniqueUrls = Array.from(new Set(urls.filter(url => urlRegex.test(url))));

        console.log(uniqueUrls, 'uniqueUrls');

        const limitedUrls = uniqueUrls.slice(0, 100);

        setEstimatedCounts(uniqueUrls.length);
        setBacklinkData({ ...backlinkData, checking_urls: uniqueUrls.join('\n') });
    };

    const handleCreateProject = (e) => {
        setIsLoading(true)

        const proj_data = {
            domain: backlinkData.domain,
            checking_urls: backlinkData.checking_urls,
            backlink_recheck: backlinkData.backlink_recheck,
            index_check: backlinkData.index_check,
            project_schedule: backlinkData.project_schedule,
            backlink_recheck_schedule: backlinkData.backlink_recheck_time,
            send_mails: backlinkData.send_mails,
            url_count: estimatedCounts
        }


        console.log(proj_data, 'proj_data');

        createBacklinkProject(proj_data).then((res) => {
            console.log(res, 'promptRes');
            setIsLoading(false)
            navigate('/backlink-projects')
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="New Project" breadcrumbItem="Backlink" /> */}
                    <Row>
                        <Col xl={8}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">New Backlink Project</CardTitle>

                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleCreateProject(e);
                                        }}>
                                        <div className="mb-3">
                                            <Label htmlFor="domain">Target Domain/URL</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="domain"
                                                placeholder="Enter Target Domain"
                                                onChange={handleDomainValidate}
                                            />
                                            {(!isValidUrl ? <p className="mt-2" style={{ "color": "red" }}>Domain is not valid!</p> : null)}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="checking_urls">Checking Backlinks ({estimatedCounts})</Label>
                                            <textarea
                                                id="checking_urls"
                                                name="checking_urls"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Urls..."
                                                onChange={handleTextareaChange}
                                                // value={checkingUrl}
                                                rows={15}
                                            // readOnly
                                            ></textarea>
                                            <p className="mt-2">Remaining Backlink Credits - {userPackageDets?.backlink_credits}</p>
                                        </div>

                                        <div className="form-check form-switch form-switch-md mb-3">
                                            <label
                                                className="form-check-label"
                                                htmlFor="backlink_recheck"
                                            >
                                                Monitor Backlink
                                            </label>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="backlink_recheck"
                                                onClick={(e) => { setBacklinkData({ ...backlinkData, backlink_recheck: !toggleBacklinkCheck }), setToggleBacklinkCheck(!toggleBacklinkCheck) }}
                                            />
                                        </div>

                                        {(toggleBacklinkCheck ? (
                                            <div className="mb-3">
                                                <Label htmlFor="backlink_recheck_time">
                                                    Backlink Recheck Period
                                                </Label>
                                                <select className="form-control" name="backlink_recheck_time" id="backlink_recheck_time"
                                                    onChange={(e) => { setBacklinkData({ ...backlinkData, backlink_recheck_time: e?.target?.value }) }}>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                            </div>
                                        ) : null)}

                                        <div className="form-check form-switch form-switch-md mb-3">
                                            <label
                                                className="form-check-label"
                                                htmlFor="index_check"
                                            >
                                                Check Index Status
                                            </label>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="index_check"
                                                onClick={(e) => { setBacklinkData({ ...backlinkData, index_check: !toggleIndexCheck }), setToggleIndexCheck(!toggleIndexCheck) }}
                                            />
                                        </div>

                                        {(toggleIndexCheck ?
                                            <div className="mb-3">
                                                <Label htmlFor="index_recheck_time">
                                                    Index Recheck Period
                                                </Label>
                                                <select className="form-control" name="index_recheck_time" id="index_recheck_time"
                                                    onChange={(e) => { setBacklinkData({ ...backlinkData, project_schedule: e?.target?.value }) }}>
                                                    <option value="No">No</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                            </div>
                                            : null)}

                                        <div className="form-check form-switch form-switch-md mb-3">
                                            <label
                                                className="form-check-label"
                                                htmlFor="send_mails"
                                            >
                                                Receive email notifications once these backlinks have been checked
                                            </label>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="send_mails"
                                                onClick={(e) => { setBacklinkData({ ...backlinkData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                            />
                                        </div>

                                        <div>
                                            <button type="submit" className="btn btn-primary w-md mt-3">
                                                Create Project
                                            </button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateBacklinkProject;
