import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { saveWpConfigProject, getProjectsByUser, buyCreditPackage } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const BuyCreditsOld = () => {

    //meta title
    document.title = "Buy Credits";

    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [amount, setAmount] = useState(0);
    const [credits, setCredits] = useState(0);

    const navigate = useNavigate();

    const handleBuyPackage = async (price, credits) => {
        setAmount(price);
        setCredits(credits);
    };

    const handlePayment = async () => {
        setIsLoading(true);
        try {
            // Create PaymentMethod using CardElement
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });
            if (error) {
                console.error('Error:', error);
                return;
            }
            const paymentDets = {
                amount: price,
                credits: credits,
                paymentMethodId: paymentMethod.id
            };
            buyCreditPackage(paymentDets).then((res) => {
                console.log(res, "response");
            }).catch((error) => {
                console.log({ error });
            })
            // Send paymentMethod.id and amount to your backend for payment processing
            // Example: axios.post('/api/payment', { paymentMethodId: paymentMethod.id, amount: amount })
            console.log('Payment successful:', paymentMethod);
        } catch (error) {
            console.error('Error:', error);
            // Handle error
        } finally {
            setIsLoading(false);
        }
    };

    const buyPackage = (price, credits) => {
        const packageDets = {
            amount: price,
            credits: credits,
        };
        console.log(packageDets, 'packageDets');
        buyCreditPackage(packageDets).then((res) => {
            console.log(res, "response");
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Buy Credits" />

                    <Row>
                        <Card style={{ backgroundColor: "#ebebf4" }}>
                            <CardBody>
                                <Row style={{ textAlign: "center" }}>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Starter</CardTitle>
                                                <h1>$10.00</h1>
                                                <h3>1000 Credits</h3>
                                                {/* <Button onClick={() => handleBuyPackage(10, 1000)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button> */}
                                                <form action={`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create-checkout-session/`} method="POST">
                                                    <input type="hidden" name="product_name" value="Starter" />
                                                    <input type="hidden" name="price" value={10 * 100} />
                                                    <Button className="btn-checkout" type="submit" style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>Buy Now</Button>
                                                </form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Pro</CardTitle>
                                                <h1>$20.00</h1>
                                                <h3>2500 Credits</h3>
                                                <form action={`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create-checkout-session/`} method="POST">
                                                    <input type="hidden" name="product_name" value="Starter" />
                                                    <input type="hidden" name="price" value={20 * 100} />
                                                    <Button className="btn-checkout" type="submit" style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>Buy Now</Button>
                                                </form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Elite</CardTitle>
                                                <h1>$30.00</h1>
                                                <h3>5000 Credits</h3>
                                                <form action={`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create-checkout-session/`} method="POST">
                                                    <input type="hidden" name="product_name" value="Starter" />
                                                    <input type="hidden" name="price" value={30 * 100} />
                                                    <Button className="btn-checkout" type="submit" style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>Buy Now</Button>
                                                </form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Row>
                    {/* <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
                    <Button onClick={handlePayment} disabled={!stripe || isLoading}>Pay ${amount}</Button> */}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BuyCreditsOld;
