import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Breadcrumb, Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row, Table, UncontrolledTooltip } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, recheckProjectRequest, deleteIndexProject, editProjectDetails } from "../../services/main.service";

import Loading from "../../components/Common/Loading";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import BootstrapTable from 'react-bootstrap-table-next';
import TableContainer from "components/Common/TableContainer";

import {
    ProjectNo, ProjectName, Pages, PageIndexed, PageNotIndexed, CreatedDate, LastCheckedDate, Status, Schedule
}
    from "./projectListCol";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const ProjectsListNew = () => {

    //meta title
    document.title = "All Projects | Indexed Checker App";

    const [isLoading, setIsLoading] = useState(true);
    const [projectListOld, setProjectListOld] = useState([]);
    const [projectList, setProjectList] = useState([]);

    const navigate = useNavigate();

    const [singlebtn, setSinglebtn] = useState(false)


    useEffect(() => {

        getAllProjects();

        getProjectsByUser().then((projects) => {
            if (projects?.data?.projects) {
                // console.log(projects?.data?.projects, "projects");
                setProjectListOld(projects?.data?.projects)
            }
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });


    }, [])

    const getAllProjects = () => {
        getAllProjectsByUser().then((projects) => {
            if (projects?.data?.projects) {
                console.log(projects?.data?.projects, "projects_new");
                setProjectList(projects?.data?.projects)
                setIsLoading(false);
            }
        }).catch((error) => {
            console.log({ error });
            setIsLoading(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleRedirectProjectDetails(project_data) {
        navigate('/project-details/' + project_data.id)
        console.log(project_data, 'class_dets');
    }

    const [dropdownStates, setDropdownStates] = useState(Array(projectList.length).fill(false));

    const toggleDropdown = (index) => {
        console.log(index, 'index');
        const updatedDropdownStates = [...dropdownStates];
        updatedDropdownStates[index] = !updatedDropdownStates[index];
        setDropdownStates(updatedDropdownStates);
    };

    const recheckProject = (project) => {
        console.log(project, 'project');
        recheckProjectRequest(project).then((res) => {
            console.log(res, "response");
            getAllProjects();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const deleteProject = (project) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteIndexProject(project.id).then((res) => {
                    console.log(res, "response");

                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    getAllProjects();
                }).catch((error) => {
                    console.log({ error });
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    const [modal_standard, setmodal_standard] = useState(false);
    const [project_data, setProjectData] = useState();

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const handleOpenEditModal = (project) => {
        setmodal_standard(true);
        setProjectData(project)
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    const columns_old = [
        {
            dataField: 'id',
            text: 'Project ID',
        },
        {
            dataField: 'project_name',
            text: 'Project Name',
        }, {
            dataField: 'pages',
            text: 'Pages',
        }, {
            dataField: 'page_indexed',
            text: 'Page Indexed',
            formatter: (cell, row) => `${cell} (${((cell / row.pages) * 100).toFixed(0)}%)`
        }, {
            dataField: 'page_not_indexed',
            text: 'Page Not Indexed',
            formatter: (cell, row) => `${cell} (${((cell / row.pages) * 100).toFixed(0)}%)`
        }, {
            dataField: 'created_date',
            text: 'Creation Date',
            formatter: (cell) => new Date(cell).toLocaleString()
        }, {
            dataField: 'last_checked_date',
            text: 'Last Checked Date',
            formatter: (cell) => {
                const formattedDate = new Date(cell).toLocaleDateString();
                return formattedDate !== '1/1/1970' ? formattedDate : '-';
            }
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => (
                <span className={`badge rounded-pill badge-soft-${cell === "Checked" ? 'success' : 'secondary'} font-size-11`}>
                    {cell}
                </span>
            )
        }, {
            dataField: 'schedule',
            text: 'Recheck Period',
        }, {
            dataField: 'view',
            // text: 'Action',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    // <div className="btn-group btn-group" role="group" aria-label="Basic example">
                    //     {row.status === "Checked" ? (
                    //         <button type="button" className="btn btn-success"
                    //             onClick={() => {
                    //                 handleRedirectProjectDetails(row);
                    //             }}
                    //         >View</button>
                    //     ) : (
                    //         <button type="button" className="btn btn-outline-primary" disabled>View</button>
                    //     )}

                    //     <button type="button" className="btn btn-warning" onClick={() => recheckProject(row)}>Recheck</button>
                    //     <button type="button" className="btn btn-primary" onClick={() => handleOpenEditModal(row)}>Edit</button>
                    //     <button type="button" className="btn btn-danger" onClick={() => deleteProject(row)}>Delete</button>
                    // </div>

                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <Button className="btn btn-sm btn-soft-primary"
                                onClick={() => {
                                    handleRedirectProjectDetails(cellProps.row.original);
                                }}>
                                <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Button>
                        </li>
                        <UncontrolledTooltip placement="top" target="viewtooltip">
                            View
                        </UncontrolledTooltip>

                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <Button className="btn btn-sm btn-soft-success"
                                onClick={() => {
                                    recheckProject(cellProps.row.original);
                                }}>
                                <i className="bx bx-rotate-right" id="rechecktooltip"></i></Button>
                        </li>
                        <UncontrolledTooltip placement="top" target="rechecktooltip">
                            Recheck
                        </UncontrolledTooltip>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => {
                                    const jobData = cellProps.row.original;
                                    handleOpenEditModal(jobData);
                                }}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const jobData = cellProps.row.original;
                                    deleteProject(jobData);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                    </ul>

                    // <Dropdown
                    //     isOpen={dropdownStates[rowIndex]}
                    //     toggle={() => toggleDropdown(rowIndex)}
                    // >
                    //     <DropdownToggle className="btn btn-secondary" caret>
                    //         <i className="bx bx-menu" />
                    //     </DropdownToggle>
                    //     <DropdownMenu>
                    //         <DropdownItem onClick={() => recheckProject(row)}>Recheck</DropdownItem>
                    //         <DropdownItem onClick={() => handleOpenEditModal(row)}>Edit</DropdownItem>
                    //         <DropdownItem onClick={() => deleteProject(row)}>Delete</DropdownItem>
                    //     </DropdownMenu>
                    // </Dropdown>
                )
            }



        }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const columns = useMemo(
        () => [
            // {
            //     Header: 'No',
            //     accessor: 'id',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ProjectNo {...cellProps} />;
            //     }
            // },
            {
                Header: 'Project Name',
                accessor: 'project_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProjectName {...cellProps} />;
                }
            },
            {
                Header: 'Pages',
                accessor: 'pages',
                filterable: true,
                Cell: (cellProps) => {
                    return <Pages {...cellProps} />;
                }
            },
            {
                Header: 'Page Indexed',
                accessor: 'page_indexed',
                filterable: true,
                Cell: (cellProps) => {
                    return <PageIndexed {...cellProps} />;
                }
            },
            {
                Header: 'Page Not Indexed',
                accessor: 'page_not_indexed',
                filterable: true,
                Cell: (cellProps) => {
                    return <PageNotIndexed {...cellProps} />;
                }
            },
            {
                Header: 'Creation Date',
                accessor: 'created_date',
                Cell: (cellProps) => {
                    return <CreatedDate {...cellProps} />;
                }
            },
            {
                Header: 'Last Checked Date',
                accessor: 'last_checked_date',
                Cell: (cellProps) => {
                    return <LastCheckedDate {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Recheck Period',
                accessor: 'schedule',
                Cell: (cellProps) => {
                    return <Schedule {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Button className="btn btn-sm btn-soft-primary"
                                    onClick={() => {
                                        handleRedirectProjectDetails(cellProps.row.original);
                                    }}>
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Button>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Button className="btn btn-sm btn-soft-success"
                                    onClick={() => {
                                        recheckProject(cellProps.row.original);
                                    }}>
                                    <i className="bx bx-rotate-right" id="rechecktooltip"></i></Button>
                            </li>
                            <UncontrolledTooltip placement="top" target="rechecktooltip">
                                Recheck
                            </UncontrolledTooltip>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handleOpenEditModal(jobData);
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        deleteProject(jobData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Projects" breadcrumbItem="All Projects" />

                    {
                        isLoading ? (
                            <Row>
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            Loading...
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        {(projectList && projectList.length > 0) ? (
                                            <CardBody>

                                                <div className="mb-2">
                                                    <button className="btn" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/new-project')}>Add New Project</button>
                                                </div>

                                                <div className="table-responsive">
                                                    {/* <Table className="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Project Name</th>
                                                        <th>Pages</th>
                                                        <th>Page Indexed</th>
                                                        <th>Page Not Indexed</th>
                                                        <th>Creation Date</th>
                                                        <th>Last Checked Date</th>
                                                        <th>Status</th>
                                                        <th>Recheck Period</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (projectList && projectList.length > 0 ? (
                                                            projectList.map((project, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{project.project_name}</td>
                                                                    <td>{project.pages}</td>
                                                                    <td style={{ "color": "#198754" }}>{project.page_indexed} ({(project.page_indexed / project.pages) * 100}%)</td>
                                                                    <td style={{ "color": "#cc9a06" }}>{project.page_not_indexed} ({(project.page_not_indexed / project.pages) * 100}%)</td>
                                                                    <td>{new Date(project.created_date).toLocaleDateString() !== '1/1/1970' ? new Date(project.created_date).toLocaleDateString() + ' ' + new Date(project.created_date).toLocaleTimeString() : '-'}</td>
                                                                    <td>{new Date(project.last_checked_date).toLocaleDateString() !== '1/1/1970' ? new Date(project.last_checked_date).toLocaleDateString() + ' ' + new Date(project.last_checked_date).toLocaleTimeString() : '-'}</td>
                                                                    <td>
                                                                        {(project.status == "Checked") ? (
                                                                            <span className="badge rounded-pill badge-soft-success font-size-11">{project.status}</span>
                                                                        ) : (
                                                                            <span className="badge rounded-pill badge-soft-secondary font-size-11">{project.status}</span>
                                                                        )}

                                                                    </td>
                                                                    <td>{project.schedule}</td>
                                                                    <td>
                                                                        {(project.status == "Checked") ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn"
                                                                                style={{ "backgroundColor": "#3FC072", "color": "#fff" }}
                                                                                onClick={() =>
                                                                                    handleRedirectProjectDetails(project)
                                                                                }
                                                                            >
                                                                                View
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-primary"
                                                                                disabled
                                                                            >
                                                                                View
                                                                            </button>
                                                                        )}

                                                                    </td>
                                                                    <td>
                                                                        <Dropdown
                                                                            isOpen={dropdownStates[index]}
                                                                            toggle={() => toggleDropdown(index)}
                                                                        >
                                                                            <DropdownToggle className="btn btn-secondary" caret>
                                                                                <i className="bx bx-menu" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => recheckProject(project)}>Recheck</DropdownItem>
                                                                                <DropdownItem onClick={() => handleOpenEditModal(project)}>Edit</DropdownItem>
                                                                                <DropdownItem onClick={() => deleteProject(project)}>Delete</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </td>

                                                                </tr>
                                                            ))
                                                        ) : null)
                                                    }
                                                </tbody>
                                            </Table> */}
                                                    <BootstrapTable
                                                        bootstrap4
                                                        keyField="id"
                                                        data={projectList}
                                                        columns={columns_old}
                                                        // filter={filterFactory}
                                                    />

                                                    <TableContainer
                                                        columns={columns}
                                                        data={projectList}
                                                        isGlobalFilter={true}
                                                        isAddOptions={false}
                                                        // handleJobClicks={handleJobClicks}
                                                        isJobListGlobalFilter={false}
                                                        customPageSize={10}
                                                    />
                                                </div>
                                            </CardBody>
                                        ) : (
                                            <CardBody>
                                                <center>
                                                    <h3 className="mt-4">Projects help you organise the pages you want to check.</h3>
                                                    <h3 className="mt-2">Create your first project.</h3>
                                                    <div className="mt-4">
                                                        <button className="btn" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/new-project')}>New Project</button>
                                                    </div>
                                                </center>
                                            </CardBody>
                                        )}

                                    </Card>
                                </Col>
                            </Row>
                        )
                    }

                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Edit Project Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={{
                                    project_name: project_data?.project_name ? project_data?.project_name : "",
                                    description: project_data?.description ? project_data?.description : "",
                                }}
                                // validationSchema={SignupSchema}
                                onSubmit={async values => {
                                    console.log(values)
                                    const projData = {
                                        id: project_data.id,
                                        project_name: values.project_name,
                                        description: values.description,
                                    }
                                    console.log(projData, "classData")
                                    await editProjectDetails(projData).then(r => {
                                        // tog_standard()
                                        setmodal_standard(false);
                                        getAllProjects()
                                        Swal.fire("Project Details Has Updated!", "", "success")
                                    }).catch(e => {
                                        console.log(e.response.data)
                                    })
                                }}
                            >{({ handleChange, handleBlur, values, errors, touched }) => (
                                <Form>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Project Name
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="project_name"
                                            name="project_name"
                                            placeholder="Enter Your Project Name"
                                            onChange={handleChange}
                                            value={values?.project_name}
                                            invalid={
                                                errors?.project_name && touched?.project_name ? true : false
                                            }
                                            onBlur={handleBlur}
                                            required
                                        />
                                        {errors?.project_name && touched?.project_name ? (
                                            <FormFeedback type="invalid">
                                                {errors?.name}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Description
                                        </Label>
                                        <textarea
                                            id="description"
                                            name="description"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Description..."
                                            onChange={handleChange}
                                            value={values?.description}
                                            invalid={
                                                errors?.description && touched?.description ? true : false
                                            }
                                            onBlur={handleBlur}
                                        ></textarea>
                                        {errors?.description && touched?.description ? (
                                            <FormFeedback type="invalid">
                                                {errors?.name}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-outline-info w-md"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </Modal>


                    {/* <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="mb-2">
                                        <button className="btn btn-primary" onClick={() => navigate('/new-project')}>Add New Project</button>
                                    </div>

                                    <div className="table-responsive">
                                        <Table className="table table-bordered border-primary mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Project Name</th>
                                                    <th>Check Status</th>
                                                    <th>Index Status</th>
                                                    <th>First Checked Date</th>
                                                    <th>Last Checked Date</th>
                                                    <th>URL Status</th>
                                                    <th>Schedule</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (projectListOld && projectListOld.length > 0 ? (
                                                        projectListOld.map((project, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{project.indexcheck__project_name}</td>
                                                                <td>{project.indexcheck__status}</td>
                                                                <td>{project.status !== '' ? project.status : '-'}</td>
                                                                <td>{new Date(project.first_checked_date).toLocaleDateString() !== '1/1/1970' ? new Date(project.first_checked_date).toLocaleDateString() : '-'}</td>
                                                                <td>{new Date(project.last_checked_date).toLocaleDateString() !== '1/1/1970' ? new Date(project.last_checked_date).toLocaleDateString() : '-'}</td>
                                                                <td>{project.url_status !== '' ? project.url_status : '-'}</td>
                                                                <td>{project.indexcheck__schedule}</td>
                                                            </tr>
                                                        ))
                                                    ) : null)
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectsListNew;
