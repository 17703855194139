import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getCreditPackages, subscriptionCheckoutSession, getSubscriptionDetails } from "../../services/main.service";


import './CardElementStyles.css';
import profileImg from "../../assets/images/crypto/features-img/img-1.png"
import Loading from "components/Common/Loading";
import toastr from "toastr";

const SubcriptionPlan = () => {

    //meta title
    document.title = "Credits Packages | Indexed Checker App";

    const searchParams = new URLSearchParams(window.location.search)

    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('authUser')));

    const navigate = useNavigate();

    const [creditPackages, setCrediPackages] = useState([]);
    const [subPackageDets, setSubPackageDets] = useState();
    const [currPackageDets, setCurrPackageDets] = useState();
    const [invoiceDets, setInvoiceDets] = useState([]);
    const [freeUser, setFreeUser] = useState(false);

    useEffect(() => {
        console.log(searchParams, 'searchParams');
        const status = searchParams.get("status");
        console.log(status, 'status');
        if (status === 'true') {
            toastr.success('Your Payment was Successful!')
        } else if (status === 'false') {
            toastr.error('Your Payment was Failed!')
        }
        getSubPackageDetails();
        getCreditPackages().then(res => {
            console.log(res, 'res');
            setCrediPackages(res?.data?.packages);
        }).catch((error) => {
            console.log({ error });
            setIsLoading(true);
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        })
    }, [])

    const getSubPackageDetails = () => {
        getSubscriptionDetails().then(res => {
            console.log(res, 'res');
            setSubPackageDets(res?.data?.subscription);
            setCurrPackageDets(res?.data?.package);
            setInvoiceDets(res?.data?.invoices);
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
            if (error.response.status === 404) {
                setFreeUser(true)
            }
            setIsLoading(false);
        })
    }

    const handleCheckoutSession = (package_data) => {
        console.log(package_data, 'package_data');
        subscriptionCheckoutSession(package_data).then(res => {
            console.log(res, 'res');
        });
    }
    const convertTimestampToDate = (timestamp) => {
        const dateObject = new Date(timestamp * 1000); // Convert seconds to milliseconds
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
        return formattedDate;
    }

    const convertTimestampToMonth = (timestamp) => {
        const dateObject = new Date(timestamp * 1000); // Convert seconds to milliseconds
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            month: 'long'
        });
        return formattedDate;
    }

    const timestampToDateNMonth = (timestamp) => {
        const dateObject = new Date(timestamp * 1000); // Convert seconds to milliseconds
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
        });
        return formattedDate;
    }

    return (
        <React.Fragment>
            {
                (isLoading ? (
                    <Loading />
                ) : null)
            }
            <div className="page-content">

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="Pricing" /> */}

                    {
                        freeUser ? (
                            <>
                                <Row className="justify-content-center">
                                    <Col lg={6}>
                                        <div className="text-center mb-5">
                                            <h4>Choose your Pricing plan</h4>
                                            <p className="text-muted">
                                                We offer free and paid premium access plans to support ethical access for all webmasters.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {creditPackages.map((package_dets, index) => (
                                        <Col xl="3" md="6" key={index}>
                                            <Card className="plan-box">
                                                <CardBody className="p-4 text-center">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5>{package_dets.package_name}</h5>
                                                            {/* <p className="text-muted">{package_dets.description}</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="py-4">
                                                        <h2>
                                                            <sup>
                                                                <small>$</small>
                                                            </sup>{" "}
                                                            {package_dets.price}.00
                                                        </h2>
                                                    </div>
                                                    <div className="py-1">
                                                        <h5>{package_dets.credits}{" "}Credits</h5>
                                                    </div>
                                                    <div className="py-1">
                                                        <h6>${package_dets.cost_per_credit}{" "}per check</h6>
                                                    </div>
                                                    <div className="text-center plan-btn">
                                                        <form action={`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/subscription/create-checkout-session`} method="POST">
                                                            <input type="hidden" name="product_name" value={package_dets.package_name} />
                                                            <input type="hidden" name="price" value={(package_dets.price * 100)} />
                                                            <input type="hidden" name="user_token" value={userData?.access} />
                                                            <input type="hidden" name="package_id" value={package_dets.id} />
                                                            <input type="hidden" name="stripe_price_id" value={package_dets.stripe_price_id} />
                                                            <Button className="btn btn-primary btn-md waves-effect waves-light" type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>Subscribe</Button>
                                                        </form>
                                                        {/* <Button className="btn btn-primary btn-md waves-effect waves-light" onClick={()=>handleCheckoutSession(package_dets)} style={{ "backgroundColor": "#00264c", "color": "#fff" }}>Buy Now</Button> */}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}

                                </Row>
                            </>
                        ) : (
                            <Row>
                                <Col lg={12}>
                                    <Card className="bg-primary bg-soft">
                                        <div>
                                            <Row>
                                                <Col xs="7">
                                                    <div className="text-primary p-3">
                                                        <h2>My Subscription</h2>
                                                        <h5 className="text-primary">You're subcribed to a plan. Thanks for the support !</h5>
                                                        <p>Subcription Details</p>

                                                        <ul className="ps-3 mb-2">
                                                            <Row className="mb-2">
                                                                <Col lg={3}><p className="text-muted">Current Subscription:</p></Col>
                                                                <Col lg={4}>
                                                                    <h4 className="mb-0">{currPackageDets?.name}</h4>
                                                                    <span className="text-secondary font-size-11">
                                                                        Since {convertTimestampToDate(subPackageDets?.created)}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-2">
                                                                <Col lg={3}><p className="text-muted mb-1">Duration:</p></Col>
                                                                <Col lg={4}>
                                                                    <h6>{timestampToDateNMonth(subPackageDets?.current_period_start)} to {timestampToDateNMonth(subPackageDets?.current_period_end)}</h6>
                                                                </Col>
                                                            </Row>
                                                            {(subPackageDets?.cancel_at_period_end ? <>
                                                                <Row className="mb-2">
                                                                <Col lg={3}><p className="text-muted mb-1">Cancel On:</p></Col>
                                                                <Col lg={4}>
                                                                    <h6 className="mb-0">{convertTimestampToDate(subPackageDets?.cancel_at)}</h6>

                                                                </Col>
                                                            </Row></> : <>
                                                                <Row className="mb-2">
                                                                    <Col lg={3}><p className="text-muted mb-1">Next Invoice:</p></Col>
                                                                    <Col lg={4}>
                                                                        <h5 className="mb-0">${(subPackageDets?.plan.amount) / 100}.00 {" "}<span className="text-secondary font-size-11">
                                                                            {convertTimestampToDate(subPackageDets?.current_period_end)}
                                                                        </span></h5>

                                                                    </Col>
                                                                </Row></>)}

                                                            <Row>
                                                                <Col lg={3}><p className="text-muted mb-1">Renew Period:</p></Col>
                                                                <Col lg={4}>
                                                                    <h6>Monthly</h6>
                                                                </Col>
                                                            </Row>
                                                        </ul>
                                                        <p className="mb-1">Payments</p>
                                                        <div className="table-responsive text-center mb-1">
                                                            <Table className="table mb-2">
                                                                <thead className="bg-secondary bg-soft">
                                                                    <tr>
                                                                        <th>Month</th>
                                                                        <th>Amount</th>
                                                                        <th>Date</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {invoiceDets.map((invoice, index) => (
                                                                        <tr>
                                                                            <th scope="row" key={index}>{convertTimestampToMonth(invoice?.created)}</th>
                                                                            <td>${(invoice?.amount_paid) / 100}.00</td>
                                                                            <td>{convertTimestampToDate(invoice?.created)}</td>
                                                                            <td><a href={invoice?.hosted_invoice_url} target="_blank" className="btn btn-secondary">Invoice</a></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <form method="POST" action={`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create-customer-portal-session`}>
                                                            <input type="hidden" name="customer_id" value={subPackageDets?.customer} />
                                                            <button type="submit" className="btn btn-primary btn-md waves-effect waves-light" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>Manage billing</button>
                                                        </form>
                                                    </div>
                                                </Col>
                                                <Col xs="5" className="align-self-center">
                                                    <img src={profileImg} alt="" className="img-fluid" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        )}

                </Container>
            </div>
        </React.Fragment>
    );
};

export default SubcriptionPlan;
