import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useNavigate} from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { savePrompt, saveProjectData, saveProject, updatePrompt, updateProject, genContOnlyPrompt, saveContentOnly, publishOnWordpress, getHasToken, saveWpConfigProject } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

const MultiContProject = () => {

  //meta title
    document.title = "Create Single Project";

    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [selectedFiles, setselectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [mainPrompt, setMainPrompt] = useState();
    const [keywordsArr, setKeywordsArr] = useState([]);
    let [proPrompt, setProPrompt] = useState("");
    const [finalContent, setFinalContent] = useState("");

    const [loadingStatus, setLoadingStatus] = useState(null);
    const [contentData, setContentData] = useState(null);
    const [wpUrl, setWpUrl] = useState(null);
    const [saveStatus, setSaveStatus] = useState({status: 0, project_id: null, prompt_id: null});

    const [wordpress, setWordpress] = useState({user_name: "admin2", password: "6DT@w&6Mx%pNeVr&d@rv@OJk", wp_url: "https://testwp.kongcepts.info/wp", content: "", title: "General", proj_id: null});

    const [hasTokenSaved, setHasTokenSaved] = useState(false);
    const [isUseApiKey, setIsUseApiKey] = useState(false);

    const [wpConfigData, setWpConfigData] = useState({user_name: "", password: "", wp_url: "", proj_id: null});
    const [multiCont, setMultiCont] = useState([]);

    const [nextPrompt, setNextPrompt] = useState();
    const [isFinal, setIsFinal] = useState(false);

    const [projectData, setProjectData] = useState({
        project_name: "",
        prompt: "",
        website: "",
        main_keyword: "",
        second_keyword: "",
        wordpress_email: "",
        org_prompt: "",
        code_model: "gpt-3.5-turbo-0301",
        proj_id: null,
        openaikey: "",
        isuseuserkey: false
    });

  const startDateChange = date => {
    setstartDate(date);
  };

  const endDateChange = date => {
    setendDate(date);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

    const createPrompt = (e) => {
        let prompt = e?.target?.value ?? "";
        let regExp = /\[@(?<content>[^\[\]]+)\]/g;;
        if (prompt) {
            let checkKeyWords = Array.from(
                prompt.matchAll(regExp)
            ).map(
                ({ groups: { content } }) => content
            );

            let checkKeyWordsMaps = checkKeyWords.map((keyw, ind) => ({index: ind, key: keyw, value: ''}));
            setKeywordsArr(checkKeyWordsMaps);
            setMainPrompt(prompt);
            setProPrompt(prompt);
            setProjectData({...projectData, org_prompt: prompt});
        }
    }

    const changeParams = (e, param) => {
        let vals = e?.target?.value ?? "";
        if (vals !== "") {
            let keywordsArrAlter = keywordsArr.map((key) => {
                if (key.index === param.index) {
                    return {...key, value: vals}
                }
                return key
            });
            setKeywordsArr(keywordsArrAlter);
        }

    }

    useEffect(() => {
        if (keywordsArr && keywordsArr.length > 0) {
            let proPromptData = mainPrompt;
            let mainKeyWords = projectData.main_keyword;
            let secondKeyWords = projectData.second_keyword;
            keywordsArr.forEach((keyData) => {
                if (keyData?.key.toLowerCase().includes('mainkey') || keyData?.key.toLowerCase().includes('mainkeyword')  || keyData?.key.toLowerCase().includes('mainkeywords')) {
                    mainKeyWords = keyData?.value;
                }
                if (keyData?.key.toLowerCase().includes('secondkey') || keyData?.key.toLowerCase().includes('secondkeyword')  || keyData?.key.toLowerCase().includes('secondkeywords')) {
                    secondKeyWords = keyData?.value;
                }
                proPromptData = proPromptData.replace(`[@${keyData.key}]`, keyData.value)
            });
            //proPrompt.replace(`[@${param.key}]`, e.target.value)
            setProPrompt(proPromptData);
            setProjectData({...projectData, main_keyword: mainKeyWords, second_keyword: secondKeyWords});
        }
    }, [keywordsArr, mainPrompt])


    useEffect(() => {
        if (proPrompt) {
            setProjectData({...projectData, prompt: proPrompt});
        }
    }, [proPrompt]);

    useEffect(() => {
        console.log({wpConfigData});
    }, [wpConfigData]);

    const encryptConversion = (value) => {
        let pubKey = localStorage.getItem("pubkey");
        if (pubKey) {
            let publicKeyNew = forge.pki.publicKeyFromPem(pubKey)
            let encrypted = publicKeyNew.encrypt(value, "RSA-OAEP", {
                md: forge.md.sha256.create(),
                mgf1: forge.mgf1.create()
            });
            return forge.util.encode64(encrypted);
        } else {
            return null;
        }
    }

    const encryptWpConfig = (projId) => {
        if (wpConfigData?.user_name != "" && wpConfigData?.wp_url != "" && wpConfigData?.password != "") {
            let userName = encryptConversion(wpConfigData?.user_name);
            let wpUrl = encryptConversion(wpConfigData?.wp_url);
            let password = encryptConversion(wpConfigData?.password);

            saveWpConfigProject({user_name: userName, wp_url: wpUrl, password: password, proj_id: projId}).then((resConfig) => {
                console.log({resConfig});
            }).catch((error) => {
                console.log({error});
            }).finally(() => {
            });
            return true;
        } else {
            return false;
        }
    }

    const saveProjectData = (e) => {
        console.log({projectData});
        if(projectData) {
            setLoadingStatus("Saving the project");
            if (!saveStatus?.project_id && !saveStatus?.prompt_id) {
                setIsLoading(true);
                savePrompt({prompt: projectData?.org_prompt, prompt_name: projectData?.project_name ?? `Default Prompt Name`, is_public: false}).then((promptRes) => {
                    console.log({promptRes});
                    if (promptRes?.data?.message) {
                        let prompt_id_new = promptRes?.data?.prompt_id;
                        saveProject({...projectData, prompt_id: prompt_id_new }).then((resProject) => {
                            console.log('Coming hereee:::', {resProject});
                            if (resProject?.data?.project_id) {
                                setSaveStatus({status: 1, project_id: resProject?.data?.project_id, prompt_id: prompt_id_new});
                                setProjectData({...projectData, proj_id: resProject?.data?.project_id});
                                generatePromptData();
                                setWpConfigData({...wpConfigData, proj_id: resProject?.data?.project_id});

                                setWordpress({...wordpress, proj_id: resProject?.data?.project_id});

                                let wpConfigDetails = {...wpConfigData, proj_id: resProject?.data?.project_id};
                                //saveWpConfigProject
                                let updatedStatus = encryptWpConfig(resProject?.data?.project_id);
                            }
                            
                        }).catch((error) => {
                            console.log({error});
                            //localStorage.removeItem('user');
                            //navigate('/login');
                        });
                    }
                }).catch((error) => {
                    localStorage.removeItem('user');
                    navigate('/login');
                });
            } else {
                setIsLoading(true);
                updatePrompt(saveStatus?.prompt_id, {prompt: projectData?.org_prompt, prompt_name: projectData?.project_name ?? `Default Prompt Name`}).then((promptRes) => {
                    console.log({promptRes});
                    if (promptRes?.data?.message) {
                        let prompt_id_new = promptRes?.data?.prompt_id;
                        updateProject({...projectData, prompt_id: prompt_id_new }).then((resProject) => {
                            console.log('Coming hereee:::', {resProject});
                            if (resProject?.data?.project_id) {
                                setSaveStatus({status: 1, project_id: resProject?.data?.project_id, prompt_id: prompt_id_new});
                                generatePromptData();
                            }
                            
                        }).catch((error) => {
                            console.log({error});
                            //localStorage.removeItem('user');
                            //navigate('/login');
                        });
                    }
                }).catch((error) => {
                    localStorage.removeItem('user');
                    navigate('/login');
                });
            }
            
        }
    }

    const generatePromptData = () => {
        setIsLoading(true);

        let publicKey = localStorage.getItem('pubkey');
        let openKey = projectData?.openaikey
        let publicKeyNew = forge.pki.publicKeyFromPem(publicKey)
        let encrypted = publicKeyNew.encrypt(openKey, "RSA-OAEP", {
            md: forge.md.sha256.create(),
            mgf1: forge.mgf1.create()
        });
        let base64 = forge.util.encode64(encrypted);

        genContOnlyPrompt(projectData?.prompt, projectData?.code_model, base64, projectData?.isuseuserkey).then((genContData) => {
            console.log({genContData});
            if (genContData?.data?.content) {
                let contentData = genContData?.data?.content.replace(/\n/g, "");
                console.log({contentData});
                setContentData(contentData);
                setFinalContent(contentData);
                setNextPrompt(contentData);
                setMultiCont([...multiCont, contentData]);
            }
        }).catch((error) => {
            console.log("Error Occured", error);
            localStorage.removeItem('user');
            navigate('/login');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    let generateContWithPrompt = (prompt, isFinal = false) => {
        setIsLoading(true);
        let publicKey = localStorage.getItem('pubkey');
        let openKey = projectData?.openaikey
        let publicKeyNew = forge.pki.publicKeyFromPem(publicKey)
        let encrypted = publicKeyNew.encrypt(openKey, "RSA-OAEP", {
            md: forge.md.sha256.create(),
            mgf1: forge.mgf1.create()
        });
        let base64 = forge.util.encode64(encrypted);
        genContOnlyPrompt(prompt, projectData?.code_model, base64, projectData?.isuseuserkey).then((genContData) => {
            console.log({genContData});
            if (genContData?.data?.content) {
                let contentData = genContData?.data?.content.replace(/\n/g, "");
                console.log({contentData});
                setContentData(contentData);
                setFinalContent(contentData);
                setNextPrompt(contentData);
                setMultiCont([...multiCont, contentData]);
                setIsFinal(isFinal);

                if (isFinal === true) {
                    setFinalContent(contentData);
                }
            }
        }).catch((error) => {
            console.log("Error Occured", error);
            localStorage.removeItem('user');
            navigate('/login');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const saveContentAndPublish = (isPublish = false, contentData = null) => {
        //saveContentOnly
        if (finalContent) {
            let genCont = contentData ?? finalContent;
            setIsLoading(true);
            saveContentOnly(projectData?.proj_id, {content: genCont, cont_title: projectData?.project_name}).then((saveCont) => {
                console.log({saveCont});
                if (isPublish) {
                    setWordpress({...wordpress, content: genCont});
                    postToWordPress();
                } else {
                    navigate('/contentgen_dashboard');
                }
            }).catch((error) => {
                console.log({error});
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        getHasToken().then((res) => {
            console.log('res?.data?.hastoken::', res?.data?.hastoken);
            setHasTokenSaved(res?.data?.hastoken ?? false);
            setIsUseApiKey(res?.data?.hastoken ?? false);
            setProjectData({...projectData, isuseuserkey: res?.data?.hastoken ?? false});
        }).catch((err) => {
            console.log({err});
        }).finally(() => {});
      }, []);

    const postToWordPress = () => {
        console.log({wordpress});
        if (wordpress.wp_url) {
            setLoadingStatus("Publishing to Wordpress");
            setIsLoading(true);
            publishOnWordpress(wordpress).then((wordpressresponse) => {
                if (wordpressresponse?.data?.statuscode) {
                    setLoadingStatus("Published");
                    setWpUrl(wordpress?.wp_url);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    let changeUseApiKey = (e) => {
        setIsUseApiKey(e?.target?.checked);
        setProjectData({...projectData, isuseuserkey: e?.target?.checked ?? false});
    }

    const generatePromptAgain = () => {
        if (nextPrompt) {
            generateContWithPrompt(nextPrompt);
        }
    }

    const finishRefining = () => {
        //setIsFinal
        console.log({nextPrompt});
        if (nextPrompt) {
            generateContWithPrompt(nextPrompt, true);
        }
    }

    const saveAllFinal = () => {
        saveContentAndPublish();
    }

  return (
    <React.Fragment>
      <div className="page-content">
        {
            isLoading ? (
                <Loading/>
            ) : null
        }

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Single Project</CardTitle>
                  <Form
                    className={contentData ? "freez-conts" : ""}
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveProjectData(e);
                    }}
                  >                   
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Project Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="projectname"
                              name="project_name"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Enter Project Name..."
                              onChange={(e) => {setProjectData({...projectData, project_name: e?.target?.value})}}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                                Model
                            </Label>
                            <Col lg="6">
                                <select className="form-control" 
                                    value={projectData?.code_model} onChange={(e) => {setProjectData({...projectData, code_model: e?.target?.value})}}
                                >
                                    <option value="code-davinci-002">code-davinci-002 (Codex)</option>
                                    <option value="text-ada-001">text-ada-001 (GPT3)</option>
                                    <option value="text-babbage-001">text-babbage-001 (GPT3)</option>
                                    <option value="text-davinci-003">text-davinci-003 (GPT3.5)</option>
                                    <option value="text-davinci-002">text-davinci-002 (GPT3.5)</option>
                                    <option value="gpt-3.5-turbo-0301">gpt-3.5-turbo-0301 (GPT3.5)</option>
                                </select>
                            </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="website"
                            className="col-form-label col-lg-2"
                          >
                            OpenAI Key
                          </Label>
                          <Col lg="10">
                            <Input
                              id="openaikey"
                              name="openaikey"
                              type="password"
                              className="form-control"
                              value={projectData?.openaikey}
                              disabled={isUseApiKey}
                              onChange={(e) => {
                                if (e?.target?.value != "") {
                                    setProjectData({...projectData, openaikey: e?.target?.value});
                                }
                              }}
                              placeholder="Enter OpenAI Key"
                            />
                            {
                                hasTokenSaved ? (
                                    <label>
                                        <Input name="is-apikey-check" type="checkbox" value={isUseApiKey} onChange={changeUseApiKey}/>
                                        <span style={{marginLeft: "5px"}}>You have a saved secret key. Use Saved API Key</span>
                                    </label>
                                ) : null
                            }
                          </Col>
                        </FormGroup> 
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="website"
                            className="col-form-label col-lg-2"
                          >
                            Website
                          </Label>
                          <Col lg="10">
                            <Input
                              id="website"
                              name="website"
                              type="text"
                              className="form-control"
                              placeholder="Enter Website Name(Optional)"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="wp_username"
                            className="col-form-label col-lg-2"
                          >
                            Wordpress User Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="wp_username"
                              name="wp_username"
                              type="text"
                              className="form-control"
                              placeholder="Enter Wordpress User Name"
                              onChange={(e) => {setWpConfigData({...wpConfigData, user_name: e?.target?.value})}}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="wp_password"
                            className="col-form-label col-lg-2"
                          >
                            Wordpress User Password
                          </Label>
                          <Col lg="10">
                            <Input
                              id="wp_password"
                              name="wp_password"
                              type="password"
                              className="form-control"
                              placeholder="Enter Wordpress Password"
                              onChange={(e) => {setWpConfigData({...wpConfigData, password: e?.target?.value})}}
                            />
                          </Col>
                        </FormGroup> 
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="wp_url"
                            className="col-form-label col-lg-2"
                          >
                            Wordpress Url
                          </Label>
                          <Col lg="10">
                            <Input
                              id="wp_url"
                              name="wp_url"
                              type="text"
                              className="form-control"
                              placeholder="Enter Wordpress Url"
                              onChange={(e) => {setWpConfigData({...wpConfigData, wp_url: e?.target?.value})}}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Prompt
                          </Label>
                          <Col lg="10">
                            <textarea
                              id="prompt"
                              name="prompt"
                              required
                              type="text"
                              className="form-control"
                              placeholder="Enter Prompt"
                              onChange={createPrompt}
                            ></textarea>
                          </Col>
                          <Label
                            htmlFor="project_name"
                            className="col-form-label col-lg-2"
                          >
                            Prompt Details
                          </Label>
                          <Col lg="4">
                            <div>
                                {
                                    keywordsArr.length > 0 ? (
                                        keywordsArr.map((param, ind) => {
                                            return (
                                                <div key={`param-${ind}`}>
                                                    <label>
                                                        <span>{ind + 1}. {param?.key}</span>
                                                        <input onChange={(e) => {changeParams(e, param)}} type="text" className="form-control"/>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    ) : (<p>No Parameters</p>)
                                }
                            </div>
                            {
                                projectData && (projectData.main_keyword !== "" || projectData.second_keyword !== "") ? (
                                    <>
                                        <p>Main Keywords: <span>{projectData.main_keyword}</span></p>
                                        <p>Second Keywords: <span>{projectData.second_keyword}</span></p>
                                    </>
                                ) : (null)
                            }
                          </Col>
                          <Col lg="6">
                            <div className="form-inner-area">
                                <p>Prompt for this Project</p>
                                    <textarea className="form-control" name="prompt" defaultValue={proPrompt}></textarea>
                                </div>
                          </Col>
                        </FormGroup>
                        <Row className="justify-content-end mb-4">
                            <Col lg="10">
                            <Button type="submit" color="primary">
                                Create Project and Generate Content
                            </Button>
                            </Col>
                        </Row>                
                  </Form>
                  {
                    contentData ? (
                        <>
                            <Row>
                                <Col lg="12" className="mb-4">
                                    {
                                        (multiCont.length > 0 ? (
                                            multiCont.map((cont, index) => {
                                                if (index == multiCont.length - 1) {
                                                    return (
                                                        <div key={`tx-${index}`}>
                                                            <textarea onChange={(e) => {setNextPrompt(e?.target?.value);}} className="form-control"  value={nextPrompt}></textarea>
                                                            <br/>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div key={`tx-${index}`}>
                                                            <textarea onChange={(e) => {console.log(e?.target?.value);}} className="form-control" disabled={true} value={cont}></textarea>
                                                            <br/>
                                                        </div>
                                                    )
                                                }
                                            })
                                        ) : null)
                                    }
                                </Col>
                                {
                                    isFinal ? (
                                        <Col lg="12" className="mb-4">
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data={ contentData ?? "" }
                                                onReady={ editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log( 'Editor is ready to use!', editor.getData() );
                                                    let data = editor.getData();
                                                    setFinalContent(data);
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    let data = editor.getData();
                                                    setFinalContent(data);
                                                    console.log( { event, editor, data } );
                                                } }
                                                onBlur={ ( event, editor ) => {
                                                    console.log( 'Blur.', editor );
                                                } }
                                                onFocus={ ( event, editor ) => {
                                                    console.log( 'Focus.', editor );
                                                } }
                                            />
                                        </Col>
                                    ) : null
                                }
                                
                            </Row>
                            <Row>
                                <Col lg="12">
                                    {
                                        isFinal ? (
                                            <>
                                                <Form>
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <button className="btn btn-primary" type="button" onClick={saveAllFinal}>
                                                                Save Content
                                                            </button>
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </>
                                        ) : (
                                            <>
                                                <Form>
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <button className="btn btn-primary" type="button" onClick={generatePromptAgain}>
                                                                Refine And Generate Again
                                                            </button>
                                                            <button className="btn btn-warning" style={{"marginLeft": "10px"}} onClick={finishRefining} type="button">
                                                                Finish Refining
                                                            </button>
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </>
                                        )
                                    }
                                    
                                </Col>
                            </Row>
                        </>
                    ) : null
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MultiContProject;
