import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { saveWpConfigProject, getProjectsByUser, buyCreditPackage, requestPayment } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import './CardElementStyles.css';
import Swal from "sweetalert2";

const BuyCredits = () => {

    //meta title
    document.title = "Buy Credits";

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();


    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }
    const [amount, setAmount] = useState(0);
    const [credits, setCredits] = useState(0);

    const handleOpenPaymentModal = (amount, credits) => {
        setmodal_standard(true)
        setCredits(credits)
        setAmount(amount)
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            const { token } = await stripe.createToken(cardElement);
            // Pass the token to your backend
            console.log(token, 'token');
            const p_data = {
                token: token.id,
                amount: amount * 100,
                credits: credits
            }
            requestPayment(p_data).then((res) => {
                console.log(res);
                if (res?.data?.response?.status == "succeeded") {
                    setmodal_standard(false);
                    setIsLoading(false);
                    Swal.fire("Added "+ credits +" Credits!", "", "success")
                    window.location.reload()
                }
            }).catch((error) => {
                console.log({ error });
            }).finally(() => {
                setIsLoading(false);
            });
            // handleToken(token);
        } catch (error) {
            setError(error.message);
        }

        setLoading(false);
    };

    return (
        <React.Fragment>
            {
                (isLoading ? (
                    <Loading />
                ) : null)
            }
            <div className="page-content">

                <Modal
                    isOpen={modal_standard}
                    toggle={() => {
                        tog_standard();
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Card Details
                        </h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">

                            <div>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                        hidePostalCode: true,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit" disabled={!stripe || loading}
                                className="btn btn-primary "
                            >
                                Purchase
                            </button>
                            {error && <div>{error}</div>}
                        </div>
                    </form>
                </Modal>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Buy Credits" />

                    <Row>
                        <Card style={{ backgroundColor: "#ebebf4" }}>
                            <CardBody>
                                <Row style={{ textAlign: "center" }}>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Starter</CardTitle>
                                                <h1>$10.00</h1>
                                                <h3>1000 Credits</h3>
                                                <Button onClick={() => handleOpenPaymentModal(10, 1000)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button>
                                                {/* <form action={`http://localhost:8000/api/create-checkout-session/`} method="POST">
                                                    <input type="hidden" name="product_name" value="Starter" />
                                                    <input type="hidden" name="price" value={10 * 100} />
                                                    <Button className="btn-checkout" type="submit" style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>Buy Now</Button>
                                                </form> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Pro</CardTitle>
                                                <h1>$20.00</h1>
                                                <h3>2500 Credits</h3>
                                                <Button onClick={() => handleOpenPaymentModal(20, 2500)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Elite</CardTitle>
                                                <h1>$30.00</h1>
                                                <h3>5000 Credits</h3>
                                                <Button onClick={() => handleOpenPaymentModal(30, 5000)} style={{ "backgroundColor": "#3FC072", "color": "#fff" }}>
                                                    Buy Now
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BuyCredits;
