import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Badge, Breadcrumb, Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row, Table, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, recheckProjectRequest, deleteIndexProject, editProjectDetails, toggleFavourite, getAllDomainProjectsByUser, getProjectDetails, recheckMultipleURLs } from "../../services/main.service";

import Loading from "../../components/Common/Loading";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

import toastr from "toastr";

const DomainMonitorList = () => {

    //meta title
    document.title = "Domain Monitoring Projects | Indexed Checker App";

    const [isLoading, setIsLoading] = useState(true);
    const [btnRefresh, setBtnRefresh] = useState(false);
    const [projectListOld, setProjectListOld] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [projectAllList, setProjectAllList] = useState([]);
    const [favouriteList, setFavouriteList] = useState([]);
    const [urlList, setURLList] = useState([]);

    const navigate = useNavigate();

    const [singlebtn, setSinglebtn] = useState(false)


    useEffect(() => {

        getAllProjects();

    }, [])

    const getAllProjectsOld = () => {
        getProjectsByUser().then((projects) => {
            if (projects?.data?.projects) {
                // console.log(projects?.data?.projects, "projects");
                setProjectListOld(projects?.data?.projects)
            }
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const getAllProjects = () => {
        getAllDomainProjectsByUser().then((projects) => {
            if (projects?.data?.projects) {
                console.log(projects?.data?.projects, "projects_new");
                setProjectAllList(projects?.data?.projects);
                setProjectList(projects?.data?.projects);
                setFavouriteList(projects?.data?.projects.filter(project => project.favourite == 1));
                setIsLoading(false);
                setBtnRefresh(false);
            }
        }).catch((error) => {
            console.log({ error });
            setIsLoading(true);
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleRedirectProjectDetails(project_data) {
        navigate('/project-details/' + project_data.id)
        console.log(project_data, 'class_dets');
    }

    const [dropdownStates, setDropdownStates] = useState(Array(projectList.length).fill(false));

    const toggleDropdown = (index) => {
        console.log(index, 'index');
        const updatedDropdownStates = [...dropdownStates];
        updatedDropdownStates[index] = !updatedDropdownStates[index];
        setDropdownStates(updatedDropdownStates);
    };

    const recheckProject = (project) => {
        console.log(project, 'project');
        recheckProjectRequest(project).then((res) => {
            console.log(res, "response");
            getAllProjects();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckNIURLs = (project) => {
        getProjectDetails(project.id).then((project) => {
            console.log(project?.data, "project_dets");
            setURLList(project?.data?.results)
            const NIurlList = project?.data?.results.filter(url => url.status === "Not Indexed");
            console.log(NIurlList, 'NIurlList');
            if (NIurlList.length > 0) {
                recheckMultipleURLs(NIurlList).then((res) => {
                    console.log(res, "response");
                    getAllProjects();
                    toastr.success("Rechecking Not Indexed URLs!")
                }).catch((error) => {
                    console.log({ error });
                    if (error.response.status === 401) {
                        localStorage.removeItem('user');
                        navigate('/login');
                    }
                });
            } else {
                toastr.error("Not Indexed URLs none!")
            }

        }).catch((error) => {
            console.log({ error });
        });
    }

    const deleteProject = (project) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteIndexProject(project.id).then((res) => {
                    console.log(res, "response");

                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    getAllProjects();
                }).catch((error) => {
                    console.log({ error });
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    const [modal_standard, setmodal_standard] = useState(false);
    const [project_data, setProjectData] = useState();

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const handleOpenEditModal = (project) => {
        setmodal_standard(true);
        setProjectData(project)
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    const selectStatusOptions = {
        'Checked': 'Checked',
        'Pending': 'Pending',
    };

    const selectScheduleOptions = {
        'No': 'No',
        'Daily': 'Daily',
        'Weekly': 'Weekly',
        'Monthly': 'Monthly',
    };

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
        sortOrder === 'asc' ? 'demo-sorting-asc' : 'demo-sorting-desc'
    );

    const handleFavourite = (dets) => {
        toggleFavourite(dets.id).then((res) => {
            console.log(res, "response");


            Swal.fire(res.data.status, "", "success")
            getAllProjects();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });

    }

    const columns = [
        // {
        //     dataField: 'id',
        //     text: 'Project ID',
        // },
        {
            dataField: 'favourite',
            text: '',
            formatter: (cell, row) => {
                return (<>
                    <Button className={`btn btn-sm btn-soft-${cell === 0 ? 'info' : 'danger'}`}
                        onClick={() => {
                            handleFavourite(row);
                        }}>

                        {(cell === 0 ? (
                            <i className='bx bx-grid-horizontal' id="hearttooltip"></i>
                        ) : (
                            <i className='bx bxs-taxi' id="hearttooltip"></i>
                        ))}
                    </Button>
                    <UncontrolledTooltip placement="top" target="hearttooltip">
                        Favourite
                    </UncontrolledTooltip>
                </>);
            },
        },
        {
            dataField: 'project_name',
            text: 'Project Name',
            // filter: textFilter(),
            sort: true,
            headerSortingClasses,
            formatter: (cell, row) => {
                return (
                    <>
                        {/* {(row.status == 'Checked' ? (
                            <Link to={'/project-details/' + row.id}>{cell}</Link>
                        ) : (
                            <p>{cell}</p>
                        ))} */}
                        <Link to={'/domain-monitor-projects-details/' + row.id}>{cell}</Link>
                    </>
                );
            },
        }, {
            dataField: 'pages',
            text: 'Pages',
            sort: true,
            headerSortingClasses
        }, {
            dataField: 'page_indexed',
            text: 'Page Indexed',
            formatter: (cell, row) => `${cell} (${((cell / row.pages) * 100).toFixed(0)}%)`
        }, {
            dataField: 'page_not_indexed',
            text: 'Page Not Indexed',
            formatter: (cell, row) => `${cell} (${((cell / row.pages) * 100).toFixed(0)}%)`
        }, {
            dataField: 'created_date',
            text: 'Creation Date',
            formatter: (cell) => new Date(cell).toLocaleString()
        }, {
            dataField: 'last_checked_date',
            text: 'Last Checked Date',
            formatter: (cell) => {
                const formattedDate = new Date(cell).toLocaleDateString();
                return formattedDate !== '1/1/1970' ? new Date(cell).toLocaleString() : '-';
            }
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: cell => {
                return (
                    <Badge className={`bg-${cell === "Checked" ? 'success' : 'secondary'}`}>{cell}</Badge>
                    // <span className={`badge rounded-pill badge-soft-${cell === "Checked" ? 'success' : 'secondary'} font-size-11`}>
                    //     {cell}
                    // </span>
                );
            },
            // filter: selectFilter({
            //     options: selectStatusOptions
            // })
        }, {
            dataField: 'schedule',
            text: 'Recheck',
            // filter: selectFilter({
            //     options: selectScheduleOptions
            // })
        }, {
            dataField: 'view',
            text: 'Action',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (

                    <ul className="list-unstyled hstack gap-1 mb-0">
                        {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">

                            <Button className="btn btn-sm btn-soft-primary" disabled={row.status !== "Checked"}
                                onClick={() => {
                                    handleRedirectProjectDetails(row);
                                }}>
                                <i className="bx bx-table" id="viewtooltip"></i>
                            </Button>
                        </li>
                        <UncontrolledTooltip placement="top" target="viewtooltip">
                            View
                        </UncontrolledTooltip> */}

                        <li data-bs-toggle="tooltip" data-bs-placement="top">
                            <Button className="btn btn-md btn-soft-success"
                                onClick={() => {
                                    recheckProject(row);
                                }}>
                                <i className="bx bx-slider-alt" id="rechecktooltip"></i></Button>
                            <UncontrolledTooltip placement="top" target="rechecktooltip">
                                Recheck
                            </UncontrolledTooltip>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-warning"
                                onClick={() => {
                                    recheckNIURLs(row);
                                }}
                                disabled={row.page_not_indexed == 0}
                            >
                                <i className="bx bxs-calendar-alt" id="rechecknitool" />
                                <UncontrolledTooltip placement="top" target="rechecknitool">
                                    Recheck Not Indexed
                                </UncontrolledTooltip>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-info"
                                onClick={() => {
                                    handleOpenEditModal(row);
                                }}
                            >
                                <i className="bx bx-reply" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-danger"
                                onClick={() => {
                                    deleteProject(row);
                                }}
                            >
                                <i className="bx bx-wrench" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                    </ul>

                    // <Dropdown
                    //     isOpen={dropdownStates[rowIndex]}
                    //     toggle={() => toggleDropdown(rowIndex)}
                    // >
                    //     <DropdownToggle className="btn btn-secondary" caret>
                    //         <i className="bx bx-menu" />
                    //     </DropdownToggle>
                    //     <DropdownMenu>
                    //         <DropdownItem onClick={() => recheckProject(row)}>Recheck</DropdownItem>
                    //         <DropdownItem onClick={() => handleOpenEditModal(row)}>Edit</DropdownItem>
                    //         <DropdownItem onClick={() => deleteProject(row)}>Delete</DropdownItem>
                    //     </DropdownMenu>
                    // </Dropdown>
                )
            }



        }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const [selectFavourite, setSelectFavourite] = useState(false);

    const toggleFavouriteList = () => {
        setSelectFavourite(!selectFavourite);
        console.log(selectFavourite, 'selectFavourite');
        if (!selectFavourite) {
            // setProjectList(projectList.filter(project => project.favourite == 1));
            setProjectList(favouriteList);
        } else {
            setProjectList(projectAllList);
        }
    }

    const searchProcess = (e) => {
        const searchTerm = e.target.value;
        console.log(searchTerm, 'value');
        setProjectList(projectAllList.filter(project => project.project_name.includes(searchTerm)));
    }

    const handleBtnRefresh = () => {
        setBtnRefresh(true);
        getAllProjects();
    }


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Projects" breadcrumbItem="Domain Monitoring" /> */}
                    
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Domain Monitoring</h4>
                        <div className="page-title-right">
                            {
                                btnRefresh ? (
                                    <Button color="primary" outline>
                                        <i className="bx bx-slider-alt bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        Refresh
                                    </Button>
                                ) : (
                                    <Button color="primary" outline  onClick={() => handleBtnRefresh()}>
                                        Refresh
                                    </Button>
                                )
                            }
                        </div>
                    </div>

                    {
                        isLoading ? (
                            <Row>
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            Loading...
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        {(projectAllList && projectAllList.length > 0) ? (
                                            <>
                                                <CardBody className="border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <h5 className="mb-0 card-title flex-grow-1">Domain Lists</h5>
                                                        <div className="flex-shrink-0 me-1">
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="search"
                                                                name="search"
                                                                placeholder="Search..."
                                                                onChange={(e) => searchProcess(e)}
                                                            />
                                                        </div>
                                                        <div className="flex-shrink-0">

                                                            <Button onClick={() => navigate('/create-domain-monitor')} className="btn btn-primary me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>Add New Domain</Button>

                                                            {(selectFavourite ? (
                                                                <Button onClick={() => toggleFavouriteList()} disabled={favouriteList.length == 0} className='btn btn-danger me-1'><i className='bx bxs-taxi' id="hearttooltip"></i></Button>
                                                            ) : (
                                                                <Button onClick={() => toggleFavouriteList()} disabled={favouriteList.length == 0} className='btn btn-light me-1'><i className='bx bx-grid-horizontal' id="hearttooltip"></i></Button>
                                                            ))}
                                                            {/* <Button onClick={() => toggleFavouriteList()} disabled={favouriteList.length == 0} className={`btn btn-${selectFavourite ? 'danger' : 'light'} me-1`}><i className={`mdi mdi-heart${selectFavourite ? '' : '-outline'}`}></i></Button> */}
                                                            <Button onClick={() => window.location.reload()} className="btn btn-light me-1"><i className="bx bx-slider-alt"></i></Button>

                                                            {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                                <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                                <DropdownMenu>
                                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown> */}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                                <CardBody>

                                                    {/* <div className="mb-2">
                                                        <button className="btn" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/new-project')}>Add New Project</button>
                                                    </div> */}

                                                    {/* <div className="table-responsive"> */}
                                                        <BootstrapTable
                                                            keyField="id"
                                                            data={projectList}
                                                            columns={columns}
                                                            filter={filterFactory()}
                                                            pagination={paginationFactory()}
                                                        />
                                                    {/* </div> */}
                                                </CardBody>
                                            </>
                                        ) : (
                                            <CardBody>
                                                <center>
                                                    <h3 className="mt-4">Projects help you organise the pages you want to check.</h3>
                                                    <h3 className="mt-2">Create your first project.</h3>
                                                    <div className="mt-4">
                                                        <button className="btn" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/create-domain-monitor')}>Add New Domain</button>
                                                    </div>
                                                </center>
                                            </CardBody>
                                        )}

                                    </Card>
                                </Col>
                            </Row>
                        )
                    }

                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Edit Project Details
                            </h5>
                        </div>
                        <Formik
                            initialValues={{
                                project_name: project_data?.project_name ? project_data?.project_name : "",
                                description: project_data?.description ? project_data?.description : "",
                            }}
                            // validationSchema={SignupSchema}
                            onSubmit={async values => {
                                console.log(values)
                                const projData = {
                                    id: project_data.id,
                                    project_name: values.project_name,
                                    description: values.description,
                                }
                                console.log(projData, "classData")
                                await editProjectDetails(projData).then(r => {
                                    // tog_standard()
                                    setmodal_standard(false);
                                    getAllProjects()
                                    Swal.fire("Project Details Has Updated!", "", "success")
                                }).catch(e => {
                                    console.log(e.response.data)
                                })
                            }}
                        >{({ handleChange, handleBlur, values, errors, touched }) => (
                            <Form>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Project Name
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="project_name"
                                            name="project_name"
                                            placeholder="Enter Your Project Name"
                                            onChange={handleChange}
                                            value={values?.project_name}
                                            invalid={
                                                errors?.project_name && touched?.project_name ? true : false
                                            }
                                            onBlur={handleBlur}
                                            required
                                        />
                                        {errors?.project_name && touched?.project_name ? (
                                            <FormFeedback type="invalid">
                                                {errors?.name}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Description
                                        </Label>
                                        <textarea
                                            id="description"
                                            name="description"
                                            required
                                            type="text"
                                            rows={8}
                                            className="form-control"
                                            placeholder="Enter Description..."
                                            onChange={handleChange}
                                            value={values?.description}
                                            invalid={
                                                errors?.description && touched?.description ? true : false
                                            }
                                            onBlur={handleBlur}
                                        ></textarea>
                                        {errors?.description && touched?.description ? (
                                            <FormFeedback type="invalid">
                                                {errors?.name}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="submit"
                                        style={{ "backgroundColor": "#00264c", "color": "#fff" }}
                                        className="btn btn-lg btn-primary "
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </Modal>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DomainMonitorList;
