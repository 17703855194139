import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import Loading from "components/Common/Loading";


//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";


import { getUserPrompts, getProjectsByUser, getPublicPrompts } from "../../services/main.service";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const ProjectDashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const [publicPrompts, setPublicPrompts] = useState([]);
  const [userPrompts, setUserPrompts] = useState([]);
  const [projects, setProjects] = useState([]);

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    getUserPrompts().then((userPrompts) => {
        if (userPrompts?.data?.prompts) {
            setUserPrompts(userPrompts?.data?.prompts);
        }
    }).catch((error) => {
        console.error('Error Occured');
    });
    getPublicPrompts().then((publicPrompts) => {
        if (publicPrompts?.data?.prompts) {
            setPublicPrompts(publicPrompts?.data?.prompts);
        }
    }).catch((error) => {
        console.error('Error Occured');
    });
    getProjectsByUser().then((projects) => {
        if (projects?.data?.projects) {
            console.log(projects?.data?.projects);
            setProjects(projects?.data?.projects);
        }
    }).catch((error) => {
        console.log({error});
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const viewProject = (projId) => {
    console.log({projId});
  }

  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"ContentGen App Dashboard"}
            breadcrumbItem={"ContentGen App Dashboard"}
          />
          <Row>
          <Col xl="12">
              <Row>
                <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              My Prompts
                            </p>
                            <h4 className="mb-0">{(userPrompts?.length ?? 0)}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              My Projects
                            </p>
                            <h4 className="mb-0">{(projects?.length ?? 0)}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Public Prompts
                            </p>
                            <h4 className="mb-0">{(publicPrompts?.length ?? 0)}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <h4>My Projects</h4>
                        <div className="table-responsive">
                            <Table className="table table-centered table-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">Project</th>
                                        <th scope="col">Project Prompt</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (projects && projects.length > 0 ? (
                                            projects.filter((project, projInd) => projInd <= 4).map((project, projInd) => {
                                                return (
                                                    <tr key={`user-pr-${projInd}`}>
                                                        <td>
                                                            <div>
                                                                <h5 className="text-truncate font-size-14">
                                                                   {project?.proj_name}
                                                                </h5>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">{project?.prompt}</p>
                                                        </td>
                                                        <td>
                                                            <button onClick={() => {viewProject(project?.proj_id)}} className="btn btn-primary" type="button">
                                                                <span>View/Edit Project</span>
                                                            </button>
                                                            <button className="btn btn-warning" style={{"marginLeft": "10px"}} type="button">
                                                                <span>Delete</span>
                                                            </button>
                                                        </td>
                                                    </tr> 
                                                )
                                            })
                                        ) : null)
                                    }
                                </tbody>
                            </Table>
                            {
                                projects && projects.length > 0 ? (
                                    <>
                                        <Button>View All Projects</Button>
                                    </>
                                ) : null
                            }
                        </div>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProjectDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(ProjectDashboard);
