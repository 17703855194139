import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, Modal, FormFeedback, Badge, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { Form as Form1 } from 'reactstrap';

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, getProjectDetails, recheckSingleURL, deleteSingleURL, addNewUrls, recheckProjectRequest, recheckMultipleURLs, getProjectStatusHistory, editProjectDetails, getResultsHistory } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import PropTypes from 'prop-types';
import StatusChart from "../projects/statusChart";

import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactApexChart from "react-apexcharts";

function ReportLog(props) {

    const { id } = useParams();
    const searchParams = new URLSearchParams(window.location.search)
    // console.log(id, 'id');

    const [isLoading, setIsLoading] = useState(true);
    const [projectDetails, setProjectDetails] = useState();
    const [projectHistory, setProjectHistory] = useState();
    const [urlList, setURLList] = useState([]);
    const [toggleStopCheckIndexed, setToggleStopCheckIndexed] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [toggleStatusChangeMail, setToggleStatusChangeMail] = useState(false);
    const [toggleMonitorAll, setToggleMonitorAll] = useState(false);

    const navigate = useNavigate();

    const [status_data, setStatus_data] = useState([]);
    const [historyDetails1, setHistoryDetails1] = useState([]);
    const [oldestDataset, setOldestDataset] = useState([]);
    const [logDataArr, setLogDataArr] = useState([]);
    const [logDataArrSearch, setLogDataArrSearch] = useState([]);
    const [typeVal, setTypeVal] = useState();

    useEffect(() => {
        const type = searchParams.get("type");
        console.log(type, 'searchParams');
        setTypeVal(type)
        getProjectAllDetails();
        getProjectAllHistory();
        getResultLogDetails(type);
    }, [])

    const getResultLogDetails = (type) => {
        getResultsHistory(id).then((project) => {
            console.log(project?.data, "project_dets_result_history");
            setHistoryDetails1(project?.data.result_history)
            const oldestData = project?.data.result_history.sort((a, b) => new Date(a.checked_date) - new Date(b.checked_date));
            setOldestDataset(oldestData.slice(0, urlList.length))
            console.log(oldestData.slice(0, urlList.length), 'gofs');

            let groupedData = {};

            project?.data.result_history.forEach(item => {
                let key = item.checkedresult.toString();
                if (!groupedData[key]) {
                    groupedData[key] = [];
                }
                groupedData[key].push(item);
            });

            // Create new arrays with the desired attributes for each group
            let newArrays = [];

            for (let key in groupedData) {
                let group = groupedData[key];
                let latestRecord = group.reduce((acc, curr) => new Date(curr.checked_date) > new Date(acc.checked_date) ? curr : acc);
                let oldestRecord = group.reduce((acc, curr) => new Date(curr.checked_date) < new Date(acc.checked_date) ? curr : acc);
                let main_status;
                if (latestRecord.status == oldestRecord.status) {
                    main_status = latestRecord.status
                } else if (latestRecord.status == 'Indexed' && oldestRecord.status == 'Not Indexed') {
                    main_status = 're_indexed'
                } else if (oldestRecord.status == 'Indexed' && latestRecord.status == 'Not Indexed') {
                    main_status = 'de_indexed'
                }
                let newArrayItem = {
                    url: group[0].url,
                    latest_checked_date: latestRecord.checked_date,
                    latest_status: latestRecord.status,
                    oldest_checked_date: oldestRecord.checked_date,
                    oldest_status: oldestRecord.status,
                    checkedresult: group[0].checkedresult,
                    main_status: main_status
                };
                newArrays.push(newArrayItem);
            }

            console.log(newArrays, 'newArrays');
            if (type == 'de-indexed') {
                setLogDataArr(newArrays.filter(url => url.main_status == 'de_indexed'))
            } else {
                setLogDataArr(newArrays)
            }
            setLogDataArrSearch(newArrays)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const getProjectAllDetails = () => {
        getProjectDetails(id).then((project) => {
            console.log(project?.data, "project_dets");
            setProjectDetails(project?.data?.project_dets)
            setStatus_data([
                { value: project?.data?.project_dets?.page_indexed, name: "Indexed" },
                { value: project?.data?.project_dets?.page_not_indexed, name: "Not Indexed" },
            ])
            setURLList(project?.data?.results)
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [seriesData, setSeriesData] = useState([]);
    const [xaxisCategories, setxaxisCategories] = useState([]);

    const getProjectAllHistory = () => {
        getProjectStatusHistory(id).then((project) => {
            console.log(project?.data, "getProjectAllHistory");
            setProjectHistory(project?.data?.project_history)
            setSeriesData(
                ['Total Pages', 'Indexed Pages', 'Not Indexed Pages'].map((displayName, index) => ({
                    name: displayName,
                    data: project?.data?.project_history.map(item => {
                        switch (index) {
                            case 0:
                                return item['pages'];
                            case 1:
                                return item['indexed_pages'];
                            case 2:
                                return item['not_indexed_pages'];
                            default:
                                return 0;
                        }
                    })
                }))
            );
            setxaxisCategories(
                project?.data?.project_history.map(item => {
                    const date = new Date(item.created_date);
                    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${date.toLocaleTimeString()}`;
                })
            )
            // setStatus_data([
            //     { value: project?.data?.project_dets?.page_indexed, name: "Indexed" },
            //     { value: project?.data?.project_dets?.page_not_indexed, name: "Not Indexed" },
            // ])
            // setURLList(project?.data?.results)
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [searchText, setSearchText] = useState()

    const filterData = (filterValue) => {
        if (filterValue === 'All') {
            setLogDataArr(logDataArrSearch.filter(url => url.url.includes(searchText)));
        } else {
            setLogDataArr(logDataArrSearch.filter(url => {
                if (searchText && !url.url.includes(searchText)) {
                    return false; // Filter out if search text doesn't match
                }
                // Check for both main_status and selected value
                return url.main_status === filterValue;
            }));
        }
    }

    const handleFilterChange = (val, type) => {
        console.log(val, 'val');
        console.log(type, 'type');
        let searchTerm = '';
        let filterValue = 'All';
        if (type == 'search') {
            searchTerm = val;
        } else if (type == 'filter') {
            filterValue = val;
        }
        if (filterValue == 'All') {
            setLogDataArr(logDataArrSearch.filter(url => url.url.includes(searchTerm)));
        } else {
            setLogDataArr(logDataArrSearch.filter(url => url.main_status == val && url.url.includes(searchTerm)));
        }

    }

    return (
        <div className="page-content">
            {
                isLoading ? (
                    <Loading />
                ) : null
            }

            {(projectDetails?.type == "Domain" ? <Link to={'/domain-monitor-projects-details/' + id}><i className="bx bx-laptop"></i> Project Details</Link> : <Link to={'/project-details/' + id}><i className="bx bx-laptop"></i> Project Details</Link>)}

            <Container fluid>
                {/* Render Breadcrumbs */}
                {/* <Breadcrumbs title="Projects" breadcrumbItem={projectDetails?.project_name} /> */}

                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col lg="7">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <h5 className="mb-1" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{projectDetails?.project_name}</h5>
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>History Log</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>

                        </Card>

                        <Row className="mb-2">
                            <Col lg={4}>
                                <FormGroup row>
                                    <Col lg="10">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search Url..."
                                            onChange={(e) => handleFilterChange(e.target.value, 'search')}
                                        />

                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup row>
                                    <Col lg="10">
                                        {(typeVal === 'de-indexed' ?
                                            <select className="form-control" name="schedule" id="schedule"
                                                onChange={(e) => handleFilterChange(e.target.value, 'filter')}>
                                                <option value="All">All Status</option>
                                                <option value="Indexed">Indexed</option>
                                                <option value="Not Indexed">Not Indexed</option>
                                                <option value="re_indexed">Not Indexed &gt; Indexed</option>
                                                <option value="de_indexed" selected>Indexed &gt; Not Indexed</option>
                                            </select> 
                                            : 
                                            <select className="form-control" name="schedule" id="schedule"
                                                onChange={(e) => handleFilterChange(e.target.value, 'filter')}>
                                                <option value="All">All Status</option>
                                                <option value="Indexed">Indexed</option>
                                                <option value="Not Indexed">Not Indexed</option>
                                                <option value="re_indexed">Not Indexed &gt; Indexed</option>
                                                <option value="de_indexed">Indexed &gt; Not Indexed</option>
                                            </select>)}

                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>


                        {/* <div>
                            <CSVLink data={urlList} headers={headers}>
                                Download CSV
                            </CSVLink>
                        </div> */}
                        <Card>
                            <CardBody>
                                {/* <CardTitle className="h4">Hoverable </CardTitle> */}

                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>URL</th>
                                                <th>Last Checked Date</th>
                                                <th style={{ "textAlign": "center" }}>Status</th>
                                            </tr>
                                        </thead>
                                        {(logDataArr.length > 0 ?
                                            <tbody>
                                                {logDataArr.map((url, index) => (
                                                    <tr className={`table-${url.main_status === 're_indexed' ? 'success' :
                                                        url.main_status === 'de_indexed' ? 'danger' :
                                                            url.main_status === 'Indexed' ? 'light' : 'warning'
                                                        }`}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td><Link to={'/url-history/' + url.checkedresult}>{url.url}</Link></td>
                                                        <td>{new Date(url.latest_checked_date).toLocaleDateString()}</td>
                                                        <td style={{ "textAlign": "center" }}>
                                                            {(url.main_status === 're_indexed' ? <><Badge className="badge-soft-danger">{url.oldest_status}</Badge><i className="bx bx-sitemap"></i><Badge className="bg-success">{url.latest_status}</Badge></> :
                                                                url.main_status === 'de_indexed' ? <><Badge className="badge-soft-success">{url.oldest_status}</Badge><i className="bx bx-sitemap"></i><Badge className="bg-danger">{url.latest_status}</Badge></> :
                                                                    url.main_status === 'Indexed' ? <Badge className="badge-soft-primary" style={{ backgroundColor: "#556ee6", important: true }}>{url.latest_status}</Badge> : <Badge className="bg-warning">{url.latest_status}</Badge>)}
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody> :
                                            <tbody>
                                                <tr>
                                                    <td colspan="4" className="mt-2">
                                                        <center>
                                                            <h5>No data found!</h5>
                                                        </center>
                                                    </td>
                                                </tr>
                                            </tbody>)}
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
ReportLog.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default ReportLog;
