import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Form, FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import Loading from "components/Common/Loading";

//import Charts
import StackedColumnChart from "../Dashboard/StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "../Dashboard/WelcomeComp";
import MonthlyEarning from "../Dashboard/MonthlyEarning";
import SocialSource from "../Dashboard/SocialSource";
import ActivityComp from "../Dashboard/ActivityComp";
import TopCities from "../Dashboard/TopCities";
import LatestTranaction from "../Dashboard/LatestTranaction";

import { getUserPrompts, getProjectsByUser, getPublicPrompts, getBacklinkProjects, getPublicKey, saveSecretKey, getHasToken } from "../../services/main.service";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const SettingsUser = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [hasTokenSaved, setHasTokenSaved] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const [publicPrompts, setPublicPrompts] = useState([]);
  const [userPrompts, setUserPrompts] = useState([]);
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  const [projectsArr, setProjectsArr] = useState([]);
  const [publicKey, setPublicKey] = useState();

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    getPublicKey().then((resPub) => {
      console.log({resPub});
      if (resPub?.data && resPub?.data?.pubkey) {
        console.log(resPub?.data);
        setPublicKey(resPub?.data?.pubkey);
      }
    }).catch((err) => {
      console.log({err});
    }).finally(() => {
      setIsLoading(false);
    });

    getHasToken().then((res) => {
        setHasTokenSaved(res?.data?.hastoken ?? false);
    }).catch((err) => {
        console.log({err});
    }).finally(() => {});
  }, []);

  const viewProject = (projId) => {
    console.log({projId});
  }

  //meta title
  document.title = "BacklinkApp Dashboard";

  const viewProjectLogs = (project) => {
    (project?.proj_id) ? navigate(`/backlinkapp_logs/${project?.proj_id}`) : null;
  }

  const saveOpenAIKey = (e) => {
    let secretKey = e?.target?.secret_key?.value ?? "";
    if (publicKey) {
        let publicKeyNew = forge.pki.publicKeyFromPem(publicKey)
        let encrypted = publicKeyNew.encrypt(secretKey, "RSA-OAEP", {
            md: forge.md.sha256.create(),
            mgf1: forge.mgf1.create()
        });
        let base64 = forge.util.encode64(encrypted);

        setIsLoading(true);
        saveSecretKey(base64).then((resSave) => {
            console.log({resSave});
        }).catch((err) => {
            console.log({err});
        }).finally(() => {
            setIsLoading(false);
        });;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"BacklinkApp Dashboard"}
            breadcrumbItem={"BacklinkApp Dashboard"}
          />
          <Row>
            <Col xl="12">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <h4>Save OpenAI Key</h4>
                        <p>
                            We are encrypting your OpenAI Key using Public/Private Key Encryption
                        </p>
                        <div className="table-responsive">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                saveOpenAIKey(e);
                            }}
                        >                   
                                <FormGroup className="mb-4" row>
                                <Label
                                    htmlFor="project_name"
                                    className="col-form-label col-lg-2"
                                >
                                    Secret Key
                                </Label>
                                <Col lg="10">
                                    <Input
                                    id="secret_key"
                                    name="secret_key"
                                    type="password"
                                    required
                                    className="form-control"
                                    placeholder="Enter Your Secret Key"
                                    />
                                    {hasTokenSaved ? (
                                        <p>You have already saved a token but it will get replaced if you save a new token</p>
                                    ) : null}
                                </Col>
                                </FormGroup>
                                
                                <Row className="justify-content-end mb-4">
                                    <Col lg="10">
                                    <Button type="submit" color="primary">
                                        Save Key
                                    </Button>
                                    </Col>
                                </Row>                
                        </Form>
                        </div>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SettingsUser.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(SettingsUser);
