import React from "react"
import CarouselPage from "../AuthenticationInner/CarouselPage"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import { Col, Container, Row } from "reactstrap"
import { Link } from "react-router-dom"

const ConfirmMail = () => {

    //meta title
    document.title = "Confirm Mail | Indexed Checker App";

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="row g-0">
                        <CarouselPage />

                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5">
                                            <a href="/" className="d-block auth-logo">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="logo-dark-element"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="18"
                                                    className="logo-light-element"
                                                />
                                            </a>
                                        </div>
                                        <div className="my-auto">
                                            <div className="text-center">
                                                <div className="avatar-md mx-auto">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <div className="p-2 mt-4">
                                                    <h4>Success !</h4>
                                                    <p className="text-muted">
                                                        Click on the confirmation link you received by email.
                                                        (Check your spam folder if you didn't receive an email)
                                                    </p>
                                                    <div className="mt-4">
                                                        <Link to="/login" className="btn btn-success">
                                                            Back to Login
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">
                                                © {new Date().getFullYear()} Skote. Crafted with{" "}
                                                <i className="mdi mdi-heart text-danger"></i> by
                                                Themesbrand
                                            </p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ConfirmMail
