import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
    Badge,
    Breadcrumb,
    Button,
    Card, CardBody,
    CardTitle,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Modal,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Toast,
    ToastHeader,
    ToastBody,
} from "reactstrap";
import { Form as Form1 } from 'reactstrap';

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllNormalProjectsByUser, recheckProjectRequest, deleteIndexProject, editProjectDetails, toggleFavourite, getProjectDetails, recheckMultipleURLs, getCheckCountByUser, getBacklinkProjectsByUser, deleteBackLinkProject, deleteBacklinkProject } from "../../services/main.service";

import Loading from "../../components/Common/Loading";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import BootstrapTable from 'react-bootstrap-table-next';
import TableContainer from "components/Common/TableContainer";

import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import logo from "../../assets/images/logo.svg";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { map } from "lodash";
import moment from "moment";

const BacklinkProjectsList = () => {

    //meta title
    document.title = "Backlink Projects | Indexed Checker App";

    const [isLoading, setIsLoading] = useState(true);
    const [btnRefresh, setBtnRefresh] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [projectAllList, setProjectAllList] = useState([]);
    const [favouriteList, setFavouriteList] = useState([]);
    const [urlList, setURLList] = useState([]);
    const [creditCount, setCreditCount] = useState();

    const getCreditCounts = () => {
        getCheckCountByUser().then((project) => {
            console.log(project?.data, "count");
            setCreditCount(project?.data?.count)
            setBtnRefresh(false);
        }).catch((error) => {
            console.log({ error });
        })
    }

    const navigate = useNavigate();


    useEffect(() => {

        getAllProjects();
        getCreditCounts();

    }, [])

    const getAllProjects = () => {
        getBacklinkProjectsByUser().then((projects) => {
            if (projects?.data?.projects) {
                console.log(projects?.data?.projects, "projects_new");
                setProjectAllList(projects?.data?.projects);
                setProjectList(projects?.data?.projects);
                // setFavouriteList(projects?.data?.projects.filter(project => project.favourite == 1));
                setIsLoading(false);
                setBtnRefresh(false);
            }
        }).catch((error) => {
            console.log({ error });
            setIsLoading(true);
            if (error.response?.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleRedirectProjectDetails(project_data) {
        navigate('/project-details/' + project_data.id)
        console.log(project_data, 'class_dets');
    }

    const [dropdownStates, setDropdownStates] = useState(Array(projectList.length).fill(false));

    const toggleDropdown = (index) => {
        console.log(index, 'index');
        const updatedDropdownStates = [...dropdownStates];
        updatedDropdownStates[index] = !updatedDropdownStates[index];
        setDropdownStates(updatedDropdownStates);
    };

    const recheckProject = (project) => {
        console.log(project, 'project');
        if (creditCount <= 0) {
            Swal.fire({
                title: "No Credits!",
                text: "You have no credits to process!",
                icon: "warning",
            });
        } else {
            recheckProjectRequest(project).then((res) => {
                console.log(res, "response");
                getAllProjects();
            }).catch((error) => {
                console.log({ error });
            }).finally(() => {
                setIsLoading(false);
            });
        }

    }

    const recheckNIURLs = (project) => {
        if (creditCount <= 0) {
            Swal.fire({
                title: "No Credits!",
                text: "You have no credits to process!",
                icon: "warning",
            });
        } else {
            getProjectDetails(project.id).then((project) => {
                console.log(project?.data, "project_dets");
                setURLList(project?.data?.results)
                const NIurlList = project?.data?.results.filter(url => url.status === "Not Indexed");
                console.log(NIurlList, 'NIurlList');
                if (NIurlList.length > 0) {
                    recheckMultipleURLs(NIurlList).then((res) => {
                        console.log(res, "response");
                        getAllProjects();
                        toastr.success("Rechecking Not Indexed URLs!")
                    }).catch((error) => {
                        console.log({ error });
                        if (error.response.status === 401) {
                            localStorage.removeItem('user');
                            navigate('/login');
                        }
                    });
                } else {
                    toastr.error("Not Indexed URLs none!")
                }

            }).catch((error) => {
                console.log({ error });
            });
        }

    }

    const deleteProject = (project) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteBacklinkProject(project.indexcheck).then((res) => {
                    console.log(res, "response");

                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    getAllProjects();
                }).catch((error) => {
                    console.log({ error });
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    const [modal_standard, setmodal_standard] = useState(false);

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
        sortOrder === 'asc' ? 'demo-sorting-asc' : 'demo-sorting-desc'
    );

    const handleFavourite = (dets) => {
        toggleFavourite(dets.id).then((res) => {
            console.log(res, "response");

            if (res.data.status == 'Added to Favourites!') {
                toastr.success(res.data.status)
            } else if (res.data.status == 'Removed from Favourites!') {
                toastr.error(res.data.status)
            }
            // Swal.fire(res.data.status, "", "success")
            getAllProjects();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });

    }

    const columns = [
        // {
        //     dataField: 'id',
        //     text: 'Project ID',
        // },
        {
            dataField: 'favourite',
            text: '',
            formatter: (cell, row) => {
                return (<>
                    <Button className={`btn btn-sm btn-soft-${cell === 0 ? 'info' : 'danger'}`}
                        onClick={() => {
                            handleFavourite(row);
                        }}>

                        {(cell === 0 ? (
                            <i className='bx bx-grid-horizontal' id="hearttooltip"></i>
                        ) : (
                            <i className='bx bxs-taxi' id="hearttooltip"></i>
                        ))}
                    </Button>
                    <UncontrolledTooltip placement="top" target="hearttooltip">
                        Favourite
                    </UncontrolledTooltip>
                </>);
            },
        },
        {
            dataField: 'project_name',
            text: 'Project Name',
            // filter: textFilter(),
            sort: true,
            headerSortingClasses,
            formatter: (cell, row) => {
                return (
                    <>
                        {/* {(row.status == 'Checked' ? (
                            <Link to={'/project-details/' + row.id}>{cell}</Link>
                        ) : (
                            <p>{cell}</p>
                        ))} */}
                        <Link to={'/project-details/' + row.id}>{cell}</Link>
                    </>
                );
            },
        }, {
            dataField: 'pages',
            text: 'Pages',
            sort: true,
            headerSortingClasses
        }, {
            dataField: 'page_indexed',
            text: 'Page Indexed',
            formatter: (cell, row) => `${cell} (${((cell / row.pages) * 100).toFixed(0)}%)`
        }, {
            dataField: 'page_not_indexed',
            text: 'Page Not Indexed',
            formatter: (cell, row) => `${cell} (${((cell / row.pages) * 100).toFixed(0)}%)`
        }, {
            dataField: 'created_date',
            text: 'Creation Date',
            formatter: (cell) => new Date(cell).toLocaleString()
        }, {
            dataField: 'last_checked_date',
            text: 'Last Checked Date',
            formatter: (cell) => {
                const formattedDate = new Date(cell).toLocaleDateString();
                return formattedDate !== '1/1/1970' ? new Date(cell).toLocaleString() : '-';
            }
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: cell => {
                return (
                    <Badge className={`bg-${cell === "Checked" ? 'success' : 'secondary'}`}>{cell}</Badge>
                    // <span className={`badge rounded-pill badge-soft-${cell === "Checked" ? 'success' : 'secondary'} font-size-11`}>
                    //     {cell}
                    // </span>
                );
            },
            // filter: selectFilter({
            //     options: selectStatusOptions
            // })
        }, {
            dataField: 'schedule',
            text: 'Recheck',
            // filter: selectFilter({
            //     options: selectScheduleOptions
            // })
        }, {
            dataField: 'view',
            text: 'Action',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (

                    <ul className="list-unstyled hstack gap-1 mb-0">

                        <li data-bs-toggle="tooltip" data-bs-placement="top">
                            <Button className="btn btn-md btn-soft-success"
                                onClick={() => {
                                    recheckProject(row);
                                }}>
                                <i className="bx bx-slider-alt" id="rechecktooltip"></i></Button>
                            <UncontrolledTooltip placement="top" target="rechecktooltip">
                                Recheck
                            </UncontrolledTooltip>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-warning"
                                onClick={() => {
                                    recheckNIURLs(row);
                                }}
                                disabled={row.page_not_indexed == 0}
                            >
                                <i className="bx bxs-calendar-alt" id="rechecknitool" />
                                <UncontrolledTooltip placement="top" target="rechecknitool">
                                    Recheck Not Indexed
                                </UncontrolledTooltip>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-info"
                                onClick={() => {
                                    handleOpenEditModal(row);
                                }}
                            >
                                <i className="bx bx-reply" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-danger"
                                onClick={() => {
                                    deleteProject(row);
                                }}
                            >
                                <i className="bx bx-wrench" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                    </ul>
                )
            }



        }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const [selectFavourite, setSelectFavourite] = useState(false);

    const toggleFavouriteList = () => {
        setSelectFavourite(!selectFavourite);
        console.log(selectFavourite, 'selectFavourite');
        if (!selectFavourite) {
            // setProjectList(projectList.filter(project => project.favourite == 1));
            setProjectList(favouriteList);
        } else {
            setProjectList(projectAllList);
        }
    }

    const searchProcess = (e) => {
        const searchTerm = e.target.value;
        console.log(searchTerm, 'value');
        setProjectList(projectAllList.filter(project => project.project_name.includes(searchTerm)));
    }

    const [projectData, setProjectData] = useState();
    const [toggleStopCheckIndexed, setToggleStopCheckIndexed] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [toggleStatusChangeMail, setToggleStatusChangeMail] = useState(false);
    const [toggleMonitorAll, setToggleMonitorAll] = useState(false);

    const handleOpenEditModal = (project) => {
        setmodal_standard(true);
        setToggleStopCheckIndexed(project?.stop_check_indexed === 'True')
        setToggleSendMails(project?.send_mails === 'True')
        setToggleStatusChangeMail(project?.notify_deindex === 'True')
        setToggleMonitorAll(project?.monitor_url === 'True')
        setProjectData({
            id: project.id,
            project_name: project.project_name,
            project_desc: project.description,
            project_schedule: project.schedule,
            stop_check_indexed: project?.stop_check_indexed === 'True',
            send_mails: project?.send_mails === 'True',
            send_mails_status_change: project?.notify_deindex === 'True',
            monitor_url: project?.monitor_url === 'True',
        })
        console.log(project, 'project');
    }

    const handleProjectUpdate = (e) => {
        console.log(projectData, 'projectData');
        editProjectDetails(projectData).then(r => {
            // tog_standard()
            setmodal_standard(false);
            getAllProjects()
            Swal.fire("Project Details Has Updated!", "", "success")
        }).catch(e => {
            console.log(e)
        })
    }

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const ProjectStatus = ({ status }) => {
        switch (status) {
            case "Checking":
                return <div className="text-center">
                    <div className="text-primary">
                        <i className="bx bx-list-minus bx-spin font-size-18 align-middle me-2" />
                        Checking...
                    </div>
                </div>;

            case "Delay":
                return <Badge className="bg-danger"> {status} </Badge>;

            case "Completed":
                return <div className="text-center">
                    <div to="#" className="text-success">
                        <i className="bx bx-bookmarks font-size-18 align-middle me-2" />
                        Completed
                    </div>
                </div>;

            default:
                return <Badge className="bg-success"> {status} </Badge>;
        }
    };

    const handleBtnRefresh = () => {
        setBtnRefresh(true);
        getAllProjects();
        getCreditCounts();
    }


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Projects" breadcrumbItem="Backlink Projects" /> */}
                    
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Backlink Projects</h4>
                        <div className="page-title-right">
                            {
                                btnRefresh ? (
                                    <Button color="primary" outline>
                                        <i className="bx bx-slider-alt bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        Refresh
                                    </Button>
                                ) : (
                                    <Button color="primary" outline  onClick={() => handleBtnRefresh()}>
                                        Refresh
                                    </Button>
                                )
                            }
                        </div>
                    </div>

                    {
                        isLoading ? (
                            <Row>
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            Loading...
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                {(projectAllList && projectAllList.length > 0) ? (
                                    <>
                                        <Col xl={12}>
                                            <Card>
                                                <CardBody className="border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <h5 className="mb-0 card-title flex-grow-1">Backlink Projects Lists</h5>
                                                        <div className="flex-shrink-0 me-1">
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="search"
                                                                name="search"
                                                                placeholder="Search..."
                                                                onChange={(e) => searchProcess(e)}
                                                            />
                                                        </div>
                                                        <div className="flex-shrink-0">

                                                            <Button onClick={() => navigate('/new-backlink-project')} className="btn btn-primary me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>Add New Project</Button>

                                                            {/* {(selectFavourite ? (
                                                                <Button onClick={() => toggleFavouriteList()} disabled={favouriteList.length == 0} className='btn btn-danger me-1'><i className='bx bxs-taxi' id="hearttooltip"></i></Button>
                                                            ) : (
                                                                <Button onClick={() => toggleFavouriteList()} disabled={favouriteList.length == 0} className='btn btn-light me-1'><i className='bx bx-grid-horizontal' id="hearttooltip"></i></Button>
                                                            ))} */}
                                                            {/* <Button onClick={() => toggleFavouriteList()} disabled={favouriteList.length == 0} className={`btn btn-${selectFavourite ? 'danger' : 'light'} me-1`}><i className={`mdi mdi-heart${selectFavourite ? '' : '-outline'}`}></i></Button> */}
                                                            <Button onClick={() => window.location.reload()} className="btn btn-light me-1"><i className="bx bx-slider-alt"></i></Button>

                                                        </div>
                                                    </div>
                                                </CardBody>

                                            </Card>
                                        </Col>
                                        <Col lg={12}>
                                            <div>
                                                <div className="table-responsive">
                                                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                                                        {/* <thead>
                                                            <tr>
                                                                <th scope="col">Projects</th>
                                                                <th scope="col">Created Date</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Team</th>
                                                                <th scope="col">Back Link Recheck</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead> */}
                                                        <tbody>
                                                            {map(projectList, (project, index) => (
                                                                <tr key={index} className="shadow-sm" style={{ padding: '10px' }}>
                                                                    <td onClick={()=> navigate('/backlink-project-details/'+ project.id)}>
                                                                        <h5 className="text-truncate font-size-14">
                                                                            <Link
                                                                                to={`/backlink-project-details/${project.id}`}
                                                                                className="text-dark"
                                                                            >
                                                                                {project.domain}
                                                                            </Link>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">
                                                                            {project.url_count} Backlinks
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <ProjectStatus status={project.status} />
                                                                    </td>
                                                                    <td> {handleValidDate(project.created_date)}</td>
                                                                    <td>
                                                                        {/* <div className="avatar-group">
                                                                            {map(project.team, (member, index) =>
                                                                                !member.img || member.img !== "Null" ? (
                                                                                    <div
                                                                                        className="avatar-group-item"
                                                                                        key={index}
                                                                                    >
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="team-member d-inline-block"
                                                                                            id="member1"
                                                                                        >
                                                                                            <img
                                                                                                src={images[member.img]}
                                                                                                className="rounded-circle avatar-xs"
                                                                                                alt=""
                                                                                            />
                                                                                            <UncontrolledTooltip
                                                                                                placement="top"
                                                                                                target="member1"
                                                                                            >
                                                                                                {member.name}
                                                                                            </UncontrolledTooltip>
                                                                                        </Link>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="avatar-group-item"
                                                                                        key={"_team_" + index}
                                                                                    >
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="d-inline-block"
                                                                                            id={"member" + member.id}
                                                                                        >
                                                                                            <div className="avatar-xs">
                                                                                                <span
                                                                                                    className={
                                                                                                        "avatar-title rounded-circle bg-soft bg-" +
                                                                                                        member.color +
                                                                                                        " text-" +
                                                                                                        member.color +
                                                                                                        " font-size-16"
                                                                                                    }
                                                                                                >
                                                                                                    {member.name.charAt(0)}
                                                                                                </span>
                                                                                                <UncontrolledTooltip
                                                                                                    placement="top"
                                                                                                    target={"member" + member.id}
                                                                                                >
                                                                                                    {member.name}
                                                                                                </UncontrolledTooltip>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div> */}
                                                                    </td>

                                                                    {/* <td>
                                                                        {(project.backlink_recheck == 'True' ? <>
                                                                            <Badge className="bg-primary"> {project.backlink_recheck_schedule} </Badge>
                                                                        </> : <>
                                                                            <Badge className="bg-secondary"> None </Badge>
                                                                        </>)}
                                                                    </td> */}
                                                                    <td>
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle
                                                                                href="#"
                                                                                className="card-drop"
                                                                                tag="a"
                                                                            >
                                                                                <i className="bx bx-cycling font-size-18" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className="dropdown-menu-end">
                                                                                {/* <DropdownItem
                                                                                    href="#"
                                                                                onClick={() => handleProjectClick(project)}
                                                                                >
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                                                                    Edit
                                                                                </DropdownItem> */}
                                                                                <DropdownItem
                                                                                    href="#"
                                                                                    onClick={() => deleteProject(project)}
                                                                                >
                                                                                    <i className="bx bx-wrench font-size-16 text-danger me-1" />{" "}
                                                                                    Delete
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                ) : (
                                    <Col lg={12}>
                                        <CardBody>
                                            <center>
                                                <h3 className="mt-4">Projects help you organise the pages you want to check.</h3>
                                                <h3 className="mt-2">Create your first project.</h3>
                                                <div className="mt-4">
                                                    <button className="btn" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/new-backlink-project')}>New Project</button>
                                                </div>
                                            </center>
                                        </CardBody>
                                    </Col>
                                )}
                            </Row>
                        )
                    }

                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();
                        }}
                    >
                        <Form1
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleProjectUpdate(e);
                            }}>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Edit Project Details
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Project Name
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="project_name"
                                        name="project_name"
                                        placeholder="Enter Your Project Name"
                                        onChange={(e) => { setProjectData({ ...projectData, project_name: e?.target?.value }) }}
                                        value={projectData?.project_name}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Description
                                    </Label>
                                    <textarea
                                        id="description"
                                        name="description"
                                        required
                                        type="text"
                                        rows={8}
                                        className="form-control"
                                        placeholder="Enter Description..."
                                        onChange={(e) => { setProjectData({ ...projectData, project_desc: e?.target?.value }) }}
                                        value={projectData?.project_desc}
                                    ></textarea>
                                </div>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="basicpill-phoneno-input32">
                                                Recheck Period
                                            </Label>
                                            <select className="form-control" name="schedule" id="schedule"
                                                value={projectData?.schedule}
                                                onChange={(e) => { setProjectData({ ...projectData, project_schedule: e?.target?.value }) }}>
                                                <option value="No">No</option>
                                                <option value="Daily">Daily</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="1"></Col>
                                    <Col lg="11">
                                        <FormGroup row>
                                            <div className="form-check form-switch form-switch-md">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="stop_check_indexed"
                                                >
                                                    Stop rechecking urls for pages that are already indexed
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="stop_check_indexed"
                                                    name="stop_check_indexed"
                                                    checked={projectData?.stop_check_indexed}
                                                    onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="1"></Col>
                                    <Col lg="11">
                                        <FormGroup row>
                                            <div className="form-check form-switch form-switch-md">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="send_mails"
                                                >
                                                    Be notified via email when these pages are checked
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="send_mails"
                                                    name="send_mails"
                                                    checked={projectData?.send_mails}
                                                    onClick={(e) => { setProjectData({ ...projectData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="1"></Col>
                                    <Col lg="11">
                                        <FormGroup row>
                                            <div className="form-check form-switch form-switch-md">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="send_mails_status_change"
                                                >
                                                    Be notified via email after change of index status of any url
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="send_mails_status_change"
                                                    name="send_mails_status_change"
                                                    checked={projectData?.send_mails_status_change}
                                                    onClick={(e) => { setProjectData({ ...projectData, send_mails_status_change: !toggleStatusChangeMail }), setToggleStatusChangeMail(!toggleStatusChangeMail) }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="1"></Col>
                                    <Col lg="11">
                                        <FormGroup row>
                                            <div className="form-check form-switch form-switch-md">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="monitor_url"
                                                >
                                                    Monitor all url of the project
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="monitor_url"
                                                    name="monitor_url"
                                                    checked={projectData?.monitor_url}
                                                    onClick={(e) => { setProjectData({ ...projectData, monitor_url: !toggleMonitorAll }), setToggleMonitorAll(!toggleMonitorAll) }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    style={{ "backgroundColor": "#00264c", "color": "#fff" }}
                                    className="btn btn-lg btn-primary "
                                >
                                    Submit
                                </button>
                            </div>
                        </Form1>
                    </Modal>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default BacklinkProjectsList;
