import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { registerUserNew } from "services/main.service";
import Loading from "components/Common/Loading";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {

    //meta title
    document.title = "Register | Indexed Checker App";

    const searchParams = new URLSearchParams(window.location.search)

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [capVal, setCapVal] = useState(null);
    const [partner, setPartner] = useState();
    const [campaign, setCampaign] = useState();

    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        
        console.log(searchParams, 'searchParams');
        setPartner(searchParams.get("partner"));
        setCampaign(searchParams.get("campaign"));

        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        script.addEventListener('load', () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY).then((token) => {
                    console.log(token, 'token');
                    setIsVerified(true)
                })
            })
        })
        document.body.appendChild(script)
    }, [])

    //form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        
        onSubmit: (values) => {
            const user_data = {
                email: values.email,
                username: values.username,
                password: values.password,
                partner: partner,
                campaign: campaign
            }
            console.log(values);
            console.log(user_data, 'user_data');
            setIsLoading(true);
            registerUserNew(user_data).then((newUser) => {
                navigate('/page-confirm-mail');

            }).catch((err) => {
                console.log(err, 'error');
                if (err.response.data?.email != '') {
                    setErrorMsg(err.response.data.email)
                }
                if (err.response.data?.username[0] != '') {
                    setErrorMsg(err.response.data.username[0])
                }

            }).finally(() => {
                setIsLoading(false);
            });
        }
    });

    return (
        <React.Fragment>
            {
                (isLoading ? (
                    <Loading />
                ) : null)
            }
            <div className="g-recaptcha" data-sitekey="6Lc_XYYpAAAAALH9PJUn0d4AhJX8hZpqXnTCfkpD" data-size="invisible"></div>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage />

                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5">
                                            <Link to="https://indexedchecker.com/" className="d-block auth-logo">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo-dark"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="18"
                                                    className="auth-logo-light"
                                                />
                                            </Link>
                                        </div>
                                        <div className="my-auto">
                                            <div>
                                                <h5 style={{ "color": "#00264c" }}>Register account</h5>
                                                <p className="text-muted">
                                                    Get your free Indexed Checker account now.
                                                </p>
                                            </div>

                                            <div className="mt-4">

                                                <Form className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email</Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Username</Label>
                                                        <Input
                                                            name="username"
                                                            type="text"
                                                            placeholder="Enter username"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.username || ""}
                                                            invalid={
                                                                validation.touched.username && validation.errors.username ? true : false
                                                            }
                                                        />
                                                        {validation.touched.username && validation.errors.username ? (
                                                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Password</Label>
                                                        <Input
                                                            name="password"
                                                            type="password"
                                                            placeholder="Enter password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div>
                                                        <p className="mb-0">
                                                            By registering you agree to the{" "}
                                                            <a href="https://indexedchecker.com/terms/" target="_blank" className="text-primary">
                                                                Terms of Use
                                                            </a>
                                                        </p>
                                                    </div>
                                                    {/* <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                        onChange={(val) => setCapVal(val)}
                                                    /> */}

                                                    <div className="mt-4 d-grid">
                                                        <button style={{ "backgroundColor": "#00264c", "color": "#fff" }}
                                                            className="btn btn-primary waves-effect waves-light "
                                                            type="submit"
                                                            disabled={!isVerified}
                                                        >
                                                            Register
                                                        </button>
                                                    </div>

                                                </Form>


                                                {/* <Form action="dashboard">

                                                    <div className="mt-4 text-center">
                                                        <h5 className="font-size-14 mb-3">Sign up using</h5>

                                                        <ul className="list-inline">
                                                            <li className="list-inline-item">
                                                                <Link
                                                                to="#"
                                                                className="social-list-item bg-primary text-white border-primary me-1"
                                                                >
                                                                <i className="mdi mdi-facebook"></i>
                                                                </Link>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <Link
                                                                to="#"
                                                                className="social-list-item bg-info text-white border-info me-1"
                                                                >
                                                                <i className="mdi mdi-twitter"></i>
                                                                </Link>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <Link
                                                                    to="#"
                                                                    className="social-list-item bg-danger text-white border-danger"
                                                                >
                                                                    <i className="mdi mdi-google"></i>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Form> */}

                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Already have an account ?{" "}
                                                        <Link
                                                            to="/login"
                                                            className="fw-medium text-primary"
                                                        >
                                                            {" "}
                                                            Login
                                                        </Link>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©{" "}
                        { new Date().getFullYear()}
                        Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Register;
