import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
  } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useNavigate} from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { savePrompt, saveProjectData, saveProject, getGeneratedFullContentForTitles, generateTitlesForContent, generateFullContentForTitles, updatePrompt, updateProject, genContOnlyPrompt, saveContentOnly, publishOnWordpress, getHasToken, saveWpConfigProject } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

const MultiDividedContentProjAll = () => {

  //meta title
    document.title = "Create Single Project";

    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [selectedFiles, setselectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [mainPrompt, setMainPrompt] = useState();
    const [keywordsArr, setKeywordsArr] = useState([]);
    let [proPrompt, setProPrompt] = useState("");
    const [finalContent, setFinalContent] = useState("");

    const [loadingStatus, setLoadingStatus] = useState(null);
    const [contentData, setContentData] = useState(null);
    const [wpUrl, setWpUrl] = useState(null);
    const [saveStatus, setSaveStatus] = useState({status: 0, project_id: null, prompt_id: null});

    const [wordpress, setWordpress] = useState({user_name: "admin2", password: "6DT@w&6Mx%pNeVr&d@rv@OJk", wp_url: "https://testwp.kongcepts.info/wp", content: "", title: "General", proj_id: null});

    const [hasTokenSaved, setHasTokenSaved] = useState(false);
    const [isUseApiKey, setIsUseApiKey] = useState(false);

    const [wpConfigData, setWpConfigData] = useState({user_name: "", password: "", wp_url: "", proj_id: null});
    const [multiCont, setMultiCont] = useState([]);

    const [tokenUsage, setTokenUsage] = useState();

    const [nextPrompt, setNextPrompt] = useState();
    const [isFinal, setIsFinal] = useState(false);
    const [strContGen, setStrContGen] = useState(null);

    const [multiContDivideProject, setMultiContDivideProject] = useState({
        initprompt: null,
        isuseuserkey: true,
        model: null,
        num_of_points: 10
    });

    const [projectData, setProjectData] = useState({
        project_name: "",
        prompt: "",
        website: "",
        main_keyword: "",
        second_keyword: "",
        wordpress_email: "",
        org_prompt: "",
        code_model: "gpt-3.5-turbo-0301",
        proj_id: null,
        openaikey: "",
        isuseuserkey: false
    });

    const [projStep, setProjStep] = useState(0);

    const [projTitlesArr, setProjTitlesArr] = useState([]);

  const startDateChange = date => {
    setstartDate(date);
  };

  const [allGenProjects, setAllGenProjects] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getGeneratedFullContentForTitles().then((resAll) => {
        console.log({resAll});
        if(resAll?.data?.projects) {
            setAllGenProjects(resAll?.data?.projects);
        }
    }).catch((err) => {console.log({err})}).finally(() => {setIsLoading(false);});
  }, []);

  const endDateChange = date => {
    setendDate(date);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

    const createPrompt = (e) => {
        let prompt = e?.target?.value ?? "";
        let regExp = /\[@(?<content>[^\[\]]+)\]/g;;
        if (prompt) {
            let checkKeyWords = Array.from(
                prompt.matchAll(regExp)
            ).map(
                ({ groups: { content } }) => content
            );

            let checkKeyWordsMaps = checkKeyWords.map((keyw, ind) => ({index: ind, key: keyw, value: ''}));
            setKeywordsArr(checkKeyWordsMaps);
            setMainPrompt(prompt);
            setProPrompt(prompt);
            setProjectData({...projectData, org_prompt: prompt});
        }
    }

    const changeParams = (e, param) => {
        let vals = e?.target?.value ?? "";
        if (vals !== "") {
            let keywordsArrAlter = keywordsArr.map((key) => {
                if (key.index === param.index) {
                    return {...key, value: vals}
                }
                return key
            });
            setKeywordsArr(keywordsArrAlter);
        }

    }

    useEffect(() => {
        if (keywordsArr && keywordsArr.length > 0) {
            let proPromptData = mainPrompt;
            let mainKeyWords = projectData.main_keyword;
            let secondKeyWords = projectData.second_keyword;
            keywordsArr.forEach((keyData) => {
                if (keyData?.key.toLowerCase().includes('mainkey') || keyData?.key.toLowerCase().includes('mainkeyword')  || keyData?.key.toLowerCase().includes('mainkeywords')) {
                    mainKeyWords = keyData?.value;
                }
                if (keyData?.key.toLowerCase().includes('secondkey') || keyData?.key.toLowerCase().includes('secondkeyword')  || keyData?.key.toLowerCase().includes('secondkeywords')) {
                    secondKeyWords = keyData?.value;
                }
                proPromptData = proPromptData.replace(`[@${keyData.key}]`, keyData.value)
            });
            //proPrompt.replace(`[@${param.key}]`, e.target.value)
            setProPrompt(proPromptData);
            setProjectData({...projectData, main_keyword: mainKeyWords, second_keyword: secondKeyWords});
        }
    }, [keywordsArr, mainPrompt])


    useEffect(() => {
        if (proPrompt) {
            setProjectData({...projectData, prompt: proPrompt});
        }
    }, [proPrompt]);

    useEffect(() => {
        console.log({wpConfigData});
    }, [wpConfigData]);

    const encryptConversion = (value) => {
        let pubKey = localStorage.getItem("pubkey");
        if (pubKey) {
            let publicKeyNew = forge.pki.publicKeyFromPem(pubKey)
            let encrypted = publicKeyNew.encrypt(value, "RSA-OAEP", {
                md: forge.md.sha256.create(),
                mgf1: forge.mgf1.create()
            });
            return forge.util.encode64(encrypted);
        } else {
            return null;
        }
    }

    const encryptWpConfig = (projId) => {
        if (wpConfigData?.user_name != "" && wpConfigData?.wp_url != "" && wpConfigData?.password != "") {
            let userName = encryptConversion(wpConfigData?.user_name);
            let wpUrl = encryptConversion(wpConfigData?.wp_url);
            let password = encryptConversion(wpConfigData?.password);

            saveWpConfigProject({user_name: userName, wp_url: wpUrl, password: password, proj_id: projId}).then((resConfig) => {
                console.log({resConfig});
                if (resGenCont?.data?.message) {
                    setStrContGen(true);
                }
            }).catch((error) => {
                console.log({error});
            }).finally(() => {
            });
            return true;
        } else {
            return false;
        }
    }

    const saveProjectData = (e) => {
        console.log({projectData});
        if(projectData) {
            setLoadingStatus("Saving the project");
            if (!saveStatus?.project_id && !saveStatus?.prompt_id) {
                setIsLoading(true);
                savePrompt({prompt: projectData?.org_prompt, prompt_name: projectData?.project_name ?? `Default Prompt Name`, is_public: false}).then((promptRes) => {
                    console.log({promptRes});
                    if (promptRes?.data?.message) {
                        let prompt_id_new = promptRes?.data?.prompt_id;
                        saveProject({...projectData, prompt_id: prompt_id_new }).then((resProject) => {
                            console.log('Coming hereee:::', {resProject});
                            if (resProject?.data?.project_id) {
                                setSaveStatus({status: 1, project_id: resProject?.data?.project_id, prompt_id: prompt_id_new});
                                setProjectData({...projectData, proj_id: resProject?.data?.project_id});
                                generatePromptData();
                                setWpConfigData({...wpConfigData, proj_id: resProject?.data?.project_id});

                                setWordpress({...wordpress, proj_id: resProject?.data?.project_id});

                                let wpConfigDetails = {...wpConfigData, proj_id: resProject?.data?.project_id};
                                //saveWpConfigProject
                                let updatedStatus = encryptWpConfig(resProject?.data?.project_id);
                            }
                            
                        }).catch((error) => {
                            console.log({error});
                            //localStorage.removeItem('user');
                            //navigate('/login');
                        });
                    }
                }).catch((error) => {
                    localStorage.removeItem('user');
                    navigate('/login');
                });
            } else {
                setIsLoading(true);
                updatePrompt(saveStatus?.prompt_id, {prompt: projectData?.org_prompt, prompt_name: projectData?.project_name ?? `Default Prompt Name`}).then((promptRes) => {
                    console.log({promptRes});
                    if (promptRes?.data?.message) {
                        let prompt_id_new = promptRes?.data?.prompt_id;
                        updateProject({...projectData, prompt_id: prompt_id_new }).then((resProject) => {
                            console.log('Coming hereee:::', {resProject});
                            if (resProject?.data?.project_id) {
                                setSaveStatus({status: 1, project_id: resProject?.data?.project_id, prompt_id: prompt_id_new});
                                generatePromptData();
                            }
                            
                        }).catch((error) => {
                            console.log({error});
                            //localStorage.removeItem('user');
                            //navigate('/login');
                        });
                    }
                }).catch((error) => {
                    localStorage.removeItem('user');
                    navigate('/login');
                });
            }
            
        }
    }

    const generatePromptData = () => {
        setIsLoading(true);

        let publicKey = localStorage.getItem('pubkey');
        let openKey = projectData?.openaikey
        let publicKeyNew = forge.pki.publicKeyFromPem(publicKey)
        let encrypted = publicKeyNew.encrypt(openKey, "RSA-OAEP", {
            md: forge.md.sha256.create(),
            mgf1: forge.mgf1.create()
        });
        let base64 = forge.util.encode64(encrypted);

        genContOnlyPrompt(projectData?.prompt, projectData?.code_model, base64, projectData?.isuseuserkey).then((genContData) => {
            console.log({genContData});
            if (genContData?.data?.content) {
                let contentData = genContData?.data?.content.replace(/\n/g, "");
                console.log({contentData});
                setContentData(contentData);
                setFinalContent(contentData);
                setNextPrompt(contentData);
                setMultiCont([...multiCont, contentData]);
            }
        }).catch((error) => {
            console.log("Error Occured", error);
            localStorage.removeItem('user');
            navigate('/login');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    let generateContWithPrompt = (prompt, isFinal = false) => {
        setIsLoading(true);
        let publicKey = localStorage.getItem('pubkey');
        let openKey = projectData?.openaikey
        let publicKeyNew = forge.pki.publicKeyFromPem(publicKey)
        let encrypted = publicKeyNew.encrypt(openKey, "RSA-OAEP", {
            md: forge.md.sha256.create(),
            mgf1: forge.mgf1.create()
        });
        let base64 = forge.util.encode64(encrypted);
        genContOnlyPrompt(prompt, projectData?.code_model, base64, projectData?.isuseuserkey).then((genContData) => {
            console.log({genContData});
            if (genContData?.data?.content) {
                let contentData = genContData?.data?.content.replace(/\n/g, "");
                console.log({contentData});
                setContentData(contentData);
                setFinalContent(contentData);
                setNextPrompt(contentData);
                setMultiCont([...multiCont, contentData]);
                setIsFinal(isFinal);

                if (isFinal === true) {
                    setFinalContent(contentData);
                }
            }
        }).catch((error) => {
            console.log("Error Occured", error);
            localStorage.removeItem('user');
            navigate('/login');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const saveContentAndPublish = (isPublish = false, contentData = null) => {
        //saveContentOnly
        if (finalContent) {
            let genCont = contentData ?? finalContent;
            setIsLoading(true);
            saveContentOnly(projectData?.proj_id, {content: genCont, cont_title: projectData?.project_name}).then((saveCont) => {
                console.log({saveCont});
                if (isPublish) {
                    setWordpress({...wordpress, content: genCont});
                    postToWordPress();
                } else {
                    navigate('/contentgen_dashboard');
                }
            }).catch((error) => {
                console.log({error});
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        getHasToken().then((res) => {
            console.log('res?.data?.hastoken::', res?.data?.hastoken);
            setHasTokenSaved(res?.data?.hastoken ?? false);
            setIsUseApiKey(res?.data?.hastoken ?? false);
            setProjectData({...projectData, isuseuserkey: res?.data?.hastoken ?? false});
        }).catch((err) => {
            console.log({err});
        }).finally(() => {});
      }, []);

    const postToWordPress = () => {
        console.log({wordpress});
        if (wordpress.wp_url) {
            setLoadingStatus("Publishing to Wordpress");
            setIsLoading(true);
            publishOnWordpress(wordpress).then((wordpressresponse) => {
                if (wordpressresponse?.data?.statuscode) {
                    setLoadingStatus("Published");
                    setWpUrl(wordpress?.wp_url);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    let changeUseApiKey = (e) => {
        setIsUseApiKey(e?.target?.checked);
        setProjectData({...projectData, isuseuserkey: e?.target?.checked ?? false});
    }

    const generatePromptAgain = () => {
        if (nextPrompt) {
            generateContWithPrompt(nextPrompt);
        }
    }

    const finishRefining = () => {
        //setIsFinal
        console.log({nextPrompt});
        if (nextPrompt) {
            generateContWithPrompt(nextPrompt, true);
        }
    }

    const saveAllFinal = () => {
        saveContentAndPublish();
    }

    const saveProjectContentDataInitial = () => {
        //projStep
        console.log("coming here");
        
        if (multiContDivideProject?.initprompt) {
            setIsLoading(true);
            setProjStep(1);
            generateTitlesForContent(multiContDivideProject?.initprompt, multiContDivideProject?.isuseuserkey, multiContDivideProject?.num_of_points).then((resMulti) => {
                console.log({resMulti});
                if (resMulti?.data?.titles) {
                    let promptMap = resMulti?.data?.titles.map((proj) => {
                        return {prompt: proj, wordcount: 100}
                    });
                    console.log("promptMap::", promptMap);
                    setProjTitlesArr(promptMap);
                    setTokenUsage({completion_tokens: resMulti?.data?.completion_tokens, prompt_tokens: resMulti?.data?.prompt_tokens, total_tokens: resMulti?.data?.total_tokens});
                }
            }).catch((err) => {console.log(err)}).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const saveProjectContentGeneration = () => {
        if (projTitlesArr && projTitlesArr.length > 0) {
            let cusProjArr = projTitlesArr.map((proj) => {
                return {prompt: proj.prompt, wordcount: proj?.wordcount ?? 100}
            });
            let postData = {
                contgen_titles: cusProjArr,
                contgen_name: "test project"
            };
            
            setIsLoading(true);
            generateFullContentForTitles(postData).then((resGenCont) => {
                console.log({resGenCont});
                if (resGenCont?.data?.message && resGenCont?.data?.mc_id) {
                    setStrContGen(resGenCont?.data?.mc_id);
                }
            }).catch((err) => {console.log(err);}).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const updateFieldChangeTitles = (value, index, isPrompt = false) => {
        let newArr = [...projTitlesArr];
        if (isPrompt) {
            newArr[index] = {...newArr[index], prompt: value};
        } else {
            newArr[index] = {...newArr[index], wordcount: value};
        }
        setProjTitlesArr(newArr);
    }

    const viewProjectSingle = () => {
        if (strContGen) {
            console.log({strContGen});
        }
    }

  return (
    <React.Fragment>
      <div className="page-content">
        {
            isLoading ? (
                <Loading/>
            ) : null
        }

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12" className={`${(strContGen ? "cont-gen-started" : "")}`}>
                {
                    strContGen ? (
                        <div className="cont-gen-started-wrapper">
                            <p>
                                Content is generating now and will be available shortly
                            </p>
                            <a onClick={viewProjectSingle} className="btn btn-primary">View Project</a>
                        </div>
                    ) : null
                }
              <Card>
                <CardBody>
                    <h4>My Projects</h4>
                    <div className="table-responsive">
                        <Table className="table table-centered table-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Used Model</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allGenProjects.length > 0 ? (
                                        allGenProjects.map((genProj, indx) => {
                                            return (
                                                <tr key={`gen-pro-${indx}`}>
                                                    <td>
                                                        {genProj?.mc_name}
                                                    </td>
                                                    <td>
                                                        {genProj?.mc_status}
                                                    </td>
                                                    <td>
                                                        {genProj?.mc_content_model}
                                                    </td>
                                                    <td>
                                                        <Button className="btn btn-primary" onClick={() => {
                                                            console.log(genProj?.mc_id)
                                                            navigate(`/contentgen_multicontproj_single/${genProj?.mc_id}`)
                                                        }}>
                                                            View Project
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : null
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MultiDividedContentProjAll;
