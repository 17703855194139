import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Label,
  Form,
  FormGroup
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import Loading from "components/Common/Loading";

//import Charts
import StackedColumnChart from "../Dashboard/StackedColumnChart";

import ChartistGraph from "react-chartist"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "../Dashboard/WelcomeComp";
import MonthlyEarning from "../Dashboard/MonthlyEarning";
import SocialSource from "../Dashboard/SocialSource";
import ActivityComp from "../Dashboard/ActivityComp";
import TopCities from "../Dashboard/TopCities";
import LatestTranaction from "../Dashboard/LatestTranaction";
import { useParams } from 'react-router';
import { getBacklinkProjects, getSingleProjectBackLink } from "../../services/main.service";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PieChart from "../AllCharts/chartist/piechart";
//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const BacklinkProjectLogs = props => {

  //const params = props.router.params;
  const params= useParams()

  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  let pieChartDataLinkStatus = {
    series: [10, 15, 20],
    labels: ["42%", "25%", "33%"]
  }

  const [pieChartDataLinkStatusData, setPieChartDataLinkStatus] = useState(pieChartDataLinkStatus)

  let pieChartDataAnalyseStatus = {
    series: [10, 15, 20],
    labels: ["42%", "25%", "33%"]
  }

  const [pieChartDataAnalyseStatusData, setPieChartDataAnalyseStatus] = useState(pieChartDataAnalyseStatus)

  

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  const [projectData, setProjectData] = useState([]);
  const [publicPrompts, setPublicPrompts] = useState([]);
  const [userPrompts, setUserPrompts] = useState([]);
  const [projects, setProjects] = useState([]);


  useEffect(() => {
    if (params?.id) {
        setIsLoading(false);
        getSingleProjectBackLink(params?.id).then((resProject) => {
            console.log({resProject});
            if (resProject?.data) {
              setProjectData(resProject?.data);
              if (resProject?.data?.proj_data) {
                let percData = resProject?.data?.proj_data;
                let live = percData?.alive_percent ?? 100;
                let dead = percData?.dead_percent ?? 100;
                let dofollow_percent = percData?.dofollow_percent ?? 100;
                let nofollow_percent = percData?.nofollow_percent ?? 100;

                let per_do_fol = Math.round((dofollow_percent / (dofollow_percent + nofollow_percent)) * 100);
                let per_no_fol = Math.round((nofollow_percent / (dofollow_percent + nofollow_percent)) * 100);

                pieChartDataLinkStatus = {
                    series: [live, dead],
                    labels: [`${live}% (Live)`, `${dead}% (Dead)`]
                }

                let pieChartDataLinkStatusAnalyze = {
                    series: [per_do_fol, per_no_fol],
                    labels: [`${per_do_fol}% (Do Follow)`, `${per_no_fol}% (No Follow)`]
                }

                setPieChartDataLinkStatus(pieChartDataLinkStatus);
                setPieChartDataAnalyseStatus(pieChartDataLinkStatusAnalyze);
              }
              
            }
          }).catch((err) => {
            console.log({err});
          }).finally(() => {
            setIsLoading(false);
          });
    }
    
  }, [params]);

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  const viewProject = (projId) => {
    console.log({projId});
  }

  //meta title
  document.title = "BacklinkApp Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"BacklinkApp Dashboard"}
            breadcrumbItem={"BacklinkApp Dashboard"}
          />
          <Row>
            <Col xl="12">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <PieChart dataColors='["--bs-warning, --bs-primary, --bs-success"]' pieChartData={pieChartDataLinkStatusData}/>
                            </Col>
                            <Col lg="6">
                                <PieChart dataColors='["--bs-warning, --bs-primary, --bs-success"]' pieChartData={pieChartDataAnalyseStatusData}/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col xl="12">
                <Card className="mini-stats-wid">
                    <CardBody>
                        {
                            projectData?.proj_data ? (
                                <Form>
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="project_name"
                                            className="col-form-label col-lg-2"
                                        >
                                            Target Url
                                        </Label>
                                        <Col lg="10">
                                            <Input
                                            id="target_url"
                                            name="target_url"
                                            type="text"
                                            readOnly
                                            value={projectData?.proj_data?.website}
                                            className="form-control"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="project_name"
                                            className="col-form-label col-lg-2"
                                        >
                                            Project Name
                                        </Label>
                                        <Col lg="10">
                                            <Input
                                            id="target_url"
                                            name="target_url"
                                            type="text"
                                            readOnly
                                            value={projectData?.proj_data?.proj_name}
                                            className="form-control"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="project_name"
                                            className="col-form-label col-lg-2"
                                        >
                                            Status
                                        </Label>
                                        <Col lg="10">
                                            <Input
                                            id="target_url"
                                            name="target_url"
                                            type="text"
                                            readOnly
                                            value={projectData?.proj_data?.status}
                                            className="form-control"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="project_name"
                                            className="col-form-label col-lg-2"
                                        >
                                            Number of Backlinks
                                        </Label>
                                        <Col lg="10">
                                            <Input
                                            id="target_url"
                                            name="target_url"
                                            type="text"
                                            readOnly
                                            value={projectData?.proj_data?.backlinks ? projectData?.proj_data?.backlinks.length : 0}
                                            className="form-control"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            ) : null
                        }
                        
                    </CardBody>
                </Card>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <h4>Scheduler Logs</h4>
                        <div className="table-responsive">
                            <Table className="table table-centered table-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">Schedule Time</th>
                                        <th scope="col">Backlink Url</th>
                                        <th scope="col">Link Status</th>
                                        <th scope="col">No Follow Status</th>
                                        <th scope="col">Sponsored Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (projectData && projectData?.project_logs && projectData?.project_logs.length > 0 ? (
                                            projectData?.project_logs.map((project, ind) => {
                                                return (
                                                    <tr key={`user-pr-${ind}`}>
                                                        <td>
                                                            <div>
                                                                <h5 className="text-truncate font-size-14">
                                                                   {project?.log_time}
                                                                </h5>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <p>
                                                                    {project?.backlink_url}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {project?.link_status}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {project?.nofollow_status}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {project?.sponsored_status}
                                                            </div>
                                                        </td>
                                                    </tr> 
                                                )
                                            })
                                        ) : null)
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BacklinkProjectLogs.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(BacklinkProjectLogs);
