import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './CardElementStyles.css';
import { requestPayment } from 'services/main.service';

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            const { token } = await stripe.createToken(cardElement);
            // Pass the token to your backend
            console.log(token, 'token');
            const p_data = {
                token: token.id,
                amount: 1000,
            }
            requestPayment(p_data).then((res)=> {
                console.log(res);
            }).catch((error) => {
                console.log({ error });
            }).finally(() => {
                setIsLoading(false);
            });
            // handleToken(token);
        } catch (error) {
            setError(error.message);
        }

        setLoading(false);
    };

    const handleToken = async (token) => {
        // Send the token to your backend server
        try {
            const response = await fetch('/api/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token.id,
                    amount: 1000, // Example amount in cents ($10)
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to process payment');
            }

            // Payment successful, handle accordingly
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <form style={{ marginTop: "100px" }} onSubmit={handleSubmit}>
            <div className="card-element-container">
                <label className="card-element-label">
                    Card Details
                </label>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>
            <button type="submit" disabled={!stripe || loading}>
                Pay
            </button>
            {error && <div>{error}</div>}
        </form>
    );
};

export default PaymentForm;
