import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import Loading from "components/Common/Loading";

//import Charts
import StackedColumnChart from "../Dashboard/StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "../Dashboard/WelcomeComp";
import MonthlyEarning from "../Dashboard/MonthlyEarning";
import SocialSource from "../Dashboard/SocialSource";
import ActivityComp from "../Dashboard/ActivityComp";
import TopCities from "../Dashboard/TopCities";
import LatestTranaction from "../Dashboard/LatestTranaction";

import { getUserPrompts, getProjectsByUser, getPublicPrompts, getBacklinkProjects, deleteBackLinkProject, updateBacklinkProjectStatus } from "../../services/main.service";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const BacklinkDashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const [publicPrompts, setPublicPrompts] = useState([]);
  const [userPrompts, setUserPrompts] = useState([]);
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  const [projectsArr, setProjectsArr] = useState([]);

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  useEffect(() => {
    loadProjects();
  }, []);

  let loadProjects = () => {
    setIsLoading(true);
    getBacklinkProjects().then((resProject) => {
      console.log({resProject});
      if (resProject?.data?.project_list) {
        setProjectsArr(resProject?.data?.project_list);
      }
    }).catch((err) => {
      console.log({err});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const viewProject = (projId) => {
    console.log({projId});
  }

  //meta title
  document.title = "BacklinkApp Dashboard";

  const viewProjectLogs = (project) => {
    (project?.proj_id) ? navigate(`/backlinkapp_logs/${project?.proj_id}`) : null;
  }

  const printDateTime = (dateStr) => {
    if (dateStr) {
      let [yyyy,mm,dd,hh,mi] = dateStr.split(/[/:\-T]/);
      return `${yyyy}-${mm}-${dd} ${hh}:${mi}`;
    } 
    return "No Date Available";
  }

  const deleteBackLinkProjectInitiate = (projId) => {
    setIsLoading(true);
    if (projId) {
      deleteBackLinkProject(projId).then((respo) => {
        console.log(respo);
        if (respo?.data) {
          loadProjects();
        }
      }).catch((err) => {
        console.log({err});
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  const updateBkProjectStatus = (projId) => {
    setIsLoading(true);
    if (projId) {
      updateBacklinkProjectStatus(projId).then((resp) => {
        if (resp?.data) {
          loadProjects();
        }
      }).catch((err) => {
        console.log({err});
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {
                isLoading ? (
                    <Loading/>
                ) : null
        }
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"BacklinkApp Dashboard"}
            breadcrumbItem={"BacklinkApp Dashboard"}
          />
          <Row>
            <Col xl="12">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <h4>My Projects</h4>
                        <div className="table-responsive">
                            <Table className="table table-centered table-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">Project Name</th>
                                        <th scope="col">Last Updated Time</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (projectsArr && projectsArr.length > 0 ? (
                                          projectsArr.map((project, ind) => {
                                                return (
                                                    <tr key={`user-pr-${ind}`}>
                                                        <td>
                                                            <div>
                                                                <h5 className="text-truncate font-size-14">
                                                                   {project?.proj_name}
                                                                </h5>
                                                                <p className="text-muted mb-0">{project?.website}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                          <span>{printDateTime(project?.completeddt)}</span>
                                                        </td>
                                                        <td>
                                                          <span>{project?.status}</span>
                                                        </td>
                                                        <td>
                                                            {
                                                              project?.status && project?.status !== 'START' ? (
                                                                <button onClick={() => { viewProjectLogs(project) }} className="btn btn-primary" type="button">
                                                                  <span>View Logs</span>
                                                                </button>
                                                              ) : null
                                                            }
                                                            {
                                                              project?.status && project?.status === 'INACTIVE' ? (
                                                                <button onClick={() => {updateBkProjectStatus(project?.proj_id)}} className="btn btn-primary" style={{"marginLeft": "10px"}} type="button">
                                                                  <span>Activate</span>
                                                              </button>
                                                              ) : (
                                                                <button onClick={() => {updateBkProjectStatus(project?.proj_id)}} className="btn btn-primary" style={{"marginLeft": "10px"}} type="button">
                                                                  <span>Deactivate</span>
                                                                </button>
                                                              )
                                                            }
                                                            <button onClick={() => {deleteBackLinkProjectInitiate(project?.proj_id)}} className="btn btn-warning" style={{"marginLeft": "10px"}} type="button">
                                                                <span>Delete</span>
                                                            </button>
                                                        </td>
                                                    </tr> 
                                                )
                                            })
                                        ) : null)
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BacklinkDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(BacklinkDashboard);
