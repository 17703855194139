import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useNavigate} from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { savePrompt, saveProjectData, saveProject, updatePrompt, updateProject, genContOnlyPrompt, saveContentOnly, publishOnWordpress } from "../../services/main.service";

import Loading from "../../components/Common/Loading";
import { analyzeSingleBacklink, saveBacklinkMultiProject } from "../../services/main.service";

const MultiAnalizerSubmit = () => {

  //meta title
    document.title = "Create Single Project";
    const [isLoading, setIsLoading] = useState(false);
    const [resData, setResData] = useState(null);

    const [backlinksArr, setBacklinksArr] = useState([]);
    const [projectDet, setProjectDet] = useState({
        project_name: null,
        website: null,
        frequency: 1,
        backlinks: []
    });

    const navigate = useNavigate();
    const [urlData, setUrlData] = useState({
        target_url: "",
        backlink_url: ""
    });

    const startDateChange = date => {
        setstartDate(date);
    };

    const endDateChange = date => {
        setendDate(date);
    };


    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const urlValidator = (statusConst, url) => {
        if (validateUrl(url)) {
            if (statusConst === 'TARGET') {
                setUrlData({...urlData, target_url: url});
                setProjectDet({...projectDet, website: url});
            } else {
                setUrlData({...urlData, backlink_url: url});
            }
        } else {
            console.log("not valid url");
        }
    }

    const validateUrl = (value) => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const analyzeBacklink = (e) => {
        if (urlData?.backlink_url && urlData?.target_url) {
            setIsLoading(true);
            analyzeSingleBacklink(urlData).then((res) => {
                console.log({res});
                if (res?.data) {
                    setResData(res?.data);
                }
            }).catch((e) => {
                console.log('error:', e);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const getFileContent = (e) => {
        console.log({e});
        let files = e.target.files;
        let file = files[0];
        let fileData, fileDataArr;

        if (file?.type === 'text/plain') {
            console.log({file});
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = ((theFile) => {
                return (eDt) => {
                    fileData = eDt.target.result;
                    if (fileData) {
                        fileDataArr = fileData.split(/\r?\n/);
                        setBacklinksArr(fileDataArr);
                    }
                };
              })(file);
        }
    }

    useEffect(() => {
        if (backlinksArr.length > 0) {
            setProjectDet({...projectDet, backlinks: backlinksArr});
        }
    }, [backlinksArr]);

    const saveMultiBacklinkProject = (e) => {
        e.preventDefault();
        console.log('backlinksArr', {projectDet});
        if (projectDet?.project_name && projectDet?.website && backlinksArr.length > 0) {
            console.log({projectDet});
            setIsLoading(true);
            saveBacklinkMultiProject(projectDet).then((resData) => {
                console.log({resData});
                navigate('/backlinkapp_dashboard');
            }).catch((err) => {
                console.log({err});
            }).finally(() => {
                setIsLoading(false);
            });
        }
        
    }

    return (
        <React.Fragment>
        <div className="page-content">
            {
                isLoading ? (
                    <Loading/>
                ) : null
            }

            <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Analyze Backlink" breadcrumbItem="Backlink App" />

            <Row>
                <Col lg="12">
                <Card>
                    <CardBody>
                    <CardTitle className="mb-4">Save Project</CardTitle>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            saveMultiBacklinkProject(e);
                        }}
                        encType="multipart/form-data"
                    >
                            <FormGroup className="mb-4" row>
                                <Label
                                    htmlFor="project_name"
                                    className="col-form-label col-lg-2"
                                >
                                    Project Name
                                </Label>
                                <Col lg="10">
                                    <Input
                                        id="project_name"
                                        name="project_name"
                                        type="text"
                                        required
                                        className="form-control"
                                        placeholder="Project Name"
                                        onChange={(e) => {setProjectDet({...projectDet, project_name: e?.target?.value})}}
                                    />
                                </Col>
                            </FormGroup>         
                            <FormGroup className="mb-4" row>
                                <Label
                                    htmlFor="project_name"
                                    className="col-form-label col-lg-2"
                                >
                                    Target Url
                                </Label>
                                <Col lg="10">
                                    <Input
                                    id="target_url"
                                    name="target_url"
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder="Target Url"
                                    onChange={(e) => {urlValidator('TARGET', e?.target?.value)}}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                            <Label
                                htmlFor="project_name"
                                className="col-form-label col-lg-2"
                            >
                                Backlink Urls (Upload Text File)
                            </Label>
                            <Col lg="10">
                                <Input
                                    id="backlink_url"
                                    name="backlink_url"
                                    type="file"
                                    required
                                    className="form-control"
                                    placeholder="Backlink Url"
                                    onChange={getFileContent}
                                />
                            </Col>
                            </FormGroup>
                            <Row className="justify-content-end mb-4">
                                <Col lg="10">
                                <Button type="submit" color="primary">
                                    Save Project
                                </Button>
                                </Col>
                            </Row>  
                            <FormGroup className="mb-4" row>
                                <Col lg="12">
                                    {
                                        backlinksArr.length > 0 ? (
                                            <>
                                                {
                                                    console.log({backlinksArr})
                                                }
                                                {
                                                    backlinksArr.map((backlink, indBack) => {
                                                        return (
                                                            <div key={`bk-arr-l-${indBack}`} className={'mb-3'}>
                                                                <Input
                                                                    id="backlink_urls"
                                                                    name="backlink_urls"
                                                                    type="text"
                                                                    readOnly
                                                                    required
                                                                    className="form-control"
                                                                    value={backlink}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : null
                                    }
                                </Col>
                            </FormGroup>              
                    </Form>
                    <div>
                        {
                            resData ? (
                                <>
                                    {
                                        (resData?.nofollow_status || resData?.sponsored_status) ? (
                                            <>
                                                {
                                                    resData?.nofollow_status ? (
                                                        <>
                                                            <label>
                                                                <b>No Follow Status: </b>{resData?.nofollow_status}
                                                            </label><br/>
                                                        </>
                                                    ) : null
                                                }
                                                {
                                                    resData?.sponsored_status ? (
                                                        <label>
                                                            <b>No Follow Status: </b>{resData?.sponsored_status}
                                                        </label>
                                                    ) : null
                                                }
                                            </>
                                        ) : (
                                            <label>
                                                <b>Status: </b>Not Found
                                            </label>
                                        )
                                    }
                                </>
                            ) : null
                        }
                    </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default MultiAnalizerSubmit;
