import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, Modal, FormFeedback, Badge, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { Form as Form1 } from 'reactstrap';

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, getProjectDetails, recheckSingleURL, deleteSingleURL, addNewUrls, recheckProjectRequest, recheckMultipleURLs, getProjectStatusHistory, editProjectDetails, getDomainProjectDetails, editDomainProjectDetails } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import PropTypes from 'prop-types';
import StatusChart from "../projects/statusChart";

import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactApexChart from "react-apexcharts";

function DomainProjectDetails(props) {

    const { id } = useParams();
    // console.log(id, 'id');

    const [isLoading, setIsLoading] = useState(true);
    const [projectDetails, setProjectDetails] = useState();
    const [domainProjectDetails, setDomainProjectDetails] = useState();
    const [projectHistory, setProjectHistory] = useState();
    const [urlList, setURLList] = useState([]);
    const [toggleStopCheckIndexed, setToggleStopCheckIndexed] = useState(false);
    const [toggleMonitorSitemap, setToggleMonitorSitemap] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [toggleStatusChangeMail, setToggleStatusChangeMail] = useState(false);
    const [toggleMonitorAll, setToggleMonitorAll] = useState(false);
    const [timeSinceLastChecked, settimeSinceLastChecked] = useState("...");

    const navigate = useNavigate();

    const [status_data, setStatus_data] = useState([]);

    useEffect(() => {
        getProjectAllDetails();
        getProjectAllHistory();
        getDomainAllDetails();
    }, [])

    const getProjectAllDetails = () => {
        getProjectDetails(id).then((project) => {
            console.log(project?.data, "project_dets");
            setProjectDetails(project?.data?.project_dets)
            setStatus_data([
                { value: project?.data?.project_dets?.page_indexed, name: "Indexed" },
                { value: project?.data?.project_dets?.page_not_indexed, name: "Not Indexed" },
            ])
            setURLList(project?.data?.results)
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const getDomainAllDetails = () => {
        getDomainProjectDetails(id).then((project) => {
            console.log(project?.data, "project_dets");
            setDomainProjectDetails(project?.data?.domain_dets)
            setIsLoading(false)
            settimeSinceLastChecked(getTimeSinceLastChecked(project?.data?.domain_dets.last_scraped_date));
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [seriesData, setSeriesData] = useState([]);
    const [xaxisCategories, setxaxisCategories] = useState([]);

    const getProjectAllHistory = () => {
        getProjectStatusHistory(id).then((project) => {
            console.log(project?.data, "getProjectAllHistory");
            setProjectHistory(project?.data?.project_history)
            setSeriesData(
                ['Total Pages', 'Indexed Pages', 'Not Indexed Pages'].map((displayName, index) => ({
                    name: displayName,
                    data: project?.data?.project_history.map(item => {
                        switch (index) {
                            case 0:
                                return item['pages'];
                            case 1:
                                return item['indexed_pages'];
                            case 2:
                                return item['not_indexed_pages'];
                            default:
                                return 0;
                        }
                    })
                }))
            );
            setxaxisCategories(
                project?.data?.project_history.map(item => {
                    const date = new Date(item.created_date);
                    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                })
            )
            // setStatus_data([
            //     { value: project?.data?.project_dets?.page_indexed, name: "Indexed" },
            //     { value: project?.data?.project_dets?.page_not_indexed, name: "Not Indexed" },
            // ])
            // setURLList(project?.data?.results)
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const [dropdownStates, setDropdownStates] = useState(Array(urlList.length).fill(false));

    const toggleDropdown = (index) => {
        const updatedDropdownStates = [...dropdownStates];
        updatedDropdownStates[index] = !updatedDropdownStates[index];
        setDropdownStates(updatedDropdownStates);
    };

    const recheckURL = (project) => {
        console.log(project, 'project');
        recheckSingleURL(project).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckProject = () => {
        console.log(projectDetails, 'project');
        recheckProjectRequest(projectDetails).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckMultiURLs = (url_arr) => {
        console.log(url_arr, 'url_arr');
        recheckMultipleURLs(url_arr).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const recheckNIURLs = () => {
        console.log(urlList, 'urlList');
        const NIurlList = urlList.filter(url => url.status === "Not Indexed");
        console.log(NIurlList, 'NIurlList');
        recheckMultipleURLs(NIurlList).then((res) => {
            console.log(res, "response");
            getProjectAllDetails();
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const exportToCsv = () => {
        const fieldsToExport = ['url', 'first_checked_date', 'last_checked_date', 'status', 'url_status'];
        const fieldName = ['URL', 'Created Date', 'Last Checked Date', 'Status', 'URL Status'];

        // Function to format a date object to "YYYY-MM-DD" format
        const formatDate = (date) => {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const hours = String(formattedDate.getHours()).padStart(2, '0');
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const headerRow = fieldName.join(',') + '\n';

        const csvContent = "data:text/csv;charset=utf-8," + headerRow +
            urlList.map(row => {
                return fieldsToExport.map(field => {
                    // Check if the field is a date field, and format it accordingly
                    return field.includes('date') ? formatDate(row[field]) : row[field];
                }).join(',');
            }).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", projectDetails.project_name + "_export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const [settingsMenu, setSettingsMenu] = useState(false)

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    const deleteURL = (url) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSingleURL(url.id).then((res) => {
                    console.log(res, "response");

                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    getProjectAllDetails();
                }).catch((error) => {
                    console.log({ error });
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    const [modalAddUrl, setModalAddUrl] = useState(false);
    const [estimatedCounts, setEstimatedCounts] = useState(0);

    const handleOpenNewUrlModal = () => {
        setModalAddUrl(!modalAddUrl);
        setEstimatedCounts(0);
    }
    // const headers = Object.keys(urlList[0]);

    const columns = [
        // {
        //     dataField: 'id',
        //     text: 'Product ID',
        //     sort: true
        // },
        {
            dataField: 'url',
            text: 'URL',
            sort: true,
            // filter: textFilter()
        }, {
            dataField: 'first_checked_date',
            text: 'Created Date',
            sort: true,
            formatter: (cell) => new Date(cell).toLocaleString()
        }, {
            dataField: 'last_checked_date',
            text: 'Last Checked Date',
            sort: true,
            formatter: (cell) => {
                const formattedDate = new Date(cell).toLocaleDateString();
                return formattedDate !== '1/1/1970' ? new Date(cell).toLocaleString() : '-';
            }
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (cell) => (
                <span className={`badge rounded-pill badge-soft-${cell === "Indexed" ? 'success' : cell === "Not Indexed" ? 'warning' : 'info'} font-size-11`}>
                    {cell}
                </span>
            )
        }, {
            dataField: 'url_status',
            text: 'URL Status',
            sort: true
        }, {
            dataField: 'view',
            // text: 'Action',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">

                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <Button className="btn btn-sm btn-soft-success"
                                onClick={() => {
                                    recheckURL(row);
                                }}>
                                <i className="bx bx-slider-alt" id="rechecktooltip"></i></Button>
                        </li>
                        <UncontrolledTooltip placement="top" target="rechecktooltip">
                            Recheck
                        </UncontrolledTooltip>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    deleteURL(row);
                                }}
                            >
                                <i className="bx bx-wrench" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                    </ul>
                    // <UncontrolledDropdown className="dropdown d-inline-block me-1">
                    //     <DropdownToggle type="menu" className="btn btn-secondary" id="dropdownMenuButton1">
                    //         <i className="bx bx-data"></i></DropdownToggle>
                    //     <DropdownMenu>
                    //         <li><DropdownItem onClick={() => recheckURL(row)}>Recheck</DropdownItem></li>
                    //         <li><DropdownItem onClick={() => deleteURL(row)}>Delete</DropdownItem></li>
                    //     </DropdownMenu>
                    // </UncontrolledDropdown>
                )
            }



        }];

    const selectRow1 = {
        mode: 'checkbox',
        clickToSelect: false,
        hideSelectColumn: true,
        // bgColor: '#00BFFF',
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row.id, 'rowId');
            console.log(isSelect, 'isSelect');
            console.log(rowIndex, 'rowIndex');
            console.log(e);
        },
    };

    const [selectedRows, setSelectedRows] = useState([])


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelectedRows([...selectedRows, row]);
            } else {
                setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row));
            }
            // console.log(row, 'rowId');
            // console.log(isSelect, 'isSelect');
            // console.log(rowIndex, 'rowIndex');
            // console.log(e);
        },
        hideSelectAll: true,
    };

    const chart_data = {
        series: seriesData,
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: xaxisCategories
            }
        }
    };

    const [modal_standard, setmodal_standard] = useState(false);
    const [projectData, setProjectData] = useState();

    const handleOpenEditModal = () => {
        setmodal_standard(true);
        setToggleStopCheckIndexed(projectDetails?.stop_check_indexed === 'True')
        setToggleSendMails(projectDetails?.send_mails === 'True')
        setToggleStatusChangeMail(projectDetails?.notify_deindex === 'True')
        setToggleMonitorAll(projectDetails?.monitor_url === 'True')
        setProjectData({
            id: projectDetails.id,
            project_name: projectDetails.project_name,
            project_desc: projectDetails.description,
            project_schedule: projectDetails.schedule,
            stop_check_indexed: projectDetails?.stop_check_indexed === 'True',
            send_mails: projectDetails?.send_mails === 'True',
            send_mails_status_change: projectDetails?.notify_deindex === 'True',
            monitor_url: projectDetails?.monitor_url === 'True',
            monitor_sitemap: domainProjectDetails?.monitor_sitemap === 'True',
            sitemap_recheck: domainProjectDetails.sitemap_recheck,
        })
        console.log(projectDetails, 'project');
    }

    const handleProjectUpdate = (e) => {
        console.log(projectData, 'projectData');
        editDomainProjectDetails(projectData).then(r => {
            // tog_standard()
            setmodal_standard(false);
            getProjectAllDetails()
            getDomainAllDetails()
            Swal.fire("Project Details Has Updated!", "", "success")
        }).catch(e => {
            console.log(e.response.data)
        })
    }
    const [newUrls, setNewUrl] = useState()

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const urls = textareaValue.split('\n').map(line => line.trim()).filter(line => line !== '');

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Filter out non-URL values and remove duplicates
        const uniqueUrls = Array.from(new Set(urls.filter(url => urlRegex.test(url))));

        const newUniqueUrls = uniqueUrls.filter(url => !urlList.map(item => item.url).includes(url));

        const domain = domainProjectDetails.domain;
        const domainUrls = newUniqueUrls.filter(url => new RegExp(domain).test(url));

        console.log(domainUrls, 'newUniqueUrls');


        setEstimatedCounts(domainUrls.length);
        setNewUrl(domainUrls.join('\n'));
    };

    const handleNewUrls = () => {
        console.log(newUrls, 'newUrls');
        const urlsData = {
            id: id,
            urls: newUrls,
        }
        console.log(urlsData, "urlsData")
        addNewUrls(urlsData).then(r => {
            // tog_standard()
            setModalAddUrl(false);
            getProjectAllDetails();
            Swal.fire("Added New URLs!", "", "success")
        }).catch(e => {
            console.log(e.response.data)
        })
    }

    function getTimeSinceLastChecked(lastCheckedDate) {
        const now = new Date();
        const lastChecked = new Date(lastCheckedDate);
        console.log(lastChecked, 'lastChecked');
        const timeDiff = now.getTime() - lastChecked.getTime();
        console.log(timeDiff, 'timeDiff');
        if (timeDiff < 0) {
            const timeZoneOffset = 5.5 * 60 * 60 * 1000;
            now.setTime(now.getTime() + timeZoneOffset);
            console.log(now, 'now');
        }
    
    
        // Calculate the time difference in seconds
        const seconds = Math.floor(timeDiff / 1000);
        console.log(seconds, 'seconds');
    
        if (seconds < 60) {
            return `${seconds} seconds ago`;
        }
    
        // Calculate the time difference in minutes
        const minutes = Math.floor(seconds / 60);
    
        if (minutes < 60) {
            return `${minutes} minutes ago`;
        }
    
        // Calculate the time difference in hours
        const hours = Math.floor(minutes / 60);
    
        if (hours < 24) {
            return `${hours} hours ago`;
        }
    
        // Calculate the time difference in days
        const days = Math.floor(hours / 24);
    
        if (days < 30) {
            return `${days} days ago`;
        }
    
        // Calculate the time difference in months
        const months = Math.floor(days / 30);
    
        return `${months} months ago`;
    }

    return (
        <div className="page-content">
            {
                isLoading ? (
                    <Loading />
                ) : null
            }

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    setmodal_standard(!modal_standard);
                }}
            >
                <Form1
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleProjectUpdate(e);
                    }}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Edit Project Details
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                                Project Name
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="project_name"
                                name="project_name"
                                placeholder="Enter Your Project Name"
                                onChange={(e) => { setProjectData({ ...projectData, project_name: e?.target?.value }) }}
                                value={projectData?.project_name}
                                readOnly
                            />
                        </div>
                        <Row>
                            <Col lg="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-phoneno-input32">
                                        Recheck Period
                                    </Label>
                                    <select className="form-control" name="schedule" id="schedule"
                                        value={projectData?.schedule}
                                        onChange={(e) => { setProjectData({ ...projectData, project_schedule: e?.target?.value }) }}>
                                        {/* <option value="No">No</option> */}
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="monitor_sitemap"
                                        >
                                            Monitor Sitemap
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="monitor_sitemap"
                                            name="monitor_sitemap"
                                            checked={projectData?.monitor_sitemap}
                                            onClick={(e) => { setProjectData({ ...projectData, monitor_sitemap: !toggleMonitorSitemap }), setToggleMonitorSitemap(!toggleMonitorSitemap) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        {(projectData?.monitor_sitemap) ?
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="basicpill-phoneno-input32">
                                            Sitemap Recheck Period
                                        </Label>
                                        <select className="form-control" name="schedule" id="schedule"
                                            value={projectData?.sitemap_recheck}
                                            onChange={(e) => { setProjectData({ ...projectData, sitemap_recheck: e?.target?.value }) }}>
                                            {/* <option value="No">No</option> */}
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row> : null
                        }
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="stop_check_indexed"
                                        >
                                            Stop rechecking urls for pages that are already indexed
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stop_check_indexed"
                                            name="stop_check_indexed"
                                            checked={projectData?.stop_check_indexed}
                                            onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="send_mails"
                                        >
                                            Be notified via email when these pages are checked
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="send_mails"
                                            name="send_mails"
                                            checked={projectData?.send_mails}
                                            onClick={(e) => { setProjectData({ ...projectData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="send_mails_status_change"
                                        >
                                            Be notified via email after change of index status of any url
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="send_mails_status_change"
                                            name="send_mails_status_change"
                                            checked={projectData?.send_mails_status_change}
                                            onClick={(e) => { setProjectData({ ...projectData, send_mails_status_change: !toggleStatusChangeMail }), setToggleStatusChangeMail(!toggleStatusChangeMail) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">
                                <FormGroup row>
                                    <div className="form-check form-switch form-switch-md">
                                        <label
                                            className="form-check-label"
                                            htmlFor="monitor_url"
                                        >
                                            Monitor all url of the project
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="monitor_url"
                                            name="monitor_url"
                                            checked={projectData?.monitor_url}
                                            onClick={(e) => { setProjectData({ ...projectData, monitor_url: !toggleMonitorAll }), setToggleMonitorAll(!toggleMonitorAll) }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            style={{ "backgroundColor": "#00264c", "color": "#fff" }}
                            className="btn btn-lg btn-primary "
                        >
                            Submit
                        </button>
                    </div>
                </Form1>
            </Modal>

            <Modal
                isOpen={modalAddUrl}
                toggle={() => {
                    handleOpenNewUrlModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Add New URLs
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModalAddUrl(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Form1
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleNewUrls(e);
                    }}>
                    <div className="modal-body">

                        <div className="mb-3">

                            <Label
                                htmlFor="website"
                                className="col-form-label col-lg-6"
                            >
                                Website URLs
                            </Label>
                            <Col lg="12">
                                <textarea
                                    id="urls"
                                    name="urls"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Website URLs here..."
                                    rows={10}
                                    onChange={(e) => {
                                        handleTextareaChange(e)
                                    }}
                                ></textarea>
                                <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                            </Col>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary "
                        >
                            Submit
                        </button>
                    </div>
                </Form1>
                {/* <Formik
                    initialValues={{
                        urls: "",
                    }}
                    // validationSchema={SignupSchema}
                    onSubmit={async values => {
                        console.log(values)
                        const urlsData = {
                            id: id,
                            urls: values.urls,
                        }
                        console.log(urlsData, "urlsData")
                        await addNewUrls(urlsData).then(r => {
                            // tog_standard()
                            setModalAddUrl(false);
                            getProjectAllDetails();
                            Swal.fire("Added New URLs!", "", "success")
                        }).catch(e => {
                            console.log(e.response.data)
                        })
                    }}
                >{({ handleChange, handleBlur, values, errors, touched }) => (
                    <Form>
                        <div className="modal-body">

                            <div className="mb-3">

                                <Label
                                    htmlFor="website"
                                    className="col-form-label col-lg-6"
                                >
                                    Website URLs
                                </Label>
                                <Col lg="12">
                                    <textarea
                                        id="urls"
                                        name="urls"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Website URLs here..."
                                        rows={10}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleTextareaChange(e)
                                        }}
                                        value={values?.urls}
                                        invalid={
                                            errors?.urls && touched?.urls ? true : false
                                        }
                                        onBlur={handleBlur}
                                    ></textarea>
                                    {errors?.urls && touched?.urls ? (
                                        <FormFeedback type="invalid">
                                            {errors?.name}
                                        </FormFeedback>
                                    ) : null}
                                    <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                                </Col>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className="btn btn-primary "
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
                </Formik> */}
            </Modal>

            <Container fluid>
                {/* Render Breadcrumbs */}
                {/* <Breadcrumbs title="Projects" breadcrumbItem={projectDetails?.project_name} /> */}

                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col lg="6">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 align-self-center" style={{ "cursor": "pointer" }} title="Edit" onClick={() => {
                                                handleOpenEditModal();
                                            }}>
                                                <div className="text-muted">
                                                    <h5 className="mb-1" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{projectDetails?.project_name}</h5>
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{projectDetails?.description}</p>
                                                    {/* <p className="mb-0">{projectDetails?.pages} Pages</p> */}
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Recheck Period: {" "}
                                                        <Badge className="badge bg-warning">{projectDetails?.schedule}</Badge></p>
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {(projectDetails?.monitor_url == 'True' ?
                                                            <><span style={{ fontWeight: 'bold', color: 'green' }}>Monitor all {" "}</span><Badge className="badge-soft-info">{projectDetails?.pages} Pages</Badge></> :
                                                            <><span style={{ fontWeight: 'bold', textDecoration: 'line-through', color: 'red' }}>Monitor all {" "}</span><Badge className="badge-soft-info">{projectDetails?.pages} Pages</Badge></>)}
                                                    </p>
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Sitemap checked {timeSinceLastChecked}</p>
                                                    <p className="mb-2" style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Sitemap Recheck: {" "}
                                                        {(domainProjectDetails?.monitor_sitemap === "True" ?
                                                            <Badge className="badge bg-success">{domainProjectDetails?.sitemap_recheck}</Badge> :
                                                            <Badge className="badge bg-warning">No</Badge>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6" className="align-self-center">
                                        <div className="text-lg-center mt-4 mt-lg-0">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card className="mini-stats-wid" style={{ boxShadow: "0 0.75rem 1.5rem rgb(13 31 54 / 34%)" }}>
                                                        <CardBody>
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-3">
                                                                    <p className="text-muted mb-2">Indexed</p>
                                                                    <h5 className="mb-0">{projectDetails?.page_indexed} ({((projectDetails?.page_indexed / projectDetails?.pages) * 100).toFixed(2)}%)</h5>
                                                                </div>

                                                                <div className="avatar-sm ms-auto">
                                                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                        <i className="bx bx-border-top"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col lg={6}>
                                                    <Card className="blog-stats-wid" style={{ boxShadow: "0 0.75rem 1.5rem rgb(13 31 54 / 34%)" }}>
                                                        <CardBody>
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-3">
                                                                    <p className="text-muted mb-2">Not Indexed</p>
                                                                    <h5 className="mb-0">{projectDetails?.page_not_indexed} ({((projectDetails?.page_not_indexed / projectDetails?.pages) * 100).toFixed(2)}%)</h5>
                                                                </div>

                                                                <div className="avatar-sm ms-auto">
                                                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                        <i className="bx bx-palette"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" className="align-self-center">
                                        {/* {(projectHistory?.length > 0 ? ( */}
                                        <CardBody>
                                            <h4 className="card-title mb-3">Project Status</h4>
                                            <div>
                                                <div id="overview-chart" dir="ltr">
                                                    <ReactApexChart
                                                        series={chart_data.series}
                                                        options={chart_data.options}
                                                        type="line"
                                                        height={240}
                                                        className="apex-charts"
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                        {/* ) : null)} */}
                                    </Col>
                                    <Col lg="6" className="d-none d-lg-block">
                                        <div className="clearfix mt-4 mt-lg-0">
                                            <StatusChart dataColors='["--bs-success", "--bs-warning","--bs-primary", "--bs-danger","--bs-info"]' status_data={status_data} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                        <Row>
                            <Col>
                                <CardBody>
                                    <div className="flex-shrink-0 mb-2">
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => handleOpenNewUrlModal()}>Add New URLs</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => recheckProject()}>Recheck All</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => recheckNIURLs()} disabled={projectDetails?.page_not_indexed == 0}>Recheck Not Index({projectDetails?.page_not_indexed})</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} disabled={selectedRows.length == 0} onClick={() => recheckMultiURLs(selectedRows)}>Recheck({selectedRows.length})</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => exportToCsv()}>Export to CSV</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/report-log/' + id)}>History Log</button>
                                        <button style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => window.location.reload()} className="btn btn-light me-1"><i className="bx bx-slider-alt"></i></button>
                                    </div>
                                </CardBody>
                            </Col>
                        </Row>


                        {/* <div>
                            <CSVLink data={urlList} headers={headers}>
                                Download CSV
                            </CSVLink>
                        </div> */}
                        <Card>
                            <CardBody>
                                {/* <CardTitle className="h4">Hoverable </CardTitle> */}

                                <div className="table-responsive">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={urlList}
                                        columns={columns}
                                        selectRow={selectRow}
                                        filter={filterFactory()}
                                    />
                                </div>

                                {/* <div className="table-responsive">
                                    <Table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>URL</th>
                                                <th>Creation Date</th>
                                                <th>Last Checked Date</th>
                                                <th>Status</th>
                                                <th>URL Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (urlList && urlList.length > 0 ? (
                                                    urlList.map((url, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{url.url}</td>
                                                            <td>{new Date(url.first_checked_date).toLocaleDateString() !== '1/1/1970' ? new Date(url.first_checked_date).toLocaleDateString() : '-'}</td>
                                                            <td>{new Date(url.last_checked_date).toLocaleDateString() !== '1/1/1970' ? new Date(url.last_checked_date).toLocaleDateString() : '-'}</td>
                                                            <td>
                                                                {url.status === "Indexed" && (
                                                                    <span className="badge rounded-pill badge-soft-success font-size-11">
                                                                        {url.status}
                                                                    </span>
                                                                )}

                                                                {url.status === "Not Indexed" && (
                                                                    <span className="badge rounded-pill badge-soft-warning font-size-11">
                                                                        {url.status}
                                                                    </span>
                                                                )}

                                                                {(url.status !== "Indexed" && url.status !== "Not Indexed") && (
                                                                    <span className="badge rounded-pill badge-soft-info font-size-11">
                                                                        {url.status}
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>{url.url_status}</td>
                                                            <td>
                                                                <Dropdown
                                                                    isOpen={dropdownStates[index]}
                                                                    toggle={() => toggleDropdown(index)}
                                                                >
                                                                    <DropdownToggle className="btn btn-secondary" caret>
                                                                        <i className="bx bx-menu" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => recheckProject(url)}>Recheck</DropdownItem>
                                                                        <DropdownItem onClick={() => deleteURL(url)}>Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : null)
                                            }
                                        </tbody>
                                    </Table>
                                </div> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
DomainProjectDetails.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default DomainProjectDetails;
