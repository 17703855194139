import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { requestPasswordChange, resetPassword, verifyToken } from "services/main.service";
import Swal from "sweetalert2";

const ResetPassword = () => {

    //meta title
    document.title = "Reset Password | Indexed Checker App";

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [alert, setAlert] = useState()
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        console.log(token, 'token');
        verifyToken(token).then(res => {
            console.log(res, 'res');
            setIsLoading(false);
        }).catch((error) => {
            Swal.fire({
                title: error.response.data.message,
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Back to Login"
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/login')
                }
            });
        }).finally(() => {
            // navigate('/pages-500')
        });
    }, [token])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            const password_data = {
                token: token,
                password: values.password
            }
            console.log(password_data, 'password_data');
            resetPassword(password_data).then((res) => {
                console.log(res, "response");
                setAlert(res.data.message)
                Swal.fire({
                    title: res.data.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Login"
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/login')
                    }
                });
            }).catch((error) => {
                console.log({ error });
            }).finally(() => {
                // setIsLoading(false);
            });
        }
    });
    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage />

                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5">
                                            <Link to="dashboard" className="d-block auth-logo">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo-dark"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo-light"
                                                />
                                            </Link>
                                        </div>
                                        <div className="my-auto">
                                            <div>
                                                <h5 className="text-primary">Reset Password</h5>
                                                <p className="text-muted">Re-Password with Indexed Checker.
                                                </p>
                                            </div>

                                            <div className="mt-4">

                                                {(alert ? (
                                                    <div className="alert alert-success text-center mb-4" role="alert">
                                                        {alert}
                                                    </div>
                                                ) : null)}

                                                <Form className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    <div className="mb-3">
                                                        <Label className="form-label">Password</Label>
                                                        <Input
                                                            name="password"
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            type="password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mt-3 text-end">
                                                        <Button
                                                            className="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>

                                                </Form>
                                                {/* <div className="mt-5 text-center">
                                                    <p>
                                                        Remember It ?{" "}
                                                        <Link
                                                            to="login"
                                                            className="fw-medium text-primary"
                                                        >
                                                            {" "}
                                                            Sign In here{" "}
                                                        </Link>{" "}
                                                    </p>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
